import { Component, ViewChild, Input } from "@angular/core";
import { LoadingController, IonNav } from "@ionic/angular";
import * as firebase from "firebase/app";
import "firebase/auth";
import { LoginCompleteComponent } from "../login-complete.component";
import { AppConfiguration } from 'src/app/app.configuration';
import { HttpClient } from '@angular/common/http';
import { PubqConfigProvider } from 'src/app/services/pubq-config/pubq-config';
import { Storage } from '@ionic/storage';
import { LanguageProvider } from 'src/app/services/language/language.provider';
import { LanguagePipe } from 'src/app/pipes/language.pipe';

@Component({
  selector: "page-phone-form",
  templateUrl: "verifyPhone.html",
  styleUrls: ["phoneForm.scss"],
})
export class VerifyPhonePage {
  @ViewChild("codeInputField") codeInputField;
  code: string;
  @Input() isAndroid = false;
  @Input() credential;
  @Input() nav: IonNav;
  constructor(
    private loadingCtrl: LoadingController,
    private storage: Storage,
    private http: HttpClient,
    private pubqConfigProvider: PubqConfigProvider,
    public languageProvider:LanguageProvider,
    private languagePipe:LanguagePipe
  ) { }
  ngOnInit() {
    if (typeof this.credential === "object") {
      if (this.credential.instantVerification && this.isAndroid)
        this.signInWithInstantVerification(this.credential);
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.codeInputField.setFocus();
    }, 700);
  }
  ngOnDestroy() {
    this.storage.remove("phone_auth");
  }
  codeValid() {
    return this.code && this.code.length >= 6;
  }
  async verify(code) {
    let loading = await this.loadingCtrl.create({
      spinner: "circular",
      message: this.languagePipe.transform("Du loggas in",'LOGGING_IN',this.languageProvider.currentLanguage),
      duration: 8000,
    });
    loading.present();
    let verificationId = await this.getVerificationId(this.credential);
    try {
      let credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        code
      );
      var clientSpecificApp: firebase.app.App;
      if (AppConfiguration.firebaseProject)
        clientSpecificApp = firebase.app("clientSpecificProject");
      else
        clientSpecificApp = firebase.app();

      firebase
        .auth(clientSpecificApp)
        .signInWithCredential(credential)
        .then((x) => {
          loading.dismiss();
          this.finishSignIn(x);
        });
    } catch (error) {
      alert(JSON.stringify(error));
      loading.dismiss();
    }
  }
  async inputChanged(ev) {
    if (ev.length >= 6) {
      this.verify(ev);
      return;
    }
  }
  async getVerificationId(credential) {
    if (typeof credential === "object") {
      return credential.verificationId;
    } else {
      return credential;
    }
  }
  async signInWithInstantVerification(credential) {
    try {
      let instantCredential = firebase.auth.PhoneAuthProvider.credential(
        credential.verificationId,
        credential.code
      );
      var clientSpecificApp;
      if (AppConfiguration.firebaseProject)
        clientSpecificApp = firebase.app("clientSpecificProject");
      else
        clientSpecificApp = firebase.app();
      firebase
        .auth(clientSpecificApp)
        .signInWithCredential(instantCredential)
        .then((x) => {
          this.finishSignIn(x);
        });
    } catch (error) {
      alert(JSON.stringify(error));
      throw error;
    }
  }
  async finishSignIn(dbAuth: firebase.auth.UserCredential) {

    if (AppConfiguration.firebaseProject) {
      let loading = await this.loadingCtrl.create({
        message: this.languagePipe.transform("Gör klart det sista",'LOGIN_FINALIZING',this.languageProvider.currentLanguage),
        spinner: "circular",
        duration: 20000
      });
      loading.present();
      try {
        let apiUrl = this.pubqConfigProvider.config.value.ApiUrl;
        let body = {
          uid: dbAuth.user.uid
        };
        let response = await this.http.post<any>(`${apiUrl}/signInExternal`, body).toPromise();
        let userCredential = await firebase.auth().signInWithCustomToken(response.data);
        await this.nav.push(LoginCompleteComponent, {
          userId: userCredential.user.uid,
          phoneNumber: dbAuth.user.phoneNumber,
          nav: this.nav,
        });
        loading.dismiss();
      } catch (error) {
        loading.dismiss();
        alert(JSON.stringify(error));
      }
    }
    else
      this.nav.push(LoginCompleteComponent, {
        userId: dbAuth.user.uid,
        phoneNumber: dbAuth.user.phoneNumber,
        nav: this.nav,
      });
  }
}
