import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DeliveryOption } from '../context/context';
import { AlertController } from '@ionic/angular';
import { LanguageProvider } from '../language/language.provider';
import { LanguagePipe } from 'src/app/pipes/language.pipe';

/*
  Generated class for the DeliveryPlaceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable(
  { providedIn: 'root' }
)
export class DeliveryPlaceProvider {
  tableOption: DeliveryOption;

  constructor(public http: HttpClient,
              public alertCtrl: AlertController,
              private languageProvider: LanguageProvider,
              private languagePipe: LanguagePipe) {
  }
  // async promptForTableNumber() {
  //   const alert = await this.alertCtrl.create({
  //     header: this.languagePipe.transform(
  //       'Skriv in ditt bordsnummer',
  //       'WRITE_TABLENUMBER', this.languageProvider.currentLanguage) + ' (' +
  //       this.tableOption.minValue + '-' + this.tableOption.maxValue + ')',
  //     inputs: [
  //       {
  //         name: 'tableNumber',
  //         placeholder: this.languagePipe.transform('Bordsnummer', 'TABLENUMBER', this.languageProvider.currentLanguage),
  //         type: 'tel'
  //       }
  //     ],
  //     buttons: [
  //       {
  //         text: this.languagePipe.transform('Avbryt', 'CANCEL', this.languageProvider.currentLanguage),
  //         role: 'cancel'
  //       },
  //       {
  //         role: 'confirm',
  //         text: this.languagePipe.transform('Ok', 'CONFIRM', this.languageProvider.currentLanguage),
  //         handler: data => {
  //           if (parseInt(data.tableNumber) >= parseInt(this.tableOption.minValue) &&
  //             parseInt(data.tableNumber) <= parseInt(this.tableOption.maxValue)) {
  //             // alert.dismiss(data);
  //             return true;
  //           } else {
  //             return false;
  //           }
  //         }
  //       }
  //     ]
  //   });
  //   alert.present();
  //   return alert;
  // }

}
