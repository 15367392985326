import {Component, NgZone, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { ContextProvider, AppInfo } from 'src/app/services/context/context';
// import { Geolocation, Geoposition } from "@ionic-native/geolocation/ngx";
import { ActivatedRoute } from '@angular/router';
import { NavController, IonContent } from '@ionic/angular';
import { LocationSorter } from 'src/app/services/locationSorter';
import { UserProvider } from 'src/app/services/user/user';
import { OrderProvider } from 'src/app/services/order/order';
import { LanguageProvider } from 'src/app/services/language/language.provider';

declare var google;
@Component({
    selector: 'app-deliver-to-location',
    styleUrls: ['deliverToLocation.component.scss'],
    templateUrl: 'deliverToLocation.component.html',
})
export class DeliverToLocationComponent {
    @Output() userAddressInfo = new EventEmitter();
    appInfo: AppInfo;
    @ViewChild('search') public searchElementRef: ElementRef;
    @ViewChild(AgmMap) public agmMap: AgmMap;
    @ViewChild(IonContent) content: IonContent;
    loading;
    userName: string;
    userPhone: string;
    deliveryComment: string;
    zoom = 15;
    latitude = 1;
    longitude = 0.5;
    selectedLat = 0;
    selectedLong = 0;
    cameraLat = 0;
    cameraLong = 0;
    restaurantRadius = [
        {
            radius: 200,
            price: 0,
            color: 'blue',
            opacity: 0.3,
        },
        {
            radius: 600,
            price: 4900,
            color: 'blue',
            opacity: 0.2,
        },
        {
            radius: 1200,
            price: 6900,
            color: 'blue',
            opacity: 0.1,
        },
    ];
    selectedRestaurantRadius;
    maploaded = false;
    userPosition = null;
    private geoCoder;
    positionSubscription = null;
    selectedAddress = '';
    deliveryAddressIsWithinArea = false;
    constructor(
        private activatedRoute: ActivatedRoute,
        private orderProvider: OrderProvider,
        private userProvider: UserProvider,
        private navCtrl: NavController,
        private mapsApiLoader: MapsAPILoader,
        private ngZone: NgZone,
        private contextProvider: ContextProvider,
        // private geolocation: Geolocation,
        private locationSorter: LocationSorter,
        public languageProvider: LanguageProvider
    ) { }
    async ngOnInit() {
        this.appInfo = this.contextProvider.clientContext.appInfo.value;
        this.restaurantRadius = this.appInfo.Context.homeDelivery.radiuses;
        this.restaurantRadius.sort((a, b) => {
            return a.radius < b.radius ? -1 : 1;
        });
        let opacity = 300;
        this.restaurantRadius.forEach((x: any) => {
            x.opacity = opacity / 1000;
            opacity -= 100;
            if (opacity < 100) {
                opacity = 100;
            }
            x.color = 'blue';
            x.price = parseInt(x.price);
            x.radius = parseInt(x.radius);
        });

    }
    async ngAfterViewInit() {
        this.userPhone = this.userProvider.currentUser.value.phoneNumber;
        if (this.userProvider.currentUser.value.firstName === '' && this.userProvider.currentUser.value.lastName === '') {
          this.userName = '';
        } else {
          this.userName = `${this.userProvider.currentUser.value.firstName} ${this.userProvider.currentUser.value.lastName}`;
        }

        const ele = document.getElementById('searchInput');
        if (ele) {
            setTimeout(() => {
                ele.focus();
            }, 1500);
        }
        await this.mapsApiLoader.load();
        this.geoCoder = new google.maps.Geocoder();

        const autocomplete = new google.maps.places.Autocomplete(
            this.searchElementRef.nativeElement
        );
        autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
                // get the place result
                const place: google.maps.places.PlaceResult = autocomplete.getPlace();

                // verify result
                if (place.geometry === undefined || place.geometry === null) {
                    return;
                }
                // set latitude, longitude and zoom
                this.selectedLat = place.geometry.location.lat();
                this.selectedLong = place.geometry.location.lng();
                this.cameraLat = this.selectedLat;
                this.cameraLong = this.selectedLong;
                this.zoom = 18;
                this.selectedAddress = place.formatted_address;
                this.determineDeliveryDistance();
                this.scrollToElement('deliveryInformation', 0);
            });
        });
        this.loadMap();
        this.zoom = 15;
    }
    async determineDeliveryDistance() {
        const restaurantCoords = {
            latitude: this.latitude,
            longitude: this.longitude,
        };
        const deliveryPlace = {
            latitude: this.selectedLat,
            longitude: this.selectedLong,
        };
        const distance =
            this.locationSorter.getDistance(restaurantCoords, deliveryPlace) * 1000; // convert to meters
        // find correct price tier
        const validTiers = this.restaurantRadius.filter((x) => x.radius >= distance);
        if (validTiers.length <= 0) {
            this.deliveryAddressIsWithinArea = false;
            return;
        }
        this.selectedRestaurantRadius = validTiers.reduce((prev, curr) => {
            return Math.abs(curr.radius - distance) < Math.abs(prev.radius - distance)
                ? curr
                : prev;
        });
        this.deliveryAddressIsWithinArea =
            this.selectedRestaurantRadius.radius >= distance;
    }
    onSearchFocus() {
        this.scrollToElement('searchInput', 250);
        const element: HTMLInputElement = document.getElementById(
            'searchInput'
        ) as HTMLInputElement;
        element.select();
    }
    async scrollToElement(elementId, offset) {
        setTimeout(() => {
            const element = document.getElementById(elementId);
            if (!element) { return; }
            // const rect = element.getBoundingClientRect();
            // this.content.scrollByPoint(rect.x, rect.y - offset, 300);
        }, 1000);
    }
    async loadMap() {
        this.latitude = this.contextProvider.client.location.latitude;
        this.longitude = this.contextProvider.client.location.longitude;
        this.cameraLat = this.latitude;
        this.cameraLong = this.longitude;
        // this.positionSubscription = this.geolocation
        //     .watchPosition()
        //     .subscribe((x: Geoposition) => {
        //         if (!x) { return; }
        //         this.userPosition = {
        //             lat: x.coords.latitude,
        //             long: x.coords.longitude,
        //         };
        //     });
        this.positionSubscription = navigator.geolocation.watchPosition((x) => {
          if (!x) { return; }
          this.userPosition = {
            lat: x.coords.latitude,
            long: x.coords.longitude,
          };
        });
        setTimeout(() => {
            this.maploaded = true;
        }, 20);
    }
    // ngOnDestroy() {
    //     if (this.positionSubscription) { this.positionSubscription.unsubscribe(); }
    // }

    forward() {
        this.orderProvider.orderWaitingForApproval.deliveryComment = this.deliveryComment;
        this.orderProvider.orderWaitingForApproval.userName = this.userName;
        this.orderProvider.orderWaitingForApproval.phoneNumber = this.userPhone;
        this.orderProvider.orderWaitingForApproval.deliveryRadius = this.selectedRestaurantRadius;
        this.orderProvider.orderWaitingForApproval.deliveryLat = this.selectedLat;
        this.orderProvider.orderWaitingForApproval.deliveryLong = this.selectedLong;
        this.orderProvider.orderWaitingForApproval.deliveryFee = this.selectedRestaurantRadius.price;
        this.orderProvider.orderWaitingForApproval.deliveryAddress = this.selectedAddress;
        // this.navCtrl.navigateForward(['../payment'], {
        //     relativeTo: this.activatedRoute,
        // });
        const userName = this.userName;
        const userPhone = this.userPhone;
        this.userAddressInfo.emit({userName, userPhone});
    }
    formValid() {
        return this.userName && this.userPhone;
    }
    back() {
        this.navCtrl.navigateBack(['../deliveryPlace'], {
            relativeTo: this.activatedRoute,
        });
    }
}
