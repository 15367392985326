import { Component, ViewChild, EventEmitter, Input, Output } from "@angular/core";
import { Subscription } from "rxjs";
import {
  NavController,
  AlertController,
  LoadingController,
  ModalController, Platform, IonContent
} from "@ionic/angular";
import { UserProvider, UserPaymentSource, User } from "../services/user/user";
import { PaymentProvider } from "../services/payment/payment";
import { Order, OrderProvider } from "../services/order/order";
import { ActivatedRoute } from "@angular/router";
import { CartItem } from "../cart-items/cart-items";
import { AddCardComponent } from "./add-card/addCard.component";
import { BonusProvider } from '../services/bonus/bonus';
import { ContextProvider } from '../services/context/context';
import { LanguageProvider } from '../services/language/language.provider';
import { LanguagePipe } from '../pipes/language.pipe';
import { CartProvider } from '../services/cart/cart';
import { AppConfiguration } from "../app.configuration";
import { ReepayGateway } from "../services/payment/gateways/reepay.gateway";
import { PubqConfigProvider } from "../services/pubq-config/pubq-config";
import { HttpClient } from "@angular/common/http";
import { EventsProvider } from "../services/events/events.provider";
@Component({
  selector: "page-payment",
  templateUrl: "payment.html",
  styleUrls: ["payment.scss"],
})
export class PaymentPage {
  @ViewChild("paycontent") ionContent: IonContent;
  @Output() cardCredentials = new EventEmitter<any>();
  userStoredPaymentOptions: any[];
  amountToCharge;
  discountAmount = 0;
  bonusAmountToUseInPurchase = 0;
  bonusActivated = false;
  deliveryFee = 0;
  itemsInCart: CartItem[] = [];
  user: User;
  object = Object;
  paymentOptions  = {
    swish: { enabled: false },
    card: { enabled: true },
    customerTab: { enabled: false },
    vipps: { enabled: false }
  };
  loading = true;
  subscriptions: Subscription;
  userSubscription: Subscription;
  action = "";
  selectedPaymentMethod = {};
  bonusVoucher = null;
  tipAmount = 0;
  minimumBonusAmount = 0;
  globalOffersEnabled = false;
  bottomPaddingHtmlElement: HTMLElement;
  scrollDepthTriggered = false;
  tableNumber: number = 0;
  offersActivated = 0;
  showToast = false;
  tabAmountToCharge: number;
  tabOrderId: any;
  provider = AppConfiguration.paymentProvider;
  constructor(
    public navCtrl: NavController,
    private loadingController: LoadingController,
    private orderProvider: OrderProvider,
    private alertCtrl: AlertController,
    private activatedRoute: ActivatedRoute,
    public modalCtrl: ModalController,
    public userProvider: UserProvider,
    public paymentProvider: PaymentProvider,
    private bonusProvider: BonusProvider,
    private contextProvider: ContextProvider,
    private platform: Platform,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    private cartProvider: CartProvider,
    private pubqConfig: PubqConfigProvider,
    private http: HttpClient,
    private eventsProvider: EventsProvider,
  ) { 


  }

  ngAfterViewInit() {
    //this.selectedPaymentMethod = 'vipps';
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
    try{
      this.subscriptions = new Subscription();
      let options: any = await this.paymentProvider.getPaymentOptions();
      this.globalOffersEnabled = this.contextProvider.clientContext.appInfo.value.Context.globalOffersEnabled;
      if (!options)
        this.paymentOptions = {
          swish: { enabled: false },
          card: { enabled: true },
          customerTab: { enabled: false },
          vipps: { enabled: false }
        };
      else this.paymentOptions = options;
      this.paymentOptions = JSON.parse(JSON.stringify(this.paymentOptions));
      this.activatedRoute.queryParams.subscribe(async (x) => {
        if(!x) {return}
        this.action = x.action;
        if (x.action === "paytab") {
          this.orderProvider.tabOrder = new Order();
          this.orderProvider.tabOrder.tableNumber = -1;
          this.orderProvider.tabOrder.orderCode = x.orderId;
          this.orderProvider.tabOrder.client = this.contextProvider.client;

          this.cartProvider.empty();
          let items = this.orderProvider.tabItemsWaitingForApproval[
            x.orderId
          ];

          for (let item of items) {
            item.NrOfItems = 1;
            if (item.paid != true) await this.cartProvider.addItem(item);
          }

          this.tabAmountToCharge = this.cartProvider.cart.sum;
          this.orderProvider.tabOrder.totalCost = this.cartProvider.cart.sum;
          this.orderProvider.tabOrder.order = { food: [], drinks: [] };

          this.orderProvider.tabOrder.totalDrinkItems = this.cartProvider.cart.totalDrinkItems;
          this.orderProvider.tabOrder.totalFoodItems = this.cartProvider.cart.totalFoodItems;
          this.orderProvider.tabOrder.order.food = JSON.parse(JSON.stringify(this.cartProvider.getFoodItems(this.cartProvider.cart.items)));
          this.orderProvider.tabOrder.order.drinks = JSON.parse(JSON.stringify(this.cartProvider.getDrinkItems(this.cartProvider.cart.items)));
          this.itemsInCart = this.orderProvider.tabOrder.order.drinks.concat(
            this.orderProvider.tabOrder.order.food
          );
          this.cartProvider.empty();

          this.tableNumber = -1;
          // this.tabAmountToCharge = parseFloat(x.amountToCharge);
          this.tipAmount = parseFloat(x.tipAmount);
          if (this.tipAmount && this.tipAmount > 0)
            this.tabAmountToCharge += this.tipAmount;
          this.paymentOptions.customerTab.enabled = false;
          let result = await this.orderProvider.applyPreSelectedOffers(this.itemsInCart, -1);
          this.offersActivated = result.numberOfActivatedOffers;
          if (result.ineligibleOffers.length > 0) {
            this.showIneligibleOffers(result.ineligibleOffers);
          }
          this.itemsInCart = result.items;
          this.calculateTotals();
        } else {
          this.amountToCharge = this.orderProvider.orderWaitingForApproval.totalCost;

          // All items in cart
          this.itemsInCart = [];
          if(this.orderProvider.orderWaitingForApproval && this.orderProvider.orderWaitingForApproval.order){
            if(this.orderProvider.orderWaitingForApproval.order.drinks && this.orderProvider.orderWaitingForApproval.order.food) {
              this.itemsInCart =
              this.orderProvider.orderWaitingForApproval.order.drinks.concat(
                this.orderProvider.orderWaitingForApproval.order.food
              );
            }else if(this.orderProvider.orderWaitingForApproval.order.drinks){
              this.itemsInCart = this.orderProvider.orderWaitingForApproval.order.drinks;
            }else if(this.orderProvider.orderWaitingForApproval.order.food){
              this.itemsInCart = this.orderProvider.orderWaitingForApproval.order.food;
            }
          }

          this.tableNumber = this.orderProvider.orderWaitingForApproval.tableNumber;
          let result = await this.orderProvider.applyPreSelectedOffers(this.itemsInCart, this.orderProvider.orderWaitingForApproval.tableNumber);
          this.offersActivated = result.numberOfActivatedOffers;
          if (result.ineligibleOffers.length > 0) {
            this.showIneligibleOffers(result.ineligibleOffers);
          }
          this.itemsInCart = result.items;
        }
        this.calculateTotals();
      });
      let userSubscription = this.userProvider.currentUser.subscribe(
        async (user: User) => {
          if (!user) return;
          this.user = user;
          let paymentSourcesSubscription = (await this.userProvider.getUserPaymentSources(user.id)).subscribe((x) => {
            this.userStoredPaymentOptions = x;
            // Set vipps to default payment if enabled
            console.dir(this.paymentOptions);
            if(this.paymentOptions && this.paymentOptions.vipps)
               this.selectPaymentMethod('vipps');
            else if (this.userStoredPaymentOptions)
               this.selectPaymentMethod(this.userStoredPaymentOptions[0]); 
          });
          this.subscriptions.add(paymentSourcesSubscription);
        }
      );
      this.subscriptions.add(userSubscription);

      this.loading = false;
    }catch(error){
      console.log(error);
      this.loading = false;
    }
  }


  async showIneligibleOffers(offers: any[]) {
    let titles = offers.map(x => x.title).join(", ");
    let alert = await this.alertCtrl.create({
      header: this.languagePipe.transform("Dessa erbjudanden kunde inte aktiveras", 'PAYMENT_OFFER_ERROR_HEADER', this.languageProvider.currentLanguage),
      subHeader: titles,
      message: this.languagePipe.transform("De gäller inte för det serveringssätt du valt", 'PAYMENT_OFFER_ERROR_TEXT', this.languageProvider.currentLanguage),
      buttons: [{ text: "Ok" }]
    });
    alert.present();
  }
  calculateCoupon(order) {
    if (order.coupon) {
      this.discountAmount =
        order.totalDrinksCost +
        order.totalFoodCost -
        order.totalCost;
    }
  }
  calculateDeliveryFee() {
    if (this.orderProvider.orderWaitingForApproval.tableNumber === -2) {
      this.deliveryFee = this.orderProvider.orderWaitingForApproval.deliveryFee;
      this.amountToCharge += this.deliveryFee;
    } else if (this.orderProvider.orderWaitingForApproval.tableNumber === -1 && this.orderProvider.orderWaitingForApproval.deliveryFee) {
      this.deliveryFee = this.orderProvider.orderWaitingForApproval.deliveryFee;
      this.amountToCharge += this.deliveryFee;
    }
  }
  async calculateBonus() {
    let bonusVoucher = await this.bonusProvider.getBonus(this.contextProvider.clientContext.currentClient.value.routeName, this.userProvider.currentUser.value.id)
    if (!bonusVoucher) return;
    this.minimumBonusAmount = this.contextProvider.clientContext.appInfo.value.Context.minimumBonusVoucherAmount;
    if (bonusVoucher.value > 0) {
      let bonusPool = this.convertBonusVoucherToCurrency(bonusVoucher.value);
      let validItemsPrice = 0;
      if(this.itemsInCart.length > 0){
        validItemsPrice = this.itemsInCart.filter(x => {
          return !x.type.containsAlcohol;
        }).map(x => (this.totalCostOfItem(x))).reduce((acc, curr) => acc += curr);
      }
      let deduction = validItemsPrice - bonusPool;
      if (deduction < 0)
        this.bonusAmountToUseInPurchase = bonusPool - Math.abs(deduction);
      else
        this.bonusAmountToUseInPurchase = bonusPool;
      this.bonusAmountToUseInPurchase = this.convertBonusVoucherFromCurrency(this.bonusAmountToUseInPurchase);

      this.bonusVoucher = bonusVoucher;
      // if (this.bonusAmountToUseInPurchase >= this.minimumBonusAmount) {
      //   this.bonusActivated = true;
      //   this.applyBonus(true);
      // }
    }
    else
      this.bonusActivated = false;

  }
  applyBonus(ev) {
    if (ev) {
      this.amountToCharge -= this.convertBonusVoucherToCurrency(this.bonusAmountToUseInPurchase);
      this.bonusActivated = true
    }
    else {
      this.bonusActivated = false;
      this.amountToCharge += this.convertBonusVoucherToCurrency(this.bonusAmountToUseInPurchase);
    }
  }
  async offerActivated(ev) {
    let result = null;
    if (this.action === "paytab") {
      result = await this.orderProvider.applyOffer(ev, this.itemsInCart, -1);
    } else {
      result = await this.orderProvider.applyOffer(ev, this.itemsInCart, this.orderProvider.orderWaitingForApproval.tableNumber);
    }

    if (!result) {
      //Offer not applicable to this order
      this.orderProvider.deActivateOffer(ev);
      this.showIneligibleOffers([ev]);
    }
    else {
      //Offer was applied
      if (this.bonusActivated) {
        this.bonusActivated = false;
        this.amountToCharge += this.convertBonusVoucherToCurrency(this.bonusAmountToUseInPurchase);
      }
      if (this.offersActivated <= 0) this.offersActivated = 1
      else this.offersActivated++;
      this.itemsInCart = result;
      this.calculateTotals();
    }

  }
  async offerDeactivated(ev) {
    if (this.offersActivated <= 1) this.offersActivated = 0
    else this.offersActivated--;
    this.itemsInCart = await this.orderProvider.removeOffer(ev, this.itemsInCart);
    this.calculateTotals();
  }
  calculateTotals() {
    let sum = 0;
    if (this.action === "paytab") {
      sum = this.orderProvider.tabOrder.totalCost + this.tipAmount;

      this.calculateCoupon(this.orderProvider.tabOrder);
    } else {
      sum = this.orderProvider.orderWaitingForApproval.totalCost;

      this.calculateCoupon(this.orderProvider.orderWaitingForApproval);
    }
    let discountFromItems = Math.round(this.itemsInCart.filter(x => x.discountValue).map(x => x.discountValue).reduce((a, b) => a + b, 0));
    this.amountToCharge = sum - discountFromItems;
    if (this.offersActivated == 0) this.calculateBonus();

    this.calculateDeliveryFee();

  }
  getAmountToCharge() {
    if (this.amountToCharge < 0) return 0;
    return this.amountToCharge;
  }
  getAmountToChargeStyled() {
    let num = this.getAmountToCharge() / 100;
    if (num - Math.floor(num) === 0) {
      return num;
    } else {
      return num.toFixed(2);
    }
  }
  convertBonusVoucherToCurrency(voucher) {
    return (voucher / 10) * 100;
  }
  convertBonusVoucherFromCurrency(voucher) {
    return (Math.round(voucher) * 10) / 100;
  }
  hasPaymentOption(option: "applepay" | "card" | "swish" | "customerTab" | "vipps") {
    return this.paymentOptions[option] && this.paymentOptions[option].enabled;
  }
  dismiss() {
    this.navCtrl.navigateBack(["../deliveryPlace"]);
  }
  totalCostOfItem(item: CartItem) {
    return this.orderProvider.getTotalCostOfItemExclDiscount(item);
  }
  totalCostOfItemStyled(item: CartItem) {
    let num = this.totalCostOfItem(item) / 100;

    if (num - Math.floor(num) === 0) {
      return num;
    } else {
      return num.toFixed(2);
    }
  }
  totalCostAfterDiscount(item: CartItem) {
    let cost = this.orderProvider.getTotalCostOfItemExclDiscount(item);
    let discount = item.discountValue;
    let sum = cost - discount;
    return sum;
  }
  totalCostAfterDiscountStyled(item: CartItem) {
    let num = this.totalCostAfterDiscount(item) / 100;
    if (num - Math.floor(num) === 0) {
      return num;
    } else {
      return num.toFixed(2);
    }
  }
  async enterCustomerTabKey() {
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_TAB", { user: this.user});
    const type = this.contextProvider.clientContext.appInfo.value.Context.tabKeyAcceptsText;
    this.eventsProvider.logEvent("USER_PAYED_WITH_TAB",{user: this.user});
    this.navCtrl.navigateForward(["../tabKey"], {
      queryParams: { type: type },
      relativeTo: this.activatedRoute,
      queryParamsHandling: "merge",
    });
  }

  async payWithSwish() {
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_SWISH", { user: this.user});
    var swishPaymentRef: UserPaymentSource = {
      nickname: "swish",
      recurringReference: null,
      type: "swish",
    };
    this.eventsProvider.logEvent("USER_PAYED_WITH_SWISH",{user: this.user, swishPaymentRef: swishPaymentRef});
    this.orderProvider.paymentOfChoice = swishPaymentRef;
    this.forward();
  }

  async payWithReepayNewCard(){
    
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_ADD_CARD", { user: this.user});

    let merchantConfig = this.contextProvider.clientContext.paymentGateway.value;
    let pubqConfig = this.pubqConfig.config.value;
    let browserTarget = this.platform.is("ios") ? "_blank" : "_blank";

    let gateway = new ReepayGateway(this.http, pubqConfig, merchantConfig, this.loadingController, this.modalCtrl);
    
    // Will show a modal with Reepay component
    await gateway.pay_embedded({
      cardOnFile: "",
      order: this.orderProvider.orderWaitingForApproval,
      routeName: this.contextProvider.client.routeName,
      userId: this.user.id,
      orderText: "",
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email ? this.user.email : null,
      locale: this.languageProvider.currentLanguage,
    })
    .then(async (payResult) => {
      let orderId = payResult.invoice;
      let recurringReference = payResult.payment_method;
      let originalMerchant = this.contextProvider.client.routeName;
      let alert = await this.alertCtrl.create({
        header: this.languagePipe.transform("Spara ditt kort?", 'SAVE_CARD_QUESTION_HEADER', this.languageProvider.currentLanguage),
        subHeader: '',
        message: this.languagePipe.transform("Lägg till kort för framtida köp.", 'SAVE_CARD_QUESTION', this.languageProvider.currentLanguage),
        buttons: [{ role: "cancel", text: this.languagePipe.transform("Cancel", 'CANCEL', this.languageProvider.currentLanguage) }, { role: "confirm", text: this.languagePipe.transform("OK", 'OK', this.languageProvider.currentLanguage) }]
      });
      alert.present();
      let result = await alert.onDidDismiss();
      let saveCard = false;

      if (result.role === "confirm")
      {
        this.eventsProvider.logEvent("USER_SAVED_CARD",{user: this.user, orderId: orderId});
        saveCard = true;
      }
      this.orderProvider.orderWaitingForApproval.id = orderId;
      this.orderProvider.reepayData = { orderId, recurringReference, originalMerchant, saveCard };
      this.orderProvider.paymentOfChoice = <UserPaymentSource>{ recurringReference, nickname: "TBD", type: "reepay" };
      this.eventsProvider.logEvent("USER_PAYED_WITH_ADD_CARD", { user: this.user, orderId: orderId, savedCard: saveCard });
      this.forward();
    })
    // If user cancelled payment
    .catch(() => {
      this.eventsProvider.logEvent("USER_CANCEL_TO_PAY_WITH_ADD_CARD",{user: this.user});
    })
    .finally(()=> {
      return;
    });

    // let payResult = await gateway.pay({
    //   cardOnFile: "",
    //   order: this.orderProvider.orderWaitingForApproval,
    //   routeName: this.contextProvider.client.routeName,
    //   userId: this.user.id,
    //   orderText: "",
    //   firstName: this.user.firstName,
    //   lastName: this.user.lastName,
    //   email: this.user.email ? this.user.email : null,
    //   locale: this.languageProvider.currentLanguage,
    //   target: browserTarget
    // });
  }

  // Obsolete do not use -> use payWithReepayCharge
  async payWithReepayCardOnFile(){
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_SAVED_CARD", { user: this.user});

    let merchantConfig = this.contextProvider.clientContext.paymentGateway.value;
    let pubqConfig = this.pubqConfig.config.value;
    let browserTarget = this.platform.is("ios") ? "_blank" : "_blank";

    let gateway = new ReepayGateway(this.http, pubqConfig, merchantConfig, this.loadingController, this.modalCtrl);
    let payment: UserPaymentSource = <UserPaymentSource>this.selectedPaymentMethod;
    
    await gateway.pay_embedded({
      cardOnFile: payment.recurringReference,
      order: this.orderProvider.orderWaitingForApproval,
      routeName: this.contextProvider.client.routeName,
      userId: this.user.id,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      orderText: this.contextProvider.client.name,
      email: this.user.email ? this.user.email : null,
      locale: this.languageProvider.currentLanguage,
    })
    .then(async (payResult) => {
      let orderId = payResult.invoice;
      let recurringReference = payResult.payment_method;
      let originalMerchant = this.contextProvider.client.routeName;
      this.orderProvider.orderWaitingForApproval.id = orderId;
      this.orderProvider.reepayData = { orderId, recurringReference, originalMerchant, saveCard: false };
      this.orderProvider.paymentOfChoice = payment;
      this.eventsProvider.logEvent("USER_PAYED_WITH_SAVED_CARD", { user: this.user, orderId: orderId });
      this.forward();
    })
    // If user cancelled payment
    .catch(() => {
      this.eventsProvider.logEvent("USER_CANCEL_TO_PAY_WITH_SAVED_CARD",{user: this.user});
    })
    .finally(()=> {
      return;
    });
  }

  async payWithReepayCharge(){
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_SAVED_CARD", { user: this.user});

    let orderId = 'N/A';
    let payment: UserPaymentSource = <UserPaymentSource>this.selectedPaymentMethod;
    let recurringReference = payment.recurringReference;
    let originalMerchant = this.contextProvider.client.routeName;

    this.orderProvider.reepayData = { orderId, recurringReference, originalMerchant, saveCard: false };
    this.orderProvider.paymentOfChoice = payment;
    this.eventsProvider.logEvent("USER_PAYED_WITH_SAVED_CARD", { user: this.user });
    this.forward();
  }

  async payWithVipps() {
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_VIPPS", { user: this.user});
    var vipps: UserPaymentSource = {
      nickname: "vipps",
      recurringReference: null,
      type: "vipps",
    };
    this.orderProvider.paymentOfChoice = vipps;
    this.eventsProvider.logEvent("USER_PAYED_WITH_VIPPS", { user: this.user });
    this.forward();
  }

  useStoredPaymentOption(option: UserPaymentSource) {
    this.orderProvider.paymentOfChoice = option;
    this.forward();
  }

  forward() {
    this.navCtrl.navigateForward(["../chargeCustomer"], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: "preserve",
    });
  }

  async showAddCreditCardModal() {
    if(this.provider === "reepay"){
      this.selectPaymentMethod("newcard")
    }else{
      let modal = await this.modalCtrl.create({
        component: AddCardComponent,
        cssClass: "modal-drawer-small",
        swipeToClose: true,
        backdropDismiss: true,
      });
      modal.present();
      let result = await modal.onDidDismiss();
      if (result.role === "confirm") {
        this.userProvider.addPaymentSource(this.user, result.data);
      }
    }
  }

  selectPaymentMethod(method) {
    if (this.selectedPaymentMethod === method) { this.selectedPaymentMethod = {}; } else { this.selectedPaymentMethod = method; }
    this.cardCredentials.emit(this.selectedPaymentMethod);
  }

  getCardSource(option: UserPaymentSource) {
    let brand = option.type.split("+")[0];
    if (brand) {
      brand = brand.toLowerCase();
      if (brand.indexOf("visa") > -1) return "./assets/icons/visa.svg";
      if (brand.indexOf("master") > -1) return "./assets/icons/mastercard.svg";
      return "./assets/icons/genericcard.svg";
    }
  }

  paymentMethodValid() {
    if (this.getAmountToCharge() <= 0) {
      this.selectedPaymentMethod = { recurringReference: "free", type: this.provider, nickname: "Free" };
    }
    if (typeof this.selectedPaymentMethod === "object")
      return Object.keys(this.selectedPaymentMethod).length !== 0;
    return this.selectedPaymentMethod;
  }

  async pay() {
    if (this.bonusActivated) {
      if (this.action != "paytab") this.orderProvider.orderWaitingForApproval.bonusVoucher = { amountUsedInPurchase: this.bonusAmountToUseInPurchase, voucher: this.bonusVoucher };
      else {
        this.orderProvider.tabOrder.bonusVoucher = { amountUsedInPurchase: this.bonusAmountToUseInPurchase, voucher: this.bonusVoucher };
      }
    }
    switch (this.selectedPaymentMethod) {
      case "swish": {
        this.payWithSwish();
        break;
      }
      case "customerTabKey": {
        this.enterCustomerTabKey();
        break;
      }
      default:
      {
        if (this.provider === "reepay" && this.selectedPaymentMethod === "newcard") {
          await this.payWithReepayNewCard();
        }
        else if (this.provider === "reepay") 
        {
          //await this.payWithReepayCardOnFile();
          await this.payWithReepayCharge();
        }
        return;

        this.useStoredPaymentOption(
          <UserPaymentSource>this.selectedPaymentMethod
        );
      }
    }
  }

  async showRefusedAlert(error) {
    let text;
    if (error.pubqmaintenance)
      text = this.languagePipe.transform("Just nu pågår en uppdatering och ditt köp kan inte genomföras", 'PAYMENT_ERROR_MAINTENANCE', this.languageProvider.currentLanguage);
    else
      text =
        this.languagePipe.transform("Se så ditt kort är öppet för internetköp och att tillräcklig summa finns tillgänglig", 'PAYMENT_ERROR', this.languageProvider.currentLanguage);
    let alert = await this.alertCtrl.create({
      header: this.languagePipe.transform("Fel", 'ERROR', this.languageProvider.currentLanguage),
      subHeader: text,
      buttons: [this.languagePipe.transform("OK", 'PAYMENT_ERROR_OK', this.languageProvider.currentLanguage)],
    });
    alert.present();
  }
  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
  }
  async ionViewDidEnter() {
    await this.ionContent.scrollByPoint(0, 10, 1);
    setTimeout(() => {
      this.showToast = true;
      setTimeout(() => {
        let element = document.getElementById("payment-reached-end");
        let result = this.isInView(element);
        if (result) {
          this.scrollDepthTriggered = true;
        }
      }, 200);
    }, 100);
  }
  async scrollIsHappening($event) {
    if ($event.target.localName != "ion-content") {
      return;
    }

    const scrollElement = await $event.target.getScrollElement();
    const scrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
    const currentScrollDepth = $event.detail.scrollTop;
    const targetPercent = 90;

    let triggerDepth = ((scrollHeight / 100) * targetPercent);
    if (currentScrollDepth > triggerDepth) this.scrollDepthTriggered = true;
  }
  isInView(element) {
    let height = this.platform.height();
    let width = this.platform.width();
    let bounding = element.getBoundingClientRect();
    if (
      Math.floor(bounding.top) >= 0 &&
      Math.floor(bounding.left) >= 0 &&
      Math.floor(bounding.right) <= width &&
      Math.floor(bounding.bottom) <= height
    ) {
      return true;
    } else {
      return false;
    }
  }
}
