import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TabsPageRoutingModule } from './tabs-routing.module';

import { TabsPage } from './tabs.page';
import { CartWelcome } from '../cart/cart';
import { TableBookingPage } from '../table-booking/table-booking';
import { BookSlotPage } from '../table-booking/book-slot/book-slot';

import {
  ActiveOrderComponent
} from '../active-orders/active-order/active-order';
import { ActiveOrdersComponent } from '../active-orders/active-orders';
// import { MenuPage} from '../menu/menu';
import { BurgerStepComponent } from '../menu/build-a-burger/burgerOrder';
import { EmptyComponent } from '../menu/build-a-burger/empty';
import { AuthModule } from '../account/auth.module';
import {
  UserIdFilterPipe,
  CustomerTabsListPage,
} from '../customer-tabs/customer-tabs-list';
import { TakeawayNavComponent } from '../cart/takeaway-nav/takeawayNav.component';
// import { ClientInfoComponent } from '../client-info/clientInfo.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { CalendarModule } from 'ion2-calendar';
import { NewsItemDetailsComponent } from '../cart/news-item-details/newsItemDetails.component';
import { AgmCoreModule } from '@agm/core';
import { SharedComponentsModule } from '../sharedComponents.module';
import { WeeklyMenuComponent } from '../weekly-menu/weeklyMenu.component';
import { CustomTicketsComponent } from '../custom-tickets/customTickets.component';
// import { MenuItemDescriptionComponent } from '../menu/itemDescription';
import { OrderTicketComponent } from '../active-orders/order-ticket/orderTicket.component';
import { OfferDetailsComponent } from '../cart/offer-details-component/offerDetails.component';
// import { DeliverToLocationComponent } from '../delivery-place/deliver-to-location/deliverToLocation.component';
// import { PaymentPage } from '../payment/payment';


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    // TabsPageRoutingModule,
    AuthModule,
    PinchZoomModule,
    CalendarModule,
    AgmCoreModule,
    SharedComponentsModule,
  ],
  declarations: [
    UserIdFilterPipe,
    TabsPage,
    TableBookingPage,
    BookSlotPage,
    ActiveOrderComponent,
    ActiveOrdersComponent,
    OrderTicketComponent,
    CartWelcome,
    // MenuPage,
    // MenuItemDescriptionComponent,
    BurgerStepComponent,
    EmptyComponent,
    CustomerTabsListPage,
    TakeawayNavComponent,
    // ClientInfoComponent,
    NewsItemDetailsComponent,
    OfferDetailsComponent,
    WeeklyMenuComponent,
    CustomTicketsComponent,
    // DeliverToLocationComponent,
    // PaymentPage
  ],
  entryComponents: [
    NewsItemDetailsComponent,
    OfferDetailsComponent,
    // ClientInfoComponent,
    OrderTicketComponent,
    // MenuItemDescriptionComponent,
    BurgerStepComponent,
    EmptyComponent,
    // MenuPage,
    TakeawayNavComponent,
    WeeklyMenuComponent,
    CustomTicketsComponent
  ],
})
export class TabsPageModule { }
