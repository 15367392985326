import {Component, EventEmitter, Output} from '@angular/core';
import { UserPurchaseHistoryV2, User, UserProvider } from 'src/app/services/user/user';
import { NavParams, NavController } from '@ionic/angular';
import { PurchaseHistoryDetailsViewComponent } from './purchaseHistoryListDetails';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { LanguageProvider } from 'src/app/services/language/language.provider';

@Component({
  selector: 'page-purchase-history-list',
  templateUrl: 'purchaseHistoryList.html',
  styleUrls: ['purchaseHistory.scss'],
  providers: [PurchaseHistoryListViewPage, PurchaseHistoryDetailsViewComponent]
})
export class PurchaseHistoryListViewPage {
  @Output() receipts = new EventEmitter();
  purchaseHistory: UserPurchaseHistoryV2[];
  user: User;
  constructor(private userProvider: UserProvider,
              private navCtrl: NavController,
              public languageProvider: LanguageProvider,
              private activatedRoute: ActivatedRoute) {
    this.user = this.userProvider.currentUser.value;

    this.userProvider.getPurchaseHistory(this.user).subscribe((x: UserPurchaseHistoryV2[]) => {
      this.purchaseHistory = x.sort((a, b) => {
        return moment(a.receipt.timestamp) < moment(b.receipt.timestamp) ? 1 : -1;
      });
    });
  }

  ngOnInit() {
    this.receipts.emit(this.purchaseHistory);
  }

  getNiceDateWithTime(date) {
    return moment(date).format('YYYY-MM-DD kk:mm');
  }
  goBack() {
    this.navCtrl.back();
  }
  async showPurchaseHistoryDetails(item) {
    this.navCtrl.navigateForward([`/history/${item.key}`]);
  }

}
