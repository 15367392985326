import * as settings from './client.configuration';
export class AppConfiguration {
    static userOrigin = settings.userOriginName;
    static paymentProvider = settings.paymentProvider;
    static clientName = settings.name;
    static clientRoute = settings.dbRoute;
    static hasCitySelection = settings.hasCitySelection;
    static customUrlProtocol = settings.customUrlProtocol;
    static statusBarColorHex = settings.statusBarColorHex;
    static statusBarStyle = settings.statusBarStyle;
    static tabHomeTitle = settings.tabHomeTitle;
    static tabFoodTitle = settings.tabFoodTitle;
    static tabDrinksTitle = settings.tabDrinksTitle;
    static tabAccountTitle = settings.tabAccountTitle;
    static menuSegmentType = settings.menuSegmentType;
    static firebaseProject = settings.firebaseProject;
    static defaultLanguage = settings.defaultLanguage;
}
export var database = settings.database;
export var appVersion = settings.version;
