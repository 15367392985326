import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "pos-numpad",
  templateUrl: "numpad.component.html",
  styleUrls: ["numpad.component.scss"],
})
export class NumpadComponent implements OnInit {
  
  input: any;
  clearOnFirstInput = false;
  @Input() asModal = false;
  @Input() showModifierButtons = true;
  @Input() receiptSum: number;
  @Input() options;
  @Input() title: string;
  @Input() maskNumber = false;
  @Output() numpadChanged = new EventEmitter<any>();
  showArrowBack = true;
  constructor(private modalController: ModalController) { }

  ngOnInit() {
    if (this.options) {
      this.applyOptions(this.options);
    }
  }
  getMaskedNumber(str: string) {
    let masked = "";
    for (let index = 0; index < str.length; index++) {
      masked += "*";
    }
    return masked;
  }
  applyOptions(options) {
    this.input = { number: "", operator: "" };
    this.input.operator = options.operator ? options.operator : null;
    this.input.number = options.number ? options.number.toString() : "";
    this.showArrowBack = typeof (options.showArrowBack) === "boolean" ? options.showArrowBack : true;
    if (options.number) this.clearOnFirstInput = true;
  }
  goBack() {
    this.modalController.dismiss(null, "cancel");
  }
  getOperator() {
    if (!this.input) return;
    switch (this.input.operator) {
      case "percent": {
        return "Rabatt %";
      }
      case "price": {
        return "Pris";
      }
      case "quantity": {
        return "Antal";
      }
    }
  }
  setOperator(operator) {
    if (this.clearOnFirstInput) {
      this.input = { number: "", operator: "" };
      this.clearOnFirstInput = false;
    }
    if (!this.input) this.input = { number: "", operator: operator };
    else this.input.operator = operator;
  }
  clearInput() {
    this.input = undefined;
    this.numpadChanged.emit(null);
  }
  addNumber(number) {
    if (this.clearOnFirstInput) {
      this.input = { number: "", operator: this.input.operator };
      this.clearOnFirstInput = false;
    }
    if (!this.input) this.input = { number: "", operator: "" };
    this.input.number += number;
  }
  applyInput() {
    this.numpadChanged.emit(this.input);
    if (this.asModal) this.modalController.dismiss(this.input, "confirm");

    setTimeout(() => {
      this.numpadChanged.emit(null);
      this.clearInput();
    }, 100);
  }
}
