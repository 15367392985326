import { Component } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { DeliveryPlaceProvider } from '../services/delivery-place/delivery-place';
import { ContextProvider, AppInfo } from '../services/context/context';
import { CartProvider } from '../services/cart/cart';
import { Order, OrderProvider } from '../services/order/order';
import { ActivatedRoute } from '@angular/router';
import { StockModalComponent } from '../stock-balance/stock.component';
import { PaymentProvider } from '../services/payment/payment';
import { CartItem } from '../cart-items/cart-items';
import { MenuItem } from '../models';
import { LanguagePipe } from '../pipes/language.pipe';
import { LanguageProvider } from '../services/language/language.provider';

/**
 * Generated class for the DeliveryPlacePage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-delivery-place',
  templateUrl: 'delivery-place.html',
  styleUrls: ['delivery-place.scss'],
})
export class DeliveryPlacePage {
  modalCloseDelayTime = 500;
  pickupClicked = false;
  sitAtRestaurantClicked = false;
  tablesEnabled = false;
  tablesIcon = 'golf';
  tablesText = this.languagePipe.transform('Servera till mitt bord', 'DELIVERY_MY_TABLE', this.languageProvider.currentLanguage);
  takeawayEnabled = false;
  takeawayIcon = 'basket';
  takeawayText = this.languagePipe.transform('Ta med', 'DELIVERY_TO_GO', this.languageProvider.currentLanguage);
  sitAtRestaurantEnabled = false;
  sitAtRestaurantIcon = 'walk';
  sitAtRestaurantText = this.languagePipe.transform('Hämta i baren', 'DELIVERY_FETCH_BAR', this.languageProvider.currentLanguage);
  selectedTableNumber = 999;
  deliveryEnabled = true;
  deliveryText = this.languagePipe.transform('Utkörning', 'DELIVERY_HOME_DELIVERY', this.languageProvider.currentLanguage);
  order: Order;
  items: CartItem[];
  stockBalanceEnabled = false;
  constructor(
    public navCtrl: NavController,
    private cartProvider: CartProvider,
    private activatedRoute: ActivatedRoute,
    private orderProvider: OrderProvider,
    private deliveryPlaceProvider: DeliveryPlaceProvider,
    private contextProvider: ContextProvider,
    private modalController: ModalController,
    private paymentProvider: PaymentProvider,
    private languagePipe: LanguagePipe,
    public languageProvider: LanguageProvider
  ) {
    this.items = this.cartProvider.cart.items;
    const appInfo = this.contextProvider.clientContext.appInfo.value;
    if (!appInfo) { return; }
    this.deliveryPlaceProvider.tableOption = {
      fee: 0,
      minValue: appInfo.Context.Tables.LowerValue,
      maxValue: appInfo.Context.Tables.UpperValue,
      name: 'Tables',
    };

    this.tablesEnabled = appInfo.Context.Tables.enabled;
    this.tablesIcon = appInfo.Context.Tables.icon
      ? appInfo.Context.Tables.icon
      : this.tablesIcon;
    this.tablesText = appInfo.Context.Tables.text
      ? appInfo.Context.Tables.text
      : this.tablesText;
    this.takeawayEnabled = appInfo.Context.takeawayDelivery.enabled;
    this.takeawayIcon = appInfo.Context.takeawayDelivery.icon
      ? appInfo.Context.takeawayDelivery.icon
      : this.takeawayIcon;
    this.takeawayText = appInfo.Context.takeawayDelivery.text
      ? appInfo.Context.takeawayDelivery.text
      : this.takeawayText;
    this.sitAtRestaurantEnabled =
      appInfo.Context.sitAtRestaurantDelivery.enabled;
    this.sitAtRestaurantIcon = appInfo.Context.sitAtRestaurantDelivery.icon
      ? appInfo.Context.sitAtRestaurantDelivery.icon
      : this.sitAtRestaurantIcon;
    this.sitAtRestaurantText = appInfo.Context.sitAtRestaurantDelivery.text
      ? appInfo.Context.sitAtRestaurantDelivery.text
      : this.sitAtRestaurantText;
    this.deliveryEnabled =
      appInfo.Context.homeDelivery && appInfo.Context.homeDelivery.enabled;
    this.deliveryText = appInfo.Context.homeDelivery && appInfo.Context.homeDelivery.text
      ? appInfo.Context.homeDelivery.text
      : this.deliveryText;
    this.order = this.orderProvider.orderWaitingForApproval;
    this.stockBalanceEnabled = appInfo.Context.stockBalanceEnabled;
  }
  async forward() {
    try {
      const couponValid = await this.validateCouponAndDeliveryPlace(
        this.order.coupon,
        this.order.tableNumber
      );
      this.navCtrl.navigateForward(['../payment'], {
        relativeTo: this.activatedRoute,
      });
    } catch (error) {
      alert(this.languagePipe.transform(
        'Rabattkoden är inte giltig för detta serveringssätt',
        'DELIVERY_OFFER_ERROR',
        this.languageProvider.currentLanguage));
      return;
    }
  }

  async ionViewWillEnter() {

    if (this.stockBalanceEnabled === true) {

      const routename = this.contextProvider.getClient().routeName;

      await this.paymentProvider.checkStockBalance(routename, this.items).subscribe(async (result: any) => {
        const missing: {
          Name: string,
          key: string,
          quantity: number
        }[] = result.missing;

        const updatedCart: MenuItem[] = result.updatedCart;

        updatedCart.forEach((item: MenuItem) => {
          console.log(item.Name);
        });

        if (missing.length > 0) {
          // Failure
          const modal = await this.modalController.create({
            component: StockModalComponent,
            componentProps: { routeName: routename, data: missing, updatedCart },
            cssClass: 'stock-modal'
          });
          modal.present();
          await modal.onDidDismiss();
          this.dismiss();
        }
      }, error => {

      });
    }
  }

  takeawaySelected() {
    this.pickupClicked = true;
    setTimeout(() => {
      if (this.cartProvider.cart.hasAlcohol) {
        alert(this.languagePipe.transform(
          'Du kan inte beställa alkohol som take away',
          'DELIVERY_ALCOHOL_TAKE_AWAY',
          this.languageProvider.currentLanguage)
        );
        return;
      }
      this.order.tableNumber = 0;
      this.pickupClicked = false;
      this.forward();
    }, this.modalCloseDelayTime);
  }
  sitAtRestaurant() {
    this.sitAtRestaurantClicked = true;
    setTimeout(() => {
      this.order.tableNumber = -1;
      this.sitAtRestaurantClicked = false;
      this.forward();
    }, this.modalCloseDelayTime);
  }
  async homeDelivery() {
    if (this.cartProvider.cart.hasAlcohol) {
      alert(this.languagePipe.transform(
        'Du kan inte beställa hem alkohol...',
        'DELIVERY_ALCOHOL_DELIVERY',
        this.languageProvider.currentLanguage)
      );
      return;
    }
    this.order.tableNumber = -2;
    try {
      const couponValid = await this.validateCouponAndDeliveryPlace(
        this.order.coupon,
        this.order.tableNumber
      );
      setTimeout(() => {
        this.navCtrl.navigateForward(['../deliverToLocation'], {
          relativeTo: this.activatedRoute,
        });
      }, this.modalCloseDelayTime);
    } catch (error) {
      alert(this.languagePipe.transform(
        'Rabattkoden är inte giltig för detta serveringssätt',
        'DELIVERY_OFFER_ERROR',
        this.languageProvider.currentLanguage)
      );
      return;
    }

  }

  async promptForTableNumber() {
    // const alert = await this.deliveryPlaceProvider.promptForTableNumber();
    // const result = await alert.onDidDismiss();
    // if (result.role === 'cancel') { return; }
    // this.order.tableNumber = parseInt(result.data.values.tableNumber);
    // this.forward();
  }

  validateCouponAndDeliveryPlace(coupon, tableNumber) {
    let numberToVerify = parseInt(tableNumber);
    if (tableNumber > 0) { numberToVerify = 1; }
    const promise = new Promise((resolve, reject) => {
      if (!coupon || !coupon.validFor) {
        resolve({ coupon, tableNumber });
        return;
      }
      if (coupon.validFor[numberToVerify]) {
        resolve({ coupon, tableNumber });
        return;
      } else { reject({ coupon, tableNumber }); }
      return;
    });
    return promise;
  }

  dismiss() {
    this.navCtrl.navigateBack(['../main']);
  }
}
