import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "offer-details",
  styleUrls: ["offerDetails.component.scss"],
  templateUrl: "offerDetails.component.html",
})
export class OfferDetailsComponent {
  style;
  @Input() offer;

  constructor(private modalCtrl: ModalController) {

  }
  ngOnInit(){
    this.style = {
        "min-height": "220px",
        background: `url(${this.offer.ImageUrl})`,
        "background-size": "cover",
        "background-position": "center",
      };
      this.offer.description = this.offer.description.replace(/(\r\n|\n|\r)/gm,"<br>");
  }
  dismiss() {
    this.modalCtrl.dismiss(null, "cancel");
  }
  go() {
    this.modalCtrl.dismiss(this.offer, "confirm");
  }
}
