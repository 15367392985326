import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database';


@Injectable({
  providedIn: 'root'
})

export class PubqConfigProvider {
  private pubqConfig: BehaviorSubject<PubqConfig> = new BehaviorSubject(null);
  constructor(public http: HttpClient, public db: AngularFireDatabase) {
    const configRef = 'PubQConfig';
    this.db.object(configRef).valueChanges().subscribe((x: PubqConfig) => {
      this.pubqConfig.next(x);
    });
  }

  get config() {
    return this.pubqConfig;
  }

}
export class PubqConfig {
  ApiUrl = '';
  Authorization = '';
  Currency = '';
  EncryptionKey = '';
  bluesnapEncryptionKey = '';
  primeApiUrl = '';
}
