import { Component, ErrorHandler, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfiguration } from '../app.configuration';
import {
  // Platform,
  AlertController,
  ToastController,
  NavController,
  LoadingController,
  ModalController,
  IonContent
} from '@ionic/angular';
// import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
// import { SMS } from '@ionic-native/sms/ngx';
import { HttpClient } from '@angular/common/http';
import { PubqConfigProvider } from '../services/pubq-config/pubq-config';
import { UserProvider, User } from '../services/user/user';
import { AngularFireDatabase } from '@angular/fire/database';
import { ContextProvider } from '../services/context/context';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { CalendarModalOptions, CalendarModal } from 'ion2-calendar';
import { Storage } from '@ionic/storage';
import { LanguagePipe } from '../pipes/language.pipe';
import { LanguageProvider } from '../services/language/language.provider';

@Component({
  selector: 'page-table-booking',
  templateUrl: 'table-booking.html',
  styleUrls: ['table-booking.scss']
})
export class TableBookingPage {
  @ViewChild('content') IonContent: IonContent;
  user: User = null;
  activeTableBooking: any;
  userSubscription: Subscription;
  routeName: string;
  selectedNrOfGuests = 0;
  selectedDate: { label: string; date: any } = {
    label: 'NONE',
    date: undefined
  };
  bookingOptions;
  yearValues = [moment().year(), moment().year() + 1];
  monthShortNames = [];
  availableSlots: TimeSlot[] = [];
  loading = false;
  tableBookingSubscription: Subscription;
  contactPhone: string;
  searchComplete = false;
  constructor(
    // private platform: Platform,
    private activatedRoute: ActivatedRoute,
    private loadingCtrl: LoadingController,
    // private androidPermissions: AndroidPermissions,
    private errorHandler: ErrorHandler,
    private storage: Storage,
    // private sms: SMS,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private http: HttpClient,
    private pubqConfig: PubqConfigProvider,
    public navCtrl: NavController,
    public userProvider: UserProvider,
    private db: AngularFireDatabase,
    public contextProvider: ContextProvider,
    private modalController: ModalController,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe
  ) {
    moment.locale('sv-se');
    this.monthShortNames = moment.monthsShort();
    this.routeName = this.contextProvider.client.routeName;
    this.contactPhone = this.contextProvider.clientContext.appInfo.value.Context.restaurantPhoneNumber;
    this.setBookingOptions();
  }
  bookingIsCheckedIn() {
    return this.activeTableBooking && this.activeTableBooking.checkedIn && this.activeTableBooking.activeTabId;
  }
  goBack() {
    this.navCtrl.pop();
  }
  ngOnInit() {
    this.tableBookingSubscription = this.userProvider.activeTableBooking.subscribe(
      x => {
        if (!x) { return; }
        this.activeTableBooking = x;
        if (x) {
          x.dateTimePretty = moment
            .unix(x.startTimeUTC)
            .format('dddd, D MMMM YYYY')
            .toUpperCase();
          x.startTimePretty = moment
            .unix(x.startTimeUTC)
            .format('kk:mm')
            .toUpperCase();
          x.endTimePretty = moment
            .unix(x.endTimeUTC)
            .format('kk:mm')
            .toUpperCase();
        }
        this.activeTableBooking = x;
      }
    );
    this.userSubscription = this.userProvider.currentUser.subscribe(x => {
      if (!x == null) { return; }
      this.user = x;
    });
  }
  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.tableBookingSubscription.unsubscribe();
  }
  async removeBooking() {
    const loading = await this.loadingCtrl.create({
      spinner: 'circular',
      translucent: true
    });
    loading.present();
    await this.userProvider.removeTableBooking(
      this.user,
      this.routeName
    );
    loading.dismiss();
    this.navCtrl.back();
  }
  async openCalendar() {
    this.selectedDate = { label: 'PICKER', date: null };
    const options: CalendarModalOptions = {
      title: this.languagePipe.transform('Välj datum', 'BOOKING_CHOOSE_DATE', this.languageProvider.currentLanguage),
      color: 'primary',
      weekStart: 1,
      to: moment().add(2, 'months').toDate(),
      doneLabel: this.languagePipe.transform('Klar', 'CONFIRM', this.languageProvider.currentLanguage),
      closeLabel: this.languagePipe.transform('Avbryt', 'CANCEL', this.languageProvider.currentLanguage),
      closeIcon: false,
      doneIcon: false,
      weekdays:
        [this.languagePipe.transform('Sön', 'SUN', this.languageProvider.currentLanguage),
        this.languagePipe.transform('Mån', 'MON', this.languageProvider.currentLanguage),
        this.languagePipe.transform('Tis', 'TUE', this.languageProvider.currentLanguage),
        this.languagePipe.transform('Ons', 'WED', this.languageProvider.currentLanguage),
        this.languagePipe.transform('Tor', 'THU', this.languageProvider.currentLanguage),
        this.languagePipe.transform('Fre', 'FRI', this.languageProvider.currentLanguage),
        this.languagePipe.transform('Lör', 'SAT', this.languageProvider.currentLanguage)]

    };

    const myCalendar = await this.modalController.create({
      component: CalendarModal,
      componentProps: { options },
      cssClass: 'modal-drawer'
    });

    myCalendar.present();
    const result = await myCalendar.onDidDismiss();
    if (result.role !== 'done') { return; }
    this.setDatePickerDate({ detail: { value: result.data.time } });
  }
  getSelectedDateLabel() {
    if (!this.selectedDate.date) { return ''; }
    return moment(this.selectedDate.date)
      .format('dddd, D MMMM YYYY')
      .toUpperCase();
  }
  getNoAvailableTimesString(selectedNrOfGuests) {
    this.languagePipe.transform(`Tyvärr finns det inga lediga tider för ${selectedNrOfGuests} personer`, 'BOOKING_NO_AVAILABLE_TIMES_1', this.languageProvider.currentLanguage, [selectedNrOfGuests]);
  }
  selectDate(date) {
    this.selectedDate = date;
    this.queryTimeSlots(this.selectedDate.date);
  }
  setDatePickerDate(ev) {
    const date = moment(ev.detail.value);
    date
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0);
    this.selectedDate = { label: 'PICKER', date };
    this.queryTimeSlots(this.selectedDate.date);
  }
  setBookingOptions() {
    this.db.database
      .ref(`adminApp/clients/${this.routeName}/settings/tables`)
      .once('value')
      .then(x => {
        const data = x.val();
        this.bookingOptions.bookDurationHours = data.bookDurationHours;
        this.bookingOptions.slotIntervalHours = data.slotIntervalHours;
      });
    this.bookingOptions = {
      nrOfGuests: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      dates: [
        {
          label: this.languagePipe.transform('Idag', 'TODAY', this.languageProvider.currentLanguage),
          date: moment()
        },
        {
          label: moment()
            .add(1, 'days')
            .format('ddd'),
          date: moment().add(1, 'days')
        },
        {
          label: moment()
            .add(2, 'days')
            .format('ddd'),
          date: moment().add(2, 'days')
        }
      ]
    };
  }
  async sendSmsToRestaurant() {
    // if (this.platform.is('android')) {
    //   const hasPermission = await this.androidPermissions.checkPermission(
    //     'SEND_SMS'
    //   );
    //   if (!hasPermission) {
    //     const result = await this.androidPermissions.requestPermission(
    //       'SEND_SMS'
    //     );
    //     if (!result.hasPermission) {
    //       alert(
    //         this.languagePipe.transform('Tillåtelsen låter appen öppna ett förifyllt sms till restaurangen åt dig',
    //           'BOOKING_ALLOW_SMS', this.languageProvider.currentLanguage),
    //       );
    //       return;
    //     }
    //   }
    // }
    // this.sms
    //   .send(
    //     this.contactPhone,
    //     this.languagePipe.transform(`Hej det gäller bokningen ${this.activeTableBooking.dateTimePretty} \n kl. ${this.activeTableBooking.startTimePretty}\n ${this.activeTableBooking.peopleCount} pers. `,
    //       'BOOKING_SMS_TEXT', this.languageProvider.currentLanguage, [this.activeTableBooking.dateTimePretty, this.activeTableBooking.startTimePretty, this.activeTableBooking.peopleCount]),
    //     { replaceLineBreaks: true, android: { intent: 'INTENT' } }
    //   )
    //   .then(async x => {
    //     (
    //       await this.toastCtrl.create({
    //         message: this.languagePipe.transform('Skickat', 'SENT', this.languageProvider.currentLanguage),
    //         position: 'top',
    //         duration: 2000,
    //         cssClass: 'sent-toast'
    //       })
    //     ).present();
    //   })
    //   .catch(async er => { });
  }
  async cancelBooking(activeTableBooking) {
    const alert = await this.alertCtrl.create({
      message: this.languagePipe.transform('Är du säker på att du vill avboka?', 'BOOKING_CANCEL', this.languageProvider.currentLanguage),
      header: this.languagePipe.transform('Bekräfta avbokning', 'BOOKING_CANCEL', this.languageProvider.currentLanguage),
      buttons: [
        {
          role: 'cancel',
          text: this.languagePipe.transform('Avbryt', 'CANCEL', this.languageProvider.currentLanguage),
        },
        {
          role: 'confirm',
          text: this.languagePipe.transform('Ok', 'CONFIRM', this.languageProvider.currentLanguage),
        }
      ]
    });
    alert.present();
    const result = await alert.onDidDismiss();
    if (result.role !== 'confirm') { return; }
    this.loading = true;
    const url = `${this.pubqConfig.config.value.primeApiUrl}/canceltimeslot`;
    const data = {
      unitRouteName: this.routeName,
      bookingId: activeTableBooking.bookingId
    };
    this.http.post<any>(url, data).subscribe(async x => {
      await this.userProvider.deleteLocallySavedBooking(this.routeName);
      this.userProvider.setupTableBooking();
      const toast = await this.toastCtrl.create({
        message: this.languagePipe.transform('Ditt bord har avbokats', 'BOOKING_CANCELED', this.languageProvider.currentLanguage),
        position: 'top',
        duration: 2000,
        cssClass: 'debooked-toast'
      });
      toast.present();
      this.loading = false;
      this.navCtrl.pop();
    });
  }
  async queryTimeSlots(momentDate: moment.Moment) {
    const dateToQuery = momentDate.clone();
    dateToQuery.set('hours', 10);
    const hours = await this.getRestaurantOpeningHours(dateToQuery);
    this.bookingOptions.restaurantOpeningMoment = hours.open.utc();
    this.bookingOptions.restaurantClosingMoment = hours.closed.utc();
    this.loading = true;

    const url = `${this.pubqConfig.config.value.primeApiUrl}/getavailabletimeslots`;
    const data = {
      unitRouteName: this.routeName,
      seats: this.selectedNrOfGuests,
      dateUTC: moment(dateToQuery)
        .utc()
        .unix()
    };
    this.searchComplete = false;
    this.http.post<TimeSlotQueryResponse>(url, data).subscribe(x => {
      this.availableSlots = [];
      this.displayAvailableSlots(x.data);
      this.loading = false;
      this.searchComplete = true;
      setTimeout(() => {
        this.IonContent.scrollToPoint(0, 500, 800);
      }, 100);

    });
  }
  displayAvailableSlots(timestamps: any[]) {
    const openingMoment: moment.Moment = this.bookingOptions.restaurantOpeningMoment.utc();
    const closingMoment: moment.Moment = this.bookingOptions.restaurantClosingMoment.utc();
    // if (!timestamps || (timestamps && timestamps.length === 0)) {
    //   this.availableSlots.push({
    //     available: false,
    //     label: `${openingMoment
    //       .local()
    //       .format("kk:mm")}-${closingMoment.local().format("kk:mm")}`,
    //     timeMoment: moment()
    //   });
    //   return;
    // }
    timestamps.sort();
    const timeSlotSizeMinutes = this.bookingOptions.slotIntervalHours * 60;
    let previousTimeSlot;
    const firstTimeSlot = moment.unix(timestamps[0]).utc();

    const openingDuration = moment
      .duration(firstTimeSlot.diff(openingMoment))
      .asMinutes();
    if (openingDuration > timeSlotSizeMinutes) {
      const timeOfFirstBusySlot: moment.Moment = openingMoment;
      const timeOfLastBusySlot: moment.Moment = openingMoment
        .clone()
        .add(openingDuration - timeSlotSizeMinutes, 'minutes');
      const gap = {
        available: false,
        label: `${timeOfFirstBusySlot
          .local()
          .format('kk:mm')}-${timeOfLastBusySlot.local().format('kk:mm')}`,
        timeMoment: timeOfFirstBusySlot.utc()
      };
      this.availableSlots.push(gap);
    }
    timestamps.forEach(x => {
      const timeMoment = moment.unix(x);
      const label = timeMoment
        .clone()
        .local()
        .format('kk:mm');
      const timeSlot = {
        label,
        timeMoment: timeMoment.utc(),
        available: true
      };
      let duration;
      if (previousTimeSlot) {
        duration = moment
          .duration(timeSlot.timeMoment.diff(previousTimeSlot.timeMoment))
          .asMinutes();
      }
      if (duration > timeSlotSizeMinutes) {
        const timeSlotsGap = this.createTimeSlotGap(
          previousTimeSlot.timeMoment,
          timeSlotSizeMinutes,
          duration
        );
        this.availableSlots.push(timeSlotsGap);
      }
      this.availableSlots.push(timeSlot);
      previousTimeSlot = timeSlot;
    });
    const lastTimeSlot = moment.unix(timestamps[timestamps.length - 1]).utc();
    const closingDuration = moment
      .duration(closingMoment.diff(lastTimeSlot))
      .asMinutes();
    if (
      Math.floor(closingDuration) >
      this.bookingOptions.bookDurationHours * 60
    ) {
      const gap = this.createTimeSlotGap(
        lastTimeSlot,
        timeSlotSizeMinutes,
        closingDuration
      );
      this.availableSlots.push(gap);
    }
    const now = moment().unix();
    for (let index = this.availableSlots.length - 1; index >= 0; index--) {
      if (now > this.availableSlots[index].timeMoment.unix()) {
        this.availableSlots.splice(index, 1);
      }
    }
    this.availableSlots = this.availableSlots.filter(x => x.available);
  }
  private createTimeSlotGap(slot: moment.Moment, timeSlotSize, gapDuration) {
    const timeOfFirstBusySlot = slot.clone().add(timeSlotSize, 'minutes');
    const timeOfLastBusySlot = slot
      .clone()
      .add(gapDuration - timeSlotSize, 'minutes');
    const labelStart = timeOfFirstBusySlot.local().format('kk:mm');
    const labelEnd = timeOfLastBusySlot.local().format('kk:mm');

    const timeslotsGap = {
      available: false,
      label: `${labelStart === labelEnd ? labelStart : labelStart + '-' + labelEnd
        }`,
      timeMoment: timeOfFirstBusySlot.utc()
    };
    return timeslotsGap;
  }
  private async getRestaurantOpeningHours(date: moment.Moment) {
    date = date.clone();
    const openMoment = date
      .clone()
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0);
    const closeMoment = date.clone();
    const weekdays = (
      await this.db.database
        .ref(
          `adminApp/clients/${this.routeName}/settings/openingHours/weekdays`
        )
        .once('value')
    ).val();
    const dayOfWeek = date
      .locale('en-us')
      .format('dddd')
      .toLowerCase();
    const matchingWeekdays = weekdays.filter(x => x.type === dayOfWeek);
    if (matchingWeekdays.length > 0) {
      const weekday = matchingWeekdays[0];
      const openHours = weekday.start.split(':')[0];
      const openMinutes = weekday.start.split(':')[1];
      const closeHours = weekday.end.split(':')[0];
      const closeMinutes = weekday.end.split(':')[1];

      return {
        open: openMoment
          .utc()
          .set('date', date.date())
          .set('hours', openHours)
          .set('minutes', openMinutes)
          .set('second', 0),
        closed: closeMoment
          .utc()
          .set('date', date.date())
          .set('hours', closeHours)
          .set('minutes', closeMinutes)
          .set('second', 0)
      };
    }
  }
  async deleteLocalStorage(routeName) {
    const storageKey = `booking_${routeName}`;
    return await this.storage.remove(storageKey);
  }
  reserveSlot(slot) {
    this.navCtrl.navigateForward(['../bookSlot'], {
      relativeTo: this.activatedRoute,
      queryParams: { timeSlot: slot.timeMoment.unix(), seats: this.selectedNrOfGuests }
    });
  }

  selectGuests(guests) {
    this.selectedNrOfGuests = guests;
    if (this.selectedDate.date) { this.queryTimeSlots(this.selectedDate.date); }
  }

  back() {
    this.navCtrl.pop();
  }
}
class TimeSlotQueryResponse {
  data;
}
class TimeSlot {
  label: string;
  timeMoment: any;
  available: boolean;
}
