

export const lang = {
    get: (string, parameters?) => {
        switch (string) {
            case 'BONUS_USE': return `Use ${parameters[0]}​​​ bonus points`;
            case 'STOCK_ALERT': return `Unfortunately, ${parameters[0]}​​​ is out of stock. Your cart has been adjusted to what was available so you can continue shopping.`;
            case 'BOOKING_NO_AVAILABLE_TIMES_1': return `Unfortunately there is no available timeslot for ${parameters[0]} persons.`;
            case 'BOOKING_SMS_TEXT': return `Hello, this is regarding your reservation ${parameters[0]} \n ${parameters[1]}\n ${parameters[2]} persons.`;
            case 'OFFERS_ACTIVATED': return `Activated ${parameters[0]}`;
            case 'CART_ITEMS_REMOVE': return `Do you want to remove ${parameters[0]}?`;
            case 'CART_ALLERGY_CONTACT': return `, between ${parameters[0]}-${parameters[1]}, we will happily answer your questions about content`;
            case 'EMAIL_CORRECT': return `Is ${parameters[0]} correct?`;
            case 'LOGIN_SEND_SMS': return `Send text to ${parameters[0]}?`;
            case 'LOGIN_ERROR': return `Oops! Something went wrong. Please try another way to sign in.\n ${parameters[0]}`;
        }
    },

    LOGIN_SEND_SMS: 'Sent text to [phoneNumber]?',
    LOGIN_ERROR: 'Oops! Something went wrong. Please try another way to sign in. \n [Error details]',
    PUSH_TITLE: 'Notifications',
    PUSH_ALERT_TXT: 'To view your order status you must allow push notifications',
    PUSH_ALERT_BTN: 'Click here',
    PUSH_INFO: 'Choose the restaurants you want to get offers from. We promise to treat your trust responsibly.',
    PUSH_PERMISSION: 'In order to make this change, please go to the phone settings and allow push notifications for the app.',
    PUSH_CHANGE_SELECTION: 'You can always change your selection under Account => Push notifications',
    CURRENT: 'Current',
    NEW_ORDER: 'New order',
    WELCOME: 'Welcome!',
    DONE: 'Done',
    BACK: 'Back',
    OPT_OUT: 'No thank you',
    CONFIRM: 'Confirm',
    OK: 'OK',
    CANCEL: 'Cancel',
    DATE_TIME: 'Date and time',
    SENT: 'Sent',
    BOOK: 'Book',
    SEARCH: 'Search…',
    ERROR: 'Error',
    YES: 'Yes',
    NO: 'No',
    CARD_NO: 'Card number',
    ADD: 'Add',
    NAME: 'Name',
    PHONE: 'Phone',
    WRITE_TABLENUMBER: 'Write your table number',
    TABLENUMBER: 'Table number',
    TIPS: 'Tips',
    PAY: 'Pay',
    PAYED: 'Paid',
    SUM: 'Sum',
    CLOSED: 'Closed',
    ACTIVATE: 'Activate',
    DISCOUNT: 'Discount',
    ORDER: 'Order',
    FORWARD: 'Forward',

    SUN: 'Sun',
    MON: 'Mon',
    TUE: 'Tue',
    WED: 'Wed',
    THU: 'Thu',
    FRI: 'Fri',
    SAT: 'Sat',
    TODAY: 'Today',
    BOOKING_THANKS_1: 'Thank\'s for the visit!',
    BOOKING_THANKS_2: 'See you again soon',
    MY_BOOKING: 'My booking',
    BOOKING_QUESTIONS: 'Do you have questions regarding your booking?',
    BOOKING_CONTACT_US_1: 'Contact us at',
    BOOKING_CONTACT_US_2: 'or click here to sent a text',
    BOOKING_CONFIRM: 'Order confirmation',
    BOOKING_APPCODE: 'Your code is',
    BOOKING_ICONS: 'Order by using the icons below',
    BOOKING_TITE: 'Book table',
    BOOKING_NUMBER_GUESTS: 'Number of guests',
    BOOKING_LARGER_GROUPS: 'Booking for larger groups',
    BOOKING_LARGER_GROUPS_CONTACT: 'If you want to book for a larger group, please contact',
    BOOKING_NO_AVAILABLE_TIMES_1: 'No times available ',
    BOOKING_ALLOW_SMS: 'The permission allows the app to open a pre-filled SMS to the restaurant for you',
    BOOKING_CANCEL: 'Are you sure, you want to cancel your booking?',
    BOOKING_CONFIRM_CANCEL: 'Confirm cancelation',
    BOOKING_CANCELED: 'Your booking has been canceled',
    STOCK_TITLE: 'Out of stock',
    BONUS_POINTS: 'Bonus points',
    PAYMENT_TITLE: 'Pay for',
    PAYMENT_DELIVERY: 'Delevery costs',
    PAYMENT_DISCOUNT: 'Discount',
    PAYMENT_BONUS: 'Use bonus points',
    PAYMENT_FIRST_DISCOUNT: 'on your way to your first discount!',
    PAYMENT_EXTRA: 'Extra',
    PAYMENT_TOTAL: 'Total',
    PAYMENT_PAYMENTS: 'Payment method',
    PAYMENT_TABLETAB: 'Add to table tab',
    PAYMENT_SWISH: 'Pay with Swish',
    PAYMENT_VIPPS: 'Pay with Vipps',
    PAYMENT_APPLEPAY: 'Pay with Apple Pay',
    PAYMENT_SAVED_CARDS: 'Saved cards',
    PAYMENT_ADD_CARD: 'Add card',
    PAYMENT_SCROLL: 'Scroll down to pay',
    PAYMENT_PAY: 'Pay',
    PAYMENT_OFFER_ERROR_HEADER: 'These offers could not be activated',
    PAYMENT_OFFER_ERROR_TEXT: 'They do not apply to your chosen type of serving',
    PAYMENT_ERROR_MAINTENANCE: 'An update is currently underway and your purchase cannot be completed',
    PAYMENT_ERROR: 'Make sure that your card can be used for online purchases and that there is a sufficient amount available',
    CHARGE_WAIT: 'Please wait ... ',
    CHARGE_TAB_TOAST: 'Your tab has been paid and a receipt has been created on your account',
    CHARGE_SWISH_INIT: 'Request Swish for payment',
    CHARGE_ERROR: 'Something went wrong with your payment. Please contact the restaurant.',
    CHARGE_VERIFY: 'Verifying',
    ADD_CARD_ADDING: 'Adding card',
    ADD_CARD_NEW_CARD: 'Add new card',
    ADD_CARD_ERROR_E1: 'Something went wrong. Please try again.',
    ADD_CARD_ERROR_E3: 'The restaurant cannot add card. Please contact the personnel.',
    ADD_CARD_ERROR_E4: 'Invalid information',
    ADD_CARD_ERROR_E5: 'Incorrect card type',
    ADD_CARD_ERROR_E6: 'Incorrect terminal ID. Please contact the personnel.',
    ADD_CARD_ERROR_E7: 'Not allowed! Please contact the personnel.',
    ADD_CARD_ERROR_E8: 'Incorrect dealer ID. Please contact the personnel.',
    ADD_CARD_ERROR_E9: 'Incorrect date. Please contact the personnel.',
    ADD_CARD_ERROR_E10: 'Incorrect card number. Please check you have entered correctly.',
    ADD_CARD_ERROR_E11: 'Incorrect month/year. Please check you have entered correctly.',
    ADD_CARD_ERROR_E12: 'Incorrect name on card. Please contact personnel.',
    ADD_CARD_ERROR_E13: 'The restaurant has incorrect setting. Please contact the personnel.',
    ADD_CARD_ERROR_E14: 'Incorrect CVV code. Please check you have entered correctly.',
    ADD_CARD_NICK_TITLE: 'What do you want to name your card?',
    ADD_CARD_NICK_MSG: 'The card will be safely encrypted and stored for future use',
    ADD_CARD_NICK_PLACEHOLDER: 'Card Nickname',
    ADD_CARD_SECURITY_INFO: 'We process your card information safely. All sensitive information is stored with a certified payment provider.',
    OFFERS: 'Offers',
    OFFERS_NONE: 'There are currently no offers to show',
    MENU_DRINKS_CLOSED: 'Drinks are currently not available',
    MENU_ORDER_FULL: 'You can not add further to your order',
    MENU_NOT_AVAILABLE: 'currently not available',
    MENU_NO_PRODUCTS: 'No products to show',
    ITEM_DESCRIPTION_OUT_OF_STOCK: 'Out of stock',
    ITEM_DESCRIPTION_SCROLL: 'Scroll down',
    COMMENT: 'Comment',
    BAB_NAME: 'The name of your dish',
    BAB_ADD: 'Add',
    BAB_TYPE_SIZE: 'Select type and size',
    BAB_DRESSING: 'Dressing',
    BAB_TOPPINGS: 'Toppings',
    BAB_SIDEORDER: 'Choose side order',
    BAB_EXTRAS: 'Choose Extras',
    BAB_BURGER_NAME: 'Name your burger',
    BAB_MANY: 'Select how many you want',
    CHOOSE_RESTAURANT: 'Select restaurant',
    DELIVERY_MY_TABLE: 'Serve at my table',
    DELIVERY_TO_GO: 'To go',
    DELIVERY_FETCH_BAR: 'Pick up at the bar',
    DELIVERY_HOME_DELIVERY: 'Home delivery',
    DELIVERY_OFFER_ERROR: 'The discount code is not valid for this type of serving',
    DELIVERY_ALCOHOL_TAKE_AWAY: 'You cannot order alcohol as take away',
    DELIVERY_ALCOHOL_DELIVERY: 'You cannot order alcohol for delivery',
    DELIVERY_SERVING: 'Serving',
    DELIVERY_QUESTION: 'How do you want to receive your order?',
    LOCATION_CHOSE: 'Choose location',
    LOCATION_ADRESS: 'Your address',
    LOCATION_QUESTION: 'Delivery to here?',
    LOCATION_FEE: 'Delivery fee',
    LOCATION_COMMENT: 'Comment (floor, zip code etc.)',
    LOCATION_UNFORTUNATELY_OTHER_ADRESS: 'Unfortunately, we cannot yet deliver to this address. Please try another address. ',
    LOCATION_OTHER_ADRESS: 'Please try another address',
    CUSTOMER_TABS_ATTENTION_MSG: 'We will be with you immediately',
    CUSTOMER_TABS_PAY_IN_APP: 'Pay in the app',
    CUSTOMER_TABS_CALL_WAITER: 'Call waiter',
    CUSTOMER_TABS_TIPS: 'Do you want to tip our personnel?',
    CUSTOMER_TABS_CUSTOM_AM1: 'Optional amount...',
    CUSTOMER_TABS_CUSTOM_AM2: 'Optional amount...',
    CUSTOMER_TAB: 'Tab',
    CUSTOM_TICKET_VALID: 'Valid until',
    CLIENT_OPEN_HOURS: 'Opening hours',
    CART_ITEMS_APPLYING_OFFER: 'Applying promo code',
    CART_ITEM_OFFER_NOT_VALID: 'The promo code is not valid',
    CART_ITEM_COMMENT_EXAMPLE: '"E.g. no cilantro"',
    CART_ITEM_COMMENT: 'Write your comment here',
    CART_ITEM_REMOVE_CODE: 'Remove code?',
    CART_ITEM_18Y: '18+ years and valid ID is required when purchasing alcohol',
    CART_ITEM_ALCOHOL: 'No discount on alcohol',
    CART_NO_ACTIVE_TAB: 'You have no active tab',
    CART_GO_TO_MENY: 'Go to menu and place your first order',
    CART_PREPARNG_SWISH: 'Preparing Swish payment',
    CART_PAYMENT_ABORTED: 'Payment was interrupted',
    CART_CREATING_ORDER: 'Creating order and adding to tab',
    CART_SOME_ERROR: 'Something went wrong. Please try again.',
    CART_CHECK_CARD: 'Please make sure that your card can be used for online purchases and that there is a sufficient amount available',
    CART_WEEK_MENU: 'This weeks menu',
    CART_ORDER_TAKEWAY: 'Order takeaway',
    CART_MY_BOOKING: 'My bookings',
    CART_BOOK_TABLE: 'Book a table',
    CART_ALLERGIC_CONTACT: 'Allergic? Contact the personnel at',
    CART_PERSONNEL_CARD: 'Personnel card',
    CART_WELCOME_READ: 'Read more',
    CART_WELCOME_OPENING_HOURS: 'Opening hours and contact information',
    CUSTOMER_TAB_KEY_KOD: 'Table code',
    CUSTOMER_TAB_KEY_WRONG_CODE: 'Wrong table code',
    CUSTOMER_TAB_KEY_SAME_CODE: 'Same code again? Click here',
    CUSTOMER_CODE_TYPE_4_DIGITS: 'Type 4 digits',
    ACTIVE_ORDER_ONGOING: 'Ongoing orders',
    ORDERS: 'Orders',
    ACTIVE_ORDER_DEL_CUSTOMER: 'The kitchen has received your order and you will be notified when the order is being delivered to you',
    ACTIVE_ORDER_WAITING: 'Thanks! Awaiting approval',
    ACTIVE_ORDER_TAKEAWAY: 'The kitchen has received your order and you will be notified with a pick-up time as soon as possible',
    ACTIVE_ORDER_TABLE: 'Thanks! We are now preparing your order and will deliver it to you as soon as we can!',
    ACTIVE_ORDER_READY: 'Your order is ready',
    ACTIVE_ORDER_FOOD_READY: 'Your meal is ready',
    ACTIVE_ORDER_DRINK_READY: 'Your drink is ready',
    ACTIVE_ORDER_TICKET: 'Here is your order ticket',
    ACTIVE_ORDER_SHOW_SCREEN: 'Show this screen at the counter ',
    ACTIVE_ORDER_HOME_DELIVERY_OTW: 'Your order is now leaving the restaurant and is on its way to you!',
    ACTIVE_ORDER_TABLE_DELIVERY_OTW: 'Your order is being delivered to your table',
    ACTIVE_ORDER_RECIEVED_Q: 'Have you recieved your order? ',
    ACTIVE_ORDER_CLOSE_WINDOW: 'This window disappears when you select Yes',
    ACCOUNT_REMOVE_CARD: 'Remove card',
    ACCOUNT_REMOVE_CARD_2: 'Do you want to remove card?',
    ACCOUNT: 'Account',
    LOGOUT: 'Log out',
    CREATE_LOGIN: 'Create account / Log in',
    ME: 'My account',
    BONUSPOINTS: 'Bonus points',
    HANDLE_NOTIFICATIONS: 'Handle notofications',
    SAVED_CARDS: 'Saved cards',
    MY_RECIEPTS: 'My reciepts',
    RECIEPTS: 'Reciept',
    PURCHASE_HISTORY_FILL_MAIL: 'Type your e-mail address',
    PURCHASE_HISTORY_SENDING_RECIEPT: 'Sending reciept to',
    PURCHASE_HISTORY_SENDING_SENT: 'Reciept sent to',
    PURCHASE_HISTORY_MAIL_RECIEPT: 'Send reciept',
    PURCHASE_HISTORY_BUY_AT: 'Buy at',
    PURCHASE_HISTORY_PHONE: 'Phone',
    PURCHASE_HISTORY_VATID: 'Vat ID',
    DATE: 'Date',
    PURCHASE_HISTORY_ORDER: 'Order',
    PURCHASE_HISTORY_DISCOUNT: 'Discount',
    PURCHASE_HISTORY_TOTAL: 'Total',
    PURCHASE_HISTORY_SUM: 'Sum',
    PURCHASE_HISTORY_COLLECTED_DISCOUNT: 'Collected Discount',
    PURCHASE_HISTORY_ROUNDING: 'Rounding',
    PURCHASE_HISTORY_CONTROL_UNIT: 'C.Unit',
    PURCHASE_HISTORY_RECIEPT_NO: 'Reciept number',
    PURCHASE_HISTORY_VAT: 'Vat',
    PURCHASE_HISTORY_NETTO: 'Netto',
    PURCHASE_HISTORY_Brutto: 'Brutto',
    MENU_NOT_UPDATED: 'It appears that the restaurant has not yet updated the menu',
    WEEK: 'Week',
    LOGIN: 'Log in',
    LOGIN_PW_6: 'Your password must contain at least 6 digits',
    LOGIN_EMAIL_FORMAT: 'Your e-mail is incorrect. Please check you have entered correctly and try again. ',
    LOGIN_EMAIL_IN_USE: 'This e-mail is already in use',
    LOGIN_NEW_USER: 'New user',
    LOGIN_FINALIZING: 'Finalizing',
    LOGIN_CONTACTING_FACEBOOK: 'Contacting Facebook',
    LOGIN_PHONE: 'Log in with phone number',
    LOGIN_APPLE: 'Log in with Apple',
    LOGIN_FACEBOOK: 'Log in with Facebook',
    LOGIN_EMAIL: 'Log in with e-mail',
    LOGIN_TERMS: 'Terms and conditions',
    LOGIN_NO_ACCOUNT_Q: 'No account?',
    LOGIN_CREATE: 'Create',
    LOGIN_RESET: 'Reset',
    LOGIN_NAME_NEEDED: 'Your name is needed to match you with your orders',
    LOGIN_HI: 'Hi',
    LOGGING_IN: 'Logging in',
    LOGIN_WAITING_CODE: 'Waiting for code...',
    LOGIN_VERIFYI: 'Verifying',
    SEND_SMS_CODE: 'Send sms code',
    LOGGING_IN_2: 'Logging in',
    LOGIN_WRITE_EMAIL: 'Type your e-mail',
    OFFER_TO_LOGIN_TITLE: 'You can login!',
    OFFER_TO_LOGIN_SUBHEADER: 'Do you want to login?',
    COMPLETE_ORDER_TITLE: 'Your order is completed!',
    COMPLETE_ORDER_SUBHEADER: 'Do you want to open your active orders?',
    EMPTY_ACTIVE_ORDER_PAGE: 'You don\'t have any active orders!',
    EMAIL: 'E-mail',
    EMAIL_SENT: 'E-mail sent',
    EMAIL_FOLLOW_INSTRUCTIONS: 'Open your inbox and follow the instructions',
    HOUR: 'Hour',
    MINUTE: 'Minute',
    CART_SERVE_NOW: 'Serve now',
    ADDITIONAL_ACCESSORIES: 'Additional accessories',
    // Reepay charge errors
    ACQUIRER_ERROR: 'Something went wrong with your payment. Please contact the restaurant.',
    INSUFFICIENT_FOUNDS: 'Something went wrong with your payment. Please contact the restaurant.',
    PAYMENT_NOT_AUTHORIZED: 'Something went wrong with your payment. Please contact the restaurant.',
    CREDIT_CARD_EXPIRED: 'Something went wrong with your payment. Please contact the restaurant.',
    DECLINED_BY_ACQUIRER: 'Something went wrong with your payment. Please contact the restaurant.',
    CREDIT_CARD_LOST_OR_STOLEN: 'Something went wrong with your payment. Please contact the restaurant.',
    CREDIT_CARD_SUSPECTED_FRAUD: 'Something went wrong with your payment. Please contact the restaurant.',
    REFOUND_AMOUNT_TOO_HIGH: 'Something went wrong with your payment. Please contact the restaurant.',
    AUTHORIZATION_EXPIRED: 'Something went wrong with your payment. Please contact the restaurant.',
    AUTHORIZATION_AMOUNT_EXCEEDED: 'Something went wrong with your payment. Please contact the restaurant.',
    AUTHORIZATION_VOIDED: 'Something went wrong with your payment. Please contact the restaurant.',
    SCA_REQUIRED: 'Something went wrong with your payment. Please contact the restaurant.',
    RISK_FILTER_BLOCK: 'Something went wrong with your payment. Please contact the restaurant.'
};
