import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserProvider } from '../services/user/user';
import { UnitProvider } from '../services/unitProvider';
import { LanguageProvider } from '../services/language/language.provider';
import { LanguagePipe } from '../pipes/language.pipe';

@Component({
  selector: 'app-push',
  templateUrl: 'push.component.html',
  styleUrls: ['push.component.scss'],
})
export class PushComponent {
  @Input() routeName;
  hasPermission = false;
  isSubscribedToTopic = false;
  units: any[] = [];
  debug = [];
  @Input() showTopics = false;
  @Input() showNotifications = false;
  constructor(
    private modalController: ModalController,
    private userService: UserProvider,
    private unitService: UnitProvider,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe
  ) {}

  async ngAfterViewInit() {
    this.checkPermissions();
    if (this.showTopics) { this.setupUnits(); }
  }

  setupUnits() {
    this.unitService.units.subscribe((x) => {
      if (!x) { return; }
      x.forEach(async (x: any) => {
        x.subscribed = await this.userService.isSubscribedToTopic(x.routeName);
      });
      x.sort((a: any, b: any) => {
        if (a.subscribed && !b.subscribed) { return -1; } else { return 0; }
      });
      this.units = x;
    });
  }

  async toggleSubscription(unit) {
    // if (!(await this.userService.hasPushPermission())) {
    //   this.userService.registerToken();
    // }
    // if (unit.subscribed) { this.userService.unsubscribeFromTopic(unit.routeName); }
    // else { this.userService.subscribeToTopic(unit.routeName); }
    // unit.subscribed = !unit.subscribed;
  }

  async checkPermissions() {
    // this.hasPermission = await this.userService.hasPushPermission();
    // this.hasSeenPermissionRequest = await this.userService.hasSeenPushPermissionRequest();
    // if (!this.hasPermission && this.hasSeenPermissionRequest) {
    //   alert(
    // tslint:disable-next-line:max-line-length
    //     this.languagePipe.transform('Du måste öppna telefonens inställningar och tillåta pushnotiser för appen innan du kan göra ändringar här',
    //     'PUSH_PERMISSION', this.languageProvider.currentLanguage)
    //   );
    //   this.dismiss();
    // }
  }

  async requestPushPermission() {
    this.hasPermission = await this.userService.requestPushPermission();
    if (!this.hasPermission) {
      alert(this.languagePipe.transform('Du kan alltid ändra ditt val under Konto => Pushnotifikationer',
      'PUSH_PERMISSION', this.languageProvider.currentLanguage));
    }
    this.modalController.dismiss(
      { hasPushPermission: this.hasPermission },
      'confirm'
    );
  }

  async subscribeToTopic(routeName) {
    this.isSubscribedToTopic = await this.userService.subscribeToTopic(
      routeName
    );
  }

  async unsubscribeFromTopic() {
    this.userService.unsubscribeFromTopic(this.routeName);
  }
  async debugTopics() {
    this.debug = await this.userService.getSubscriptions();
    alert(JSON.stringify(this.debug));
  }

  dismiss() {
    this.modalController.dismiss(
      { hasPushPermission: this.hasPermission },
      'cancel'
    );
  }

  forward() {
    this.modalController.dismiss(null, 'confirm');
  }
}
