import { Component, Input } from '@angular/core';
import { IonNav, ModalController, AlertController, LoadingController } from '@ionic/angular';
import { LanguagePipe } from 'src/app/pipes/language.pipe';
import { LanguageProvider } from 'src/app/services/language/language.provider';
import { UserProvider } from 'src/app/services/user/user';
import { LoginCompleteComponent } from './login-complete.component';
import { RegisterEmail } from './registerEmail.component';
import * as firebase from 'firebase';
@Component({
  selector: 'page-email-form',
  templateUrl: 'emailform.html',
  styleUrls: ['login.scss']
})
export class EmailForm {
  email: string;
  password: string;
  @Input() nav: IonNav;
  constructor(
    private userProvider: UserProvider,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe
  ) {
    this.email = '';
    this.password = '';
  }
  async ok() {
    const loading = await this.loadingCtrl
      .create({
        spinner: 'circular',
        duration: 10000,
        message: this.languagePipe.transform('Loggar in', 'LOGGING_IN_2', this.languageProvider.currentLanguage)
      });
    loading.present();
    try {
      const user = firebase.auth().currentUser;
      if (user.isAnonymous) {
        await user.delete();
      }
      const result = await this.userProvider.signInWithEmail(this.email, this.password);
      loading.dismiss();
      const userId = result.user.uid;
      this.nav.push(LoginCompleteComponent, { nav: this.nav, userId });
    } catch (error) {
      await this.userProvider.createAnonymousUser();
      this.showError(error);
      loading.dismiss();
    }

  }
  dismiss() {
    this.nav.pop();
    // this.viewCtrl.dismiss();
  }
  async showCreateAccount() {
    this.nav.push(RegisterEmail, { nav: this.nav });
  }
  async promptForPasswordReset() {
    const prompt = await this.alertCtrl.create({
      header: this.languagePipe.transform('Skriv in din mail', 'LOGIN_WRITE_EMAIL', this.languageProvider.currentLanguage),
      message: '',
      inputs: [
        {
          name: 'email',
          placeholder: this.languagePipe.transform('Email', 'EMAIL', this.languageProvider.currentLanguage)
        }
      ],
      buttons: [
        {
          text: this.languagePipe.transform('Avbryt', 'CANCEL', this.languageProvider.currentLanguage),
          handler: data => { }
        },
        {
          text: this.languagePipe.transform('Ok', 'CONFIRM', this.languageProvider.currentLanguage),
          handler: data => { }
        }
      ]
    });
    prompt.present();
    const result = await prompt.onDidDismiss();
    if (!result.data.values.email) { return; }
    this.resetPassword(result.data.values.email);
  }
  async resetPassword(email) {
    const alert = await this.alertCtrl.create({
      header: this.languagePipe.transform('Mail skickat', 'EMAIL_SENT', this.languageProvider.currentLanguage),
      subHeader:
      this.languagePipe.transform('Öppna din inkorg och följ instruktionerna',
                              'EMAIL_FOLLOW_INSTRUCTIONS', this.languageProvider.currentLanguage),
      buttons: [this.languagePipe.transform('OK', 'OK', this.languageProvider.currentLanguage)]
    });

    this.userProvider
      .resetPassword(email)
      .then(x => {
        alert.present();
      })
      .catch(error => {
        this.showError(error);
      });
  }
  async showError(error) {
    const alert = await this.alertCtrl.create({
      header: this.languagePipe.transform('Fel', 'ERROR', this.languageProvider.currentLanguage),
      subHeader: error,
      buttons: [this.languagePipe.transform('OK', 'OK', this.languageProvider.currentLanguage)]
    });
    alert.present();
  }
}
