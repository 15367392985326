import { Component } from '@angular/core';
import { User, UserProvider } from '../services/user/user';
import { CartProvider } from '../services/cart/cart';
import { ContextProvider } from '../services/context/context';
import { AppConfiguration } from '../app.configuration';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage {
  foodParam = "food";
  drinksParam = "drinks"
  foodTabTitle = AppConfiguration.tabFoodTitle;
  drinksTabTitle = AppConfiguration.tabDrinksTitle;
  homeTabTitle = AppConfiguration.tabHomeTitle;
  accountTabTitle = AppConfiguration.tabAccountTitle;
  user: User;
  unitHasCustomTicketEnabled = false;
  userHasCustomTicket = false;
  activeTabsCount = 0;
  foodTabEnabled = true;
  drinksTabEnabled = true;
  foodTabHidden = false;
  drinksTabHidden = false;
  iconsRoot = "assets/icons/mmm";
  home1 = `${this.iconsRoot}/table.svg`;
  home2 = `${this.iconsRoot}/table-2.svg`;
  food1 = `${this.iconsRoot}/tapas-2.svg`;
  food2 = `${this.iconsRoot}/tapas.svg`;
  drinks1 = `${this.iconsRoot}/champagne-glass.svg`;
  drinks2 = `${this.iconsRoot}/champagne-bottle.svg`;
  account1 = `${this.iconsRoot}/smile.svg`;
  account2 = `${this.iconsRoot}/smile2.svg`;
  selectedTab = "tab1";
  constructor(public cartProvider: CartProvider, private userService: UserProvider, private contextProvider: ContextProvider) {
    this.userService.currentUser.subscribe(x => {
      this.user = x;
    });
    this.contextProvider.clientContext.appInfo.subscribe(x => {
      if (!x)
        return;
      if (typeof (x.Context.foodTab) === "undefined" || typeof (x.Context.drinksTab) === "undefined")
        return;
      this.foodTabEnabled = x.Context.foodTab.enabled;
      this.drinksTabEnabled = x.Context.drinksTab.enabled;
      this.foodTabHidden = x.Context.foodTab.hidden;
      this.drinksTabHidden = x.Context.drinksTab.hidden;
      this.unitHasCustomTicketEnabled = x.Context.customTickets;
    });
  }
  itemsInCart() {
    const length = this.cartProvider.cart.totalItems;

    return length > 0 ? length : undefined;
  }
  ngOnInit() {
  }
  hasCustomTicket() {
    return this.userHasCustomTicket && this.unitHasCustomTicketEnabled;
  }
  tabsChanged(ev) {
    this.selectedTab = ev.tab;
  }
}
