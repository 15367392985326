import * as _ from "lodash";
import { ModalController, NavParams } from '@ionic/angular';
import { CartItem } from 'src/app/cart-items/cart-items';
import { EmptyComponent } from './empty';
import { Component, ViewChild } from '@angular/core';
import { Option } from 'src/app/models';
import { LanguagePipe } from 'src/app/pipes/language.pipe';
import { LanguageProvider } from 'src/app/services/language/language.provider';

export class BuildStep {
    constructor(
        public imageUrl: string,
        public headerText: string,
        public subText: string,
        public optionsGroupName: string,
        public showNameInput: boolean = false) { }
}

export class BurgerFlow {
    steps: BuildStep[] = [
        new BuildStep("img/bsns_app_storlek_S_M_B.png", "Välj typ & storlek", "", "type"),
        new BuildStep("img/bsns_app_dressing.png", "Dressing", "Välj så många du vill", "sauce"),
        new BuildStep("img/bsns_app_topping.png", "Toppings", "Välj så många du vill", "toppings"),
        new BuildStep("img/bsns_app_topping.png", "Välj side order", "", "sideorder"),
        new BuildStep("img/bsns_app_milkshake.png", "Välj tillbehör", "", "extras"),
        new BuildStep("img/bsns_app_meny.png", "Vad ska din burgare heta?", "", "", true)];
        // new BuildStep("img/bsns_app_storlek_S_M_B.png", this.languagePipe.transform("Välj typ & storlek",'BAB_TYPE_SIZE',this.languageProvider.currentLanguage), "", "type"),
        // new BuildStep("img/bsns_app_dressing.png", this.languagePipe.transform("Dressing",'BAB_NBAB_DRESSINGAME',this.languageProvider.currentLanguage), this.languagePipe.transform("Välj så många du vill",'BAB_MANY',this.languageProvider.currentLanguage), "sauce"),
        // new BuildStep("img/bsns_app_topping.png", this.languagePipe.transform("Toppings",'BAB_TOPPINGS',this.languageProvider.currentLanguage), this.languagePipe.transform("Välj så många du vill",'BAB_MANY',this.languageProvider.currentLanguage), "toppings"),
        // new BuildStep("img/bsns_app_topping.png", this.languagePipe.transform("Välj side order",'BAB_SIDEORDER',this.languageProvider.currentLanguage), "", "sideorder"),
        // new BuildStep("img/bsns_app_milkshake.png", this.languagePipe.transform("Välj tillbehör",'BAB_EXTRAS',this.languageProvider.currentLanguage), "", "extras"),
        // new BuildStep("img/bsns_app_meny.png", this.languagePipe.transform("Vad ska din burgare heta?",'BAB_BURGER_NAME',this.languageProvider.currentLanguage), "", "", true)];

    constructor(private modalCtrl: ModalController, 
        steps?: BuildStep[]) {
        if (steps) {
            this.steps = steps;
            //this.steps.push(new BuildStep("", this.languagePipe.transform("Vad ska din rätt heta?",'BAB_NAME',this.languageProvider.currentLanguage), "", "", true))
            this.steps.push(new BuildStep("", "Vad ska din rätt heta?", "", "", true))
        }
    }

    async start(item: CartItem, successCallback: (CartItem) => void, abortCallback: () => void): Promise<void> {
        let copiedItem = _.cloneDeep(item);
        this.setInitialNameAndSelectedOptions(copiedItem);


        let onSuccess = item => { successCallback(item); };
        let onAbort = () => { abortCallback(); };

        this.doStep(copiedItem, 0, onSuccess, onAbort);

    }

    private setInitialNameAndSelectedOptions(item: CartItem) {
        item.Name = "";
        item.options.forEach(option => {
            if (option.type === "multiple" && option.choices.length > 0) {
                option.selected = option.choices[0];
            }
        });
    }

    async doStep(item: CartItem, stepIndex: number, successCallback: (CartItem) => void, abortCallback: () => void): Promise<any> {
        let step = this.steps[stepIndex];

        let parameters = {
            item: item,
            imageUrl: step.imageUrl,
            headerText: step.headerText,
            subText: step.subText,
            optionsGroupName: step.optionsGroupName,
            showNameInput: step.showNameInput
        };

        let modal = await this.modalCtrl.create({ component: BurgerStepComponent, componentProps: parameters, cssClass: ["modal-drawer", "build-a-burger"], showBackdrop: true, backdropDismiss: false });
        modal.present();

        let result = await modal.onDidDismiss();
        let data = result.data;
        if (data.close) {
            abortCallback();
        }
        stepIndex = this.getNextStep(stepIndex, data);
        if (stepIndex >= this.steps.length) {
            data.item.NameInternal = "BaB: " + data.item.Name;
            successCallback(data.item);
        }
        else if (stepIndex < 0) {
            abortCallback();
        }
        else {
            this.doStep(data.item, stepIndex, successCallback, abortCallback);
        }
    }

    getNextStep(index: number, data): number {
        var change = 0;
        if (data.back) {
            change = -1;
        }
        if (data.forward) {
            change = 1;
        }

        let newIndex = index + change;

        let step = this.steps[newIndex];
        if (step != undefined && step.optionsGroupName === "sideorder" && !(data.item.options.some(x => x.text.toLowerCase().indexOf("meny") > -1 && x.selected))) {
            return newIndex + change;
        }
        return newIndex;
    }
}

@Component({
    templateUrl: "burgerOrder.html",
})
export class BurgerStepComponent {
    @ViewChild("itemNameInput") itemNameInput;
    buildStepImage: string;
    buildStepText: string;
    buildStepSubText: string;
    showComments: boolean;
    visibleOptions: Option[];
    stepName: string;
    item: CartItem;
    background = "center url('./img/foodBackground.jpg') no-repeat"
    showContent = true;
    constructor(public params: NavParams, 
        public modalCtrl: ModalController,
        private languagePipe:LanguagePipe,
        public languageProvider:LanguageProvider
        ) {
        this.item = params.get("item");
        this.buildStepImage = params.get("imageUrl");
        this.buildStepText = params.get("headerText");
        this.buildStepSubText = params.get("subText");
        this.showComments = params.get("showNameInput");

        this.visibleOptions = [];
        this.stepName = params.get("optionsGroupName");
        if (this.stepName !== "") {
            this.visibleOptions = this.getOptionsFor(this.stepName);

        }
    }
    optionChanged(ev, option) {
        option.selected = ev.detail.value;
    }
    ngAfterViewInit() {
        if (this.itemNameInput)
            this.itemNameInput.setFocus();
    }
    next(): void {
        this.dismiss({ item: this.item, forward: true });
    }

    back() {
        this.dismiss({ item: this.item, back: true });
    }

    closeGuide() {
        this.dismiss({ item: this.item, close: true });
    }

    dismiss(result) {
        this.modalCtrl.dismiss(result, null);
    }

    getOptionsFor(step: string): Option[] {
        return this.item.options.filter(x => x.step === step);
    }

    totalCost() {
        return this.item.options
            .reduce((total, option) => {
                if (option.type == "boolean") {
                    return total + (option.selected ? option.cost : 0);
                }
                return total + option.selected.cost;
            }, 0);
    }
}

export class BurgerFunctions {
    static sortOptions(item: CartItem, sortOrder: string[]): CartItem {
        if (!item.options) return item;
        var itemToSort = Object.assign({}, item);
        itemToSort.options = [];
        for (const typeString of sortOrder) {
            var s = item.options.filter((itemOption, index) => {
                return itemOption.step === typeString;
            });

            if (s)
                itemToSort.options = itemToSort.options.concat(s);
        }
        var indexToSplice = null;
        for (let index = 0; index < itemToSort.options.length; index++) {
            const element = itemToSort.options[index];
            if (element.text.toLowerCase() === "meny")
                indexToSplice = index;
        }
        var menyItem = itemToSort.options.splice(indexToSplice, 1);
        if (menyItem && menyItem[0])
            itemToSort.options.unshift(menyItem[0]);





        return itemToSort;
    }
}