import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as firebase from 'firebase';
import { AppConfiguration } from 'src/app/app.configuration';
import { PubqConfig, PubqConfigProvider } from '../pubq-config/pubq-config';
import { ContextProvider } from '../context/context';

@Injectable({
    providedIn: "root"
})
export class EventsProvider {
    origin = AppConfiguration.userOrigin;
  config: PubqConfig;
  context = {};

  constructor(
    private http: HttpClient,
    private pubqConfigProvider: PubqConfigProvider,
    private contextProvider: ContextProvider
  ) {
    this.pubqConfigProvider.config.subscribe((x: PubqConfig) => {
      if (!x) {
        return;
      }
      this.config = x;
    });
    this.setContextValue('origin', this.origin);
  }

  logEvent(event: string, payload?) {

    this.setContextValue('timestamp', +new Date());
    this.setContextValue('userId', firebase.auth().currentUser ? firebase.auth().currentUser.uid : 'N/A');
    this.setContextValue('routeName', _.get(this.contextProvider, ['client', 'routeName'], null));

    const body = {
        eventKey: event,
        payload,
        context: _.clone(this.context)
    };
    // Added timeout if event is called on startup
    // this.config.ApiUrl is otherwise null
    if(this.config){
      this.http.post<any>(this.config.ApiUrl + '/api/v1/curator', body)
      // this.http.post<any>('http://localhost:5001/pq2-staging/us-central1/api/v1/curator', body)
        .toPromise()
        .then(res => {
          console.log('event adding res:', res);
        }, err => {
          console.log('event adding error', err);
        });
    }else{
      setTimeout(()=>{
        if(this.config){
        this.http.post<any>(this.config.ApiUrl + '/api/v1/curator', body)
        // this.http.post<any>('http://localhost:5001/pq2-staging/us-central1/api/v1/curator', body)
          .toPromise()
          .then(res => {
            console.log('event adding res:', res);
          }, err => {
            console.log('event adding error', err);
          });
        }else console.log("Could not log event: " + event);
      },1000);
      
    }
  }

  setContextValue(key, val) {
    this.context[key] = val;
  }
}