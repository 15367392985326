import { NgStyle } from "@angular/common";
import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { NgSelectOption } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { User, UserProvider } from "../user/user";

@Injectable({
  providedIn: "root",
})
export class ThemeProvider {

    theme: BehaviorSubject<ThemeParams> = new BehaviorSubject<ThemeParams>(null);
    styles: ThemeStyles = this.generateStylesFromParams(null);
    activatedRouteSubscription: Subscription;
    themeSubscription: Subscription;
    userSubscription: Subscription;
    
    constructor(
        private db: AngularFireDatabase,
        private sanitizer: DomSanitizer
    ) {

        // this.userSubscription = this.userProvider.currentUser.subscribe(
        // (userInfo: User) => {
        //     if(!userInfo){return}

        //     console.log("Theme got new user")
        //     console.log(this.activatedRoute.snapshot.params.routeName);

        //     if(this.activatedRouteSubscription) this.activatedRouteSubscription.unsubscribe();
        //     this.activatedRouteSubscription = 
        //     this.activatedRoute.params.subscribe((x) => {
        //         if(!x){return}
                
        //         console.log("New route");
        //         console.dir(x);
        //         if(x.routeName){
        //             console.log("load data on route change");
        //             this.loadThemeData(x.routeName);
        //         }
        //     });
            
        //     // console.log("Loading theme when user is loaded");
        //     // console.dir(userInfo)
        //     // this.loadThemeData(this.activatedRoute.snapshot.params.routeName);
        // });

        //this.loadThemeData('partner_typing');

        //console.log("theme constructor"); 
    }

    init(routeName:string ){
        console.log("Theme init from routeName")
        this.loadThemeData(routeName);
    }

    loadThemeData(routeName: string){
        if(this.themeSubscription) 
            this.themeSubscription.unsubscribe();
        
        this.themeSubscription = this.db.object<ThemeParams>(`Clients/${routeName}/theme/webOrder`)
            .valueChanges()
            .subscribe((x: ThemeParams)=>{
                console.log("Loading theme for route " + routeName);
                if(!x) {return}
                console.log("Loading theme for route " + routeName);
                this.theme.next(x);
                this.styles = this.generateStylesFromParams(x);
            }); 
        
        // this.db.database.ref(`Clients/${routeName}/theme/webOrder`).once('value').then((x)=>{
        //     const data = x.val();

        //     console.log("Loading theme for route " + routeName);
        //     console.dir(data);
        //     this.theme.next(data);
        //     this.styles = this.generateStylesFromParams(data);
        // });
        
    }

    ngDestroy(): void {
        this.themeSubscription.unsubscribe();
    }

    private generateStylesFromParams(theme: ThemeParams) : ThemeStyles{
        const styles = new ThemeStyles();

        styles.mainBackground = { 'background-color': theme && theme.mainBackgroundColor ? theme.mainBackgroundColor : '#F5F5F5' };
        let color = theme && theme.mainButton && theme.mainButton.BackgroundColor ? theme.mainButton.BackgroundColor : '#4c8dff';
        styles.mainButton = {
            //'--background': theme && theme.mainButton && theme.mainButton.BackgroundColor ? theme.mainButton.BackgroundColor : '#4c8dff',
            'color': theme && theme.mainButton && theme.mainButton.BackgroundColor ? theme.mainButton.Color : '#FFFFFF'
          };

        return styles;
    }
}

export class ThemeParams{
    mainBackgroundColor: string;
    mainButton:{
            BackgroundColor: string;
            Color: string;}
    mainImage: string;
    mainLogo: string;
    secondaryBackgroundColor: string;
}

export class ThemeStyles{
    mainBackground: {};
    mainButton: {};
    mainImage: {};
    mainLogo: {};
    secondaryBackgroundColor: {};
}