import { Component, ViewChild } from '@angular/core';
import { AppConfiguration } from './app.configuration';
import {
  ToastController,
  LoadingController,
  Platform,
  NavController,
  IonMenu,
} from '@ionic/angular';
// import { Deeplinks } from "@ionic-native/deeplinks/ngx";
// import { StatusBar } from "@ionic-native/status-bar/ngx";
import { User, UserProvider } from './services/user/user';
import { PaymentProvider } from './services/payment/payment';
import { ContextProvider, ClientInfo } from './services/context/context';
import { UnitProvider } from './services/unitProvider';
import { ChargeCustomerComponent } from './payment/chargeCustomer.component';
import { Storage } from '@ionic/storage';
import { CartPage } from './cart/cart';
import {ActivatedRoute} from '@angular/router';
import {CartProvider} from "./services/cart/cart";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild('sideMenu') sideMenu: IonMenu;
  sideMenuBackground = 'center url(\'./img/sideMenuBg.jpg\') no-repeat';
  rootPage: any;
  hasCitySelection = AppConfiguration.hasCitySelection;
  user: User;
  client;
  deliveryType;
  constructor(
    private storage: Storage,
    private nav: NavController,
    private toastCtrl: ToastController,
    private paymentProvider: PaymentProvider,
    private loaderCtrl: LoadingController,
    // private deeplinks: Deeplinks,
    private platform: Platform,
    // private statusBar: StatusBar,
    private contextProvider: ContextProvider,
    private userProvider: UserProvider,
    private unitProvider: UnitProvider,
    private route: ActivatedRoute,
    public cartProvider: CartProvider,
  ) {
    const urlParams = new URLSearchParams(window.location.search);
    this.client = urlParams.get('client');
    this.deliveryType = urlParams.get('deliveryType');   
  }

  // async chargePossibleSwishCustomerCached() {
  //   const loader = await this.loaderCtrl.create({
  //     message: 'Verifierar Swish-betalning',
  //   });
  //   try {
  //     let body = await this.storage.get('swish');
  //     if (!body) {
  //       return;
  //     }
  //     loader.present();
  //     body = JSON.parse(body);

  //     await this.paymentProvider.chargeSwishCustomerCachedOrder(body);
  //     loader.dismiss();
  //   } catch (err) {
  //     loader.dismiss();
  //     alert(JSON.stringify(err));
  //   }
  // }

  ngOnInit() {
    this.userProvider.currentUser.subscribe((x) => {
      this.user = x;
    });
  }
  // ngAfterViewInit() {
  //   this.setupDeeplink();
  // }
  // async setupDeeplink() {
  //   await this.platform.ready();
  //   this.deeplinks
  //     .route({
  //       "/swish/:routeName/:swishId": ChargeCustomerComponent,
  //       "/:routeName/t/:tableNumber": CartPage
  //     })
  //     .subscribe(
  //       async (match) => {
  //         let routeName = match.$args.routeName;
  //         let client = new ClientInfo();
  //         if (this.hasUnitSelection) {
  //           let units = await this.unitProvider.getUnits("all").toPromise();
  //           let unit = units.find((x) => x.routeName === routeName);
  //           client.name = unit.name;
  //           client.routeName = routeName;
  //           client.address = unit.address;
  //         } else {
  //           client.name = AppConfiguration.clientName;
  //           client.routeName = routeName;
  //           client.address = "";
  //         }
  //         this.contextProvider.init(client);
  //         await this.chargePossibleSwishCustomerCached();
  //       },
  //       (nomatch) => {
  //         // nomatch.$link - the full link data
  //       }
  //     );
  // }

  async presentToast(text) {
    const toast = await this.toastCtrl.create({
      message: text,
      duration: 3000,
    });
    toast.present();
  }

  userLoggedIn() {
    return this.user !== null;
  }

  
 
}
