import { Component, ViewChild } from "@angular/core";
import { IonNav, ModalController } from '@ionic/angular';
import { MenuPage } from 'src/app/menu/menu';
@Component({
    styleUrls: [],
    selector: "takeaway-nav",
    templateUrl: "takeawayNav.component.html"
})
export class TakeawayNavComponent {
    @ViewChild("takeawayNav") nav: IonNav;
    constructor(modalCtrl:ModalController) {

    }
    ngAfterViewInit() {
        this.nav.push(MenuPage, { nav: this.nav });
    }
}