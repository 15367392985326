import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DeliveryPlacePage } from './delivery-place/delivery-place';
import { PaymentPage } from './payment/payment';
import { ChargeCustomerComponent } from './payment/chargeCustomer.component';
import { CustomerTabKeyPage } from './cart/customer-tab-key/customer-tab-key';
import { PurchaseHistoryListViewPage } from './account/purchaseHistory/purchaseHistoryListView';
import { PurchaseHistoryDetailsViewComponent } from './account/purchaseHistory/purchaseHistoryListDetails';
import { UnitSelectionComponent } from './unit-selection/unitSelection.component';
import { DeliverToLocationComponent } from './delivery-place/deliver-to-location/deliverToLocation.component';
import { PaymentConfirmationComponent } from './payment/paymentConfirmation/paymentConfirmation.component';
import { ActiveOrdersComponent } from './active-orders/active-orders';
import {CartPage} from "./cart/cart";
import {MenuPage} from "./menu/menu";
import {AccountPage} from "./account/account";
import { NoRoutePage } from './no-route/no-route.page';
import { CustomerTabsListPage } from './customer-tabs/customer-tabs-list';

const routes: Routes = [
  
  {
    path: 'main/:client/:city/:routeName',
    // loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    component: MenuPage,
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./account/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'unitselection/:client/:city',
    component: UnitSelectionComponent,
    pathMatch: 'full'
  },
  {
    path: 'deliveryPlace',
    component: DeliveryPlacePage
  },
  {
    path: 'deliverToLocation',
    component: DeliverToLocationComponent
  },
  // {
  //   path: 'payment',
  //   component: PaymentPage
  // },
  {
    path: 'chargeCustomer/:client/:city/:routeName',
    component: ChargeCustomerComponent,
    pathMatch: 'full'
  },
  {
    path: 'paymentConfirmation/:client/:city/:routeName',
    component: PaymentConfirmationComponent
  },
  {
    path: 'tabKey',
    component: CustomerTabKeyPage
  },
  {
    path: 'history',
    component: PurchaseHistoryListViewPage
  },
  {
    path: 'history/:id',
    component: PurchaseHistoryDetailsViewComponent
  },
  {
    path: 'active-orders/:client/:city/:routeName',
    component: ActiveOrdersComponent,
    pathMatch: 'full'
  },
  // Not used for Melodyclub
  // {
  //   path: 'cart/:client/:city/:routeName',
  //   component: CartPage,
  //   pathMatch: 'prefix'
  // },
  {
    path: "customerTabsList/:client/:city/:routeName",
    component: CustomerTabsListPage,
    pathMatch: 'full'
  },
  // {
  //   path: 'account/:client/:city/:routeName',
  //   component: AccountPage,
  //   pathMatch: 'full'
  // },
  {
    path: '**',
    component: NoRoutePage
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
