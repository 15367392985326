import { Component, Input, ElementRef, Renderer2,   } from '@angular/core';
import { DomController } from '@ionic/angular';

@Component({
  selector: 'shrinking-segment-header',
  templateUrl: 'shrinking-segment-header.html',
  styleUrls: ['shrinking-segment-header.scss'],
})
export class ShrinkingSegmentHeader {

  @Input('scrollArea') scrollArea: any;
  @Input('headerHeight') headerHeight: number;

  newHeaderHeight: any;

  constructor(public element: ElementRef, public renderer: Renderer2, private domCtrl: DomController) {

  }

  ngAfterViewInit(){

    this.renderer.setStyle(this.element.nativeElement, 'height', this.headerHeight + 'px');

    this.scrollArea.ionScroll.subscribe((ev) => {
      this.resizeHeader(ev);
    });

  }

  resizeHeader(ev){

    this.domCtrl.write(() => {

      this.newHeaderHeight = this.headerHeight - Math.round((ev.detail.scrollTop/2));

      if(this.newHeaderHeight < 0){
        this.newHeaderHeight = 0;
      }   

      this.renderer.setStyle(this.element.nativeElement, 'height', this.newHeaderHeight + 'px');
    });

  }

}