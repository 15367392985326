import { Component, ViewChild, ElementRef, NgZone } from '@angular/core';
import {
  ModalController,
  Platform,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import { ContextProvider } from '../services/context/context';
import * as moment from 'moment';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { LanguageProvider } from '../services/language/language.provider';
import { LanguagePipe } from '../pipes/language.pipe';
import { Subscription } from 'rxjs';

declare var google;
@Component({
  selector: 'page-client-info',
  styleUrls: ['clientInfo.component.scss'],
  templateUrl: 'clientInfo.component.html',
})
export class ClientInfoComponent {
  @ViewChild('search') public searchElementRef: ElementRef;
  @ViewChild(AgmMap) public agmMap: AgmMap;
  openingHours = null;
  openingDeviations = null;
  aboutUs = null;
  restaurantPhoneNumber = null;
  restaurantEmail = null;
  loading;
  zoom = 15;
  latitude = 1;
  longitude = 0.5;
  address: string;
  maploaded = false;
  userPosition = null;
  private geoCoder;
  positionSubscription = null;
  restaurantSubscription: Subscription;
  mainBackgroundColor = {};
  logo = {};
  mainRestaurantImage = {};
  mainButtonColor = {};
  secondaryBackgroundColor = {};
  activeSegmentColor = {};
  mainTextColor = {};
  constructor(
    private modalController: ModalController,
    private contextProvider: ContextProvider,
    private platform: Platform,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    // private geolocation: Geolocation,
    private mapsApiLoader: MapsAPILoader,
    private ngZone: NgZone,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe
  ) {}
  async ngOnInit() {
    // load Places Autocomplete
    // setTimeout( () => {
      this.restaurantSubscription = this.contextProvider.clientContext.appInfo.subscribe((data) => {
        if (data && data.Context) {
          this.aboutUs = data.Context.aboutUsDescription;
          this.restaurantEmail = data.Context.restaurantEmail;
          this.restaurantPhoneNumber = data.Context.restaurantPhoneNumber;
          console.dir(data.Context);
          this.formatOpeningHours(
            data.Context.openingHours
          );
          this.loadMap();
          this.zoom = 15;
        }
      });

      this.secondaryBackgroundColor = {
        'background-color': '#F5F5F5',
      };
      this.mainTextColor = {
        color: '#000',
        'font-weight': 'bold'
      };
    // }, 1000);
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async ngAfterViewInit() {
    await this.mapsApiLoader.load();
    this.geoCoder = new google.maps.Geocoder();
  }

  async loadMap() {
    this.latitude = typeof(this.contextProvider.client.location.latitude) === 'string'
      ? parseFloat(this.contextProvider.client.location.latitude) : this.contextProvider.client.location.latitude;
    this.longitude = typeof(this.contextProvider.client.location.longitude) === 'string'
      ? parseFloat(this.contextProvider.client.location.longitude) : this.contextProvider.client.location.longitude;
    this.positionSubscription = navigator.geolocation.watchPosition((x) => {
      if (!x) { return; }
      this.userPosition = {
        lat: x.coords.latitude,
        long: x.coords.longitude
      };
    });
    setTimeout(() => {
      this.maploaded = true;
    }, 20);
  }
  async showToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      position: 'top',
    });

    toast.present();
  }

  ngOnDestroy() {
    if (this.positionSubscription) {
      this.positionSubscription = null;
    }
  }

  formatOpeningHours(openingHours: any) {
    if (!openingHours) { 
      this.openingHours = null;
      return; 
    }
    if (openingHours.weekdays) {
      this.openingHours = [];
      openingHours.weekdays.forEach((x) => {
        const startHour = parseInt(x.start.split(':')[0]);
        const startMinute = parseInt(x.start.split(':')[1]);
        const endHour = parseInt(x.end.split(':')[0]);
        const endtMinute = parseInt(x.end.split(':')[1]);

        const weekday = {
          hours: x.isChecked
            ? `${moment
              .utc()
              .set('hours', startHour)
              .set('minute', startMinute)
              .local()
              .format('kk:mm')} - ${moment
                .utc()
                .set('hours', endHour)
                .set('minute', endtMinute)
                .local()
                .format('kk:mm')}`
            : this.languagePipe.transform('Stängt', 'CLOSED', this.languageProvider.currentLanguage),
          label: x.label,
        };
        this.openingHours.push(weekday);
      });
    }

    if (openingHours.deviations) {
      this.openingDeviations = [];
      openingHours.deviations.forEach((x) => {
        const startHour = parseInt(x.start.split(':')[0]);
        const startMinute = parseInt(x.start.split(':')[1]);
        const endHour = parseInt(x.end.split(':')[0]);
        const endtMinute = parseInt(x.end.split(':')[1]);

        const deviation = {
          hours: `${moment
            .utc()
            .set('hour', startHour)
            .set('minute', startMinute)
            .local()
            .format('kk:mm')} - ${moment
              .utc()
              .set('hour', endHour)
              .set('minute', endtMinute)
              .local()
              .format('kk:mm')}`,
          label: x.label,
        };
        this.openingDeviations.push(deviation);
      });
    }
  }
}
