import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
@Injectable({
    providedIn: "root",
})
export class LogMemoryService {
    public routeName: string;
    screenName = "";
    constructor(
        private firestore: AngularFirestore,
        private activatedRoute: ActivatedRoute
    ) {
        
    }

    async logEvent(type: "customer_tab" | "transaction_failed" | "create_receipt" | "correct_receipt" | "refund_receipt" | "move_booking" | "copy_booking" | "update_booking" | "update_tab" | "add_item" | "remove_item" | "update_item" | "to_kitchen", data: any) {
        let currentPosUser = {
            name: this.screenName
        }
        currentPosUser = currentPosUser ? { name: currentPosUser.name } : null;
        try {
            let date = moment();            
            let logRoute = `pos/${this.routeName}/log_memory`;
            this.firestore.collection(logRoute).add({ type: type, posUser: currentPosUser, timestamp: date.unix(), dateISO: date.toISOString(), ...data });

        } catch (error) {
            console.error(error.message);
        }
    }
}