export const lang = {
    get: (string, parameters?) => {
        switch (string) {
            case 'BONUS_USE': return `Utnyttja ${parameters[0]}​​​ bonuspoäng`;
            case 'STOCK_ALERT': return `Tyvärr saknades ${parameters[0]}​​​ i lager, din varukorg har justerats efter vad som fanns tillgängligt så du kan fortsätta handla.`;
            case 'BOOKING_NO_AVAILABLE_TIMES_1': return `Tyvärr finns det inga lediga tider för ${parameters[0]} personer`;
            case 'BOOKING_SMS_TEXT': return `Hej det gäller bokningen ${parameters[0]} \n kl. ${parameters[1]}\n ${parameters[2]} pers. `;
            case 'OFFERS_ACTIVATED': return `Aktiverade ${parameters[0]}`;
            case 'CART_ITEMS_REMOVE': return `Vill du ta bort  ${parameters[0]}?`;
            case 'CART_ALLERGY_CONTACT': return `, mellan kl ${parameters[0]}-${parameters[1]}, så svarar vi gärna på dina frågor om innehåll`;
            case 'EMAIL_CORRECT': return `Är ${parameters[0]} korrekt?`;
            case 'LOGIN_SEND_SMS': return `Skicka sms till ${parameters[0]}?`;
            case 'LOGIN_ERROR': return `Oj något gick fel. Vänligen testa ett annat sätt att logga in.\n ${parameters[0]}`;
        }
    },

    PUSH_TITLE: 'Notifikationer',
    PUSH_ALERT_TXT: 'För att se status på din order behöver du tillåta pushnotifkationer',
    PUSH_ALERT_BTN: 'Tryck här',
    PUSH_INFO: 'Välj de restauranger du vill få erbjudanden från. Vi lovar att inte missbruka ditt förtroende',
    PUSH_PERMISSION: 'Du måste öppna telefonens inställningar och tillåta pushnotiser för appen innan du kan göra ändringar här',
    PUSH_CHANGE_SELECTION: 'Du kan alltid ändra ditt val under Konto => Pushnotifikationer',

    CURRENT: 'Aktuellt',
    NEW_ORDER: 'Er beställning',
    ORDERING_NOT_POSSIBLE: 'Går inte att beställa nu',
    SHOW_CURRENT_ORDER: 'Visa din beställning',

    WELCOME: 'Välkommen!',
    DONE: 'Klar',
    BACK: 'Tillbaka',
    OPT_OUT: 'Nej tack',
    CONFIRM: 'Ok',
    OK: 'OK',
    CANCEL: 'Avbryt',
    DATE_TIME: 'Datum & tid',
    SENT: 'Skickat',
    BOOK: 'Boka',
    SEARCH: 'Sök...',
    ERROR: 'Fel',
    YES: 'Ja',
    NO: 'Nej',

    CARD_NO: 'Kortnummer',
    ADD: 'Lägg till',

    NAME: 'Namn',
    PHONE: 'Telefon',

    WRITE_TABLENUMBER: 'Skriv in ditt bordsnummer',
    TABLENUMBER: 'Bordsnummer',

    TIPS: 'Dricks',
    PAY: 'Betala',
    PAYED: 'BETALD',
    SUM: 'Summa',

    CLOSED: 'Stängt',

    ACTIVATE: 'Aktivera',

    DISCOUNT: 'Rabatt',

    ORDER: 'Beställ',

    FORWARD: 'Vidare',
    QUANTITY: 'Antal',
    /* 3 letter */
    SUN: 'Sön',
    MON: 'Mån',
    TUE: 'Tis',
    WED: 'Ons',
    THU: 'Tor',
    FRI: 'Fre',
    SAT: 'Lör',
    TODAY: 'Idag',

    /*Table booking*/
    BOOKING_THANKS_1: 'Tack för ditt besök!',
    BOOKING_THANKS_2: 'Vi ses snart igen',
    MY_BOOKING: 'Min bokning',
    BOOKING_QUESTIONS: 'Har du frågor om bokningen?',
    BOOKING_CONTACT_US_1: 'Kontakta oss på',
    BOOKING_CONTACT_US_2: 'eller tryck på knappen för att skicka ett SMS',
    BOOKING_CONFIRM: 'Bekräftelse',
    BOOKING_APPCODE: 'Din appkod är',
    BOOKING_ICONS: 'Beställ med hjälp av ikonerna nedan',
    BOOKING_TITE: 'Boka bord',
    BOOKING_NUMBER_GUESTS: 'Antal gäster',
    BOOKING_LARGER_GROUPS: 'Bordsbokning större grupper',
    BOOKING_LARGER_GROUPS_CONTACT: 'Om du vill boka bord för fler personer vänligen hör av dig på',
    BOOKING_NO_AVAILABLE_TIMES_1: 'Inga lediga tider',
    BOOKING_ALLOW_SMS: 'Tillåtelsen låter appen öppna ett förifyllt sms till restaurangen åt dig',
    BOOKING_CANCEL: 'Är du säker på att du vill avboka?',
    BOOKING_CONFIRM_CANCEL: 'Bekräfta avbokning',
    BOOKING_CANCELED: 'Ditt bord har avbokats',

    STOCK_TITLE: 'Slut i lager!',
    BONUS_POINTS: 'bonuspoäng',

    PAYMENT_TITLE: 'Betala för',
    PAYMENT_DELIVERY: 'Utkörningsavgift',
    PAYMENT_DISCOUNT: 'Rabatt',
    PAYMENT_BONUS: 'Utnyttja bonuspoäng',
    PAYMENT_FIRST_DISCOUNT: 'på vägen mot din första rabatt!',
    PAYMENT_EXTRA: 'Extra',
    PAYMENT_TOTAL: 'Totalt',
    PAYMENT_PAYMENTS: 'Betalsätt',
    PAYMENT_TABLETAB: 'Lägg till på bordsnota',
    PAYMENT_SWISH: 'Betala med Swish',
    PAYMENT_VIPPS: 'Betala med Vipps',
    PAYMENT_APPLEPAY: 'Betala med Äpple Pay',
    PAYMENT_SAVED_CARDS: 'Sparade kort',
    PAYMENT_ADD_CARD: 'Lägg till kort',
    PAYMENT_SCROLL: 'Scrolla ned för att betala',
    PAYMENT_PAY: 'Betala',


    PAYMENT_OFFER_ERROR_HEADER: 'Dessa erbjudanden kunde inte aktiveras',
    PAYMENT_OFFER_ERROR_TEXT: 'De gäller inte för det serveringssätt du valt',
    PAYMENT_ERROR_MAINTENANCE: 'Just nu pågår en uppdatering och ditt köp kan inte genomföras',
    PAYMENT_ERROR: 'Se så ditt kort är öppet för internetköp och att tillräcklig summa finns tillgänglig',


    CHARGE_WAIT: 'Ett ögonblick ... ',
    CHARGE_TAB_TOAST: 'Din nota är betald och ett kvitto har skapats under ditt konto',
    CHARGE_SWISH_INIT: 'Frågar Swish om betalning',
    CHARGE_VIPPS_INIT: 'Frågar vipps om betalning',
    CHARGE_ERROR: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    CHARGE_VERIFY: 'Verifierar och stämmer av',

    ADD_CARD_ADDING: 'Lägger till kort',
    ADD_CARD_NEW_CARD: 'Nytt kort',

    ADD_CARD_ERROR_E1: 'Något gick fel. Prova igen!',
    ADD_CARD_ERROR_E3: 'Restaurangen kan inte lägga till kort. Kontakta personal!',
    ADD_CARD_ERROR_E4: 'Invalid reference details',
    ADD_CARD_ERROR_E5: 'Fel korttyp!',
    ADD_CARD_ERROR_E6: 'Fel terminal-id! Kontakta personalen',
    ADD_CARD_ERROR_E7: 'Ej tillåtet! Kontakta personalen',
    ADD_CARD_ERROR_E8: 'Fel merchant-id! Kontakta personalen',
    ADD_CARD_ERROR_E9: 'Fel datumstämpel! Kontakta personalen',
    ADD_CARD_ERROR_E10: 'Fel kortnummer. Dubbelkolla att du har slagit in rätt',
    ADD_CARD_ERROR_E11: 'Fel månad/år. Dubbelkolla att du har slagit in rätt',
    ADD_CARD_ERROR_E12: 'Fel namn på kort. Kontakta personalen',
    ADD_CARD_ERROR_E13: 'Restaurangen har fel inställningar. Kontakta personalen',
    ADD_CARD_ERROR_E14: 'Fel CVV-kod. Dubellkolla att du slagit in rätt',
    ADD_CARD_NICK_TITLE: 'Vad vill du kalla ditt kort?',
    ADD_CARD_NICK_MSG: 'Kortet krypteras på ett säkert sätt och sparas för framtida användning',
    ADD_CARD_NICK_PLACEHOLDER: 'Kortets smeknamn',
    ADD_CARD_SECURITY_INFO: 'Vi behandlar din kortinformation på ett tryggt och säkert sätt. All känslig information sparas hos en certifierad betalväxel',

    OFFERS: 'Erbjudanden',
    OFFERS_NONE: 'Det finns inga erbjudanden att visa',


    MENU_DRINKS_CLOSED: 'Det tas inte emot några dryckesbeställningar för tillfället',
    MENU_ORDER_FULL: 'Du kan inte beställa mer på samma order',
    MENU_NOT_AVAILABLE: 'inte tillgänglig för tillfället',
    MENU_NO_PRODUCTS: 'Det finns inga produkter att visa',

    ITEM_DESCRIPTION_OUT_OF_STOCK: 'Slut',
    ITEM_DESCRIPTION_SCROLL: 'Scrolla ned',
    COMMENT: 'Kommentar',

    BAB_NAME: 'Vad ska din rätt heta?',
    BAB_ADD: 'LÄGG TILL',

    BAB_TYPE_SIZE: 'Välj typ & storlek',
    BAB_DRESSING: 'Dressing',
    BAB_TOPPINGS: 'Toppings',
    BAB_SIDEORDER: 'Välj side order',
    BAB_EXTRAS: 'Välj tillbehör',
    BAB_BURGER_NAME: 'Vad ska din burgare heta?',
    BAB_MANY: 'Välj så många du vill',

    CHOOSE_RESTAURANT: 'Välj restaurang',

    DELIVERY_MY_TABLE: 'Servera till mitt bord',
    DELIVERY_TO_GO: 'Ta med',
    DELIVERY_FETCH_BAR: 'Hämta i baren',
    DELIVERY_HOME_DELIVERY: 'Utkörning',
    DELIVERY_OFFER_ERROR: 'Rabattkoden är inte giltig för detta serveringssätt',
    DELIVERY_ALCOHOL_TAKE_AWAY: 'Du kan inte beställa alkohol som take away',
    DELIVERY_ALCOHOL_DELIVERY: 'Du kan inte beställa hem alkohol...',
    DELIVERY_SERVING: 'Servering',
    DELIVERY_QUESTION: 'Hur vill du få din beställning?',

    LOCATION_CHOSE: 'Välj plats',
    LOCATION_ADRESS: 'Din adress',
    LOCATION_QUESTION: 'Leverera hit?',
    LOCATION_FEE: 'Avgift',
    LOCATION_COMMENT: 'Kommentar (våning, portkod etc.)',
    LOCATION_UNFORTUNATELY_OTHER_ADRESS: 'Tyvärr levererar vi inte hit ännu. Prova en annan adress',
    LOCATION_OTHER_ADRESS: 'Prova en annan adress',

    CUSTOMER_TABS_ATTENTION_MSG: '',
    CUSTOMER_TABS_PAY_IN_APP: 'Betala i appen',
    CUSTOMER_TABS_CALL_WAITER: 'Tillkalla kypare',
    CUSTOMER_TABS_TIPS: 'Vill du ge något extra till vår personal?',
    CUSTOMER_TABS_CUSTOM_AM1: 'Valfri summa...',
    CUSTOMER_TABS_CUSTOM_AM2: 'Valfri summa',
    CUSTOMER_TAB: 'Bordsnota',

    CUSTOM_TICKET_VALID: 'Giltigt till:',

    CLIENT_OPEN_HOURS: 'Öppettider',

    CART_ITEMS_APPLYING_OFFER: 'Lägger på rabattkod',
    CART_ITEM_OFFER_NOT_VALID: 'Rabattkoden är inte giltig',
    CART_ITEM_COMMENT_EXAMPLE: 'T.ex \'ingen koriander\'',
    CART_ITEM_COMMENT: 'Skriv din kommentar här',
    CART_ITEM_REMOVE_CODE: 'Ta bort koden?',
    CART_ITEM_18Y: '18+ år och giltig identifikation krävs vid köp av alkohol',
    CART_ITEM_ALCOHOL: 'Alkohol rabatteras ej',

    CART_NO_ACTIVE_TAB: 'Du har ingen aktiv bordsnota',
    CART_GO_TO_MENY: 'Gå till menyn och gör din första beställning',
    CART_PREPARNG_SWISH: 'Förbereder Swish-betalning',
    CART_PAYMENT_ABORTED: 'Betalningen avbröts',
    CART_CREATING_ORDER: 'Skapar order och lägger till på notan',
    CART_SOME_ERROR: 'Något gick fel',
    CART_CHECK_CARD: 'Se så ditt kort är öppet för internetköp och att tillräcklig summa finns tillgänglig',
    CART_WEEK_MENU: 'Veckans meny',
    CART_ORDER_TAKEWAY: 'Beställ takeaway',
    CART_MY_BOOKING: 'Min bokning',
    CART_BOOK_TABLE: 'Boka bord',
    CART_ALLERGIC_CONTACT: 'Allergisk? Kontakta restaurangen på',
    CART_PERSONNEL_CARD: 'Personalkort',
    CART_SERVE_NOW: 'Servera direkt',

    CART_WELCOME_READ: 'Läs mer',
    CART_WELCOME_OPENING_HOURS: 'Öppettider & kontaktinformation',

    CUSTOMER_TAB_KEY_KOD: 'Bordskod',
    CUSTOMER_TAB_KEY_WRONG_CODE: 'Fel bordskod',
    CUSTOMER_TAB_KEY_SAME_CODE: 'Samma kod igen? Tryck här',
    CUSTOMER_CODE_TYPE_4_DIGITS: 'Skriv 4 siffror',

    ACTIVE_ORDER_ONGOING: 'Pågående ordrar',
    ORDERS: 'Ordrar',
    ACTIVE_ORDER_DEL_CUSTOMER: 'Din order är placerad i köket och du får en notifikation när ordern körs ut till dig',
    ACTIVE_ORDER_WAITING: 'Tack! Väntar på godkännande',
    ACTIVE_ORDER_TAKEAWAY: 'Din order är placerad i köket och du får strax en uppskattad tid när du kan hämta upp den',
    ACTIVE_ORDER_TABLE: 'Tack! Vi är på gång med beställningen och kommer ut med den till dig så snart vi kan!',
    ACTIVE_ORDER_READY: 'Din order är redo',
    ACTIVE_ORDER_FOOD_READY: 'Din mat är redo',
    ACTIVE_ORDER_DRINK_READY: 'Din dryck är redo',
    ACTIVE_ORDER_TICKET: 'Här är din biljett',
    ACTIVE_ORDER_SHOW_SCREEN: 'Visa upp den här skärmen vid disken',
    ACTIVE_ORDER_HOME_DELIVERY_OTW: 'Den lämnar restaurangen nu och är påväg mot dig!',
    ACTIVE_ORDER_TABLE_DELIVERY_OTW: 'Den levereras strax till ditt rum',
    ACTIVE_ORDER_RECIEVED_Q: 'Har du fått din order?',
    ACTIVE_ORDER_CLOSE_WINDOW: 'Detta fönster försvinner om du väljer \'Ja\'',

    ACCOUNT_REMOVE_CARD: 'Ta bort kort?',
    ACCOUNT_REMOVE_CARD_2: 'Vill du ta bort kortet ',
    ACCOUNT: 'Konto',
    LOGOUT: 'Logga ut',
    CREATE_LOGIN: 'Skapa konto / logga in',
    ME: 'Jag',
    BONUSPOINTS: 'Bonuspoäng',
    HANDLE_NOTIFICATIONS: 'Hantera notiser',
    SAVED_CARDS: 'Sparade kort',
    MY_RECIEPTS: 'Mina kvitton',
    RECIEPTS: 'Kvitton',

    PURCHASE_HISTORY_FILL_MAIL: 'Fyll i din mailadress',
    PURCHASE_HISTORY_SENDING_RECIEPT: 'Skickar kvitto till',
    PURCHASE_HISTORY_SENDING_SENT: 'Kvittot mailades till',
    PURCHASE_HISTORY_MAIL_RECIEPT: 'Maila kvitto',
    PURCHASE_HISTORY_BUY_AT: 'Köp hos',
    PURCHASE_HISTORY_PHONE: 'Telefon',
    PURCHASE_HISTORY_VATID: 'VatId',
    DATE: 'Datum',
    PURCHASE_HISTORY_ORDER: 'Order',
    PURCHASE_HISTORY_DISCOUNT: 'rabatt, summa',
    PURCHASE_HISTORY_TOTAL: 'Totalt',
    PURCHASE_HISTORY_SUM: 'Sum',
    PURCHASE_HISTORY_COLLECTED_DISCOUNT: 'Samlad rabatt',
    PURCHASE_HISTORY_ROUNDING: 'Öresavrundning',
    PURCHASE_HISTORY_CONTROL_UNIT: 'K.enhet',
    PURCHASE_HISTORY_RECIEPT_NO: 'Kvittonr',
    PURCHASE_HISTORY_VAT: 'Moms',
    PURCHASE_HISTORY_NETTO: 'Netto',
    PURCHASE_HISTORY_Brutto: 'Brutto',

    MENU_NOT_UPDATED: 'Det verkar som om att restaurangen inte uppdaterat sin meny än',
    WEEK: 'Vecka',


    LOGIN: 'Logga in',
    LOGIN_PW_6: 'Ditt lösenord måste innehålla minst 6 tecken',
    LOGIN_EMAIL_FORMAT: 'Din email verkar vara felformaterad. Dubbekolla och försök igen',
    LOGIN_EMAIL_IN_USE: 'Denna email används redan',
    LOGIN_NEW_USER: 'Ny användare',
    LOGIN_FINALIZING: 'Gör klart det sista',
    LOGIN_CONTACTING_FACEBOOK: 'Kontaktar Facebook...',
    LOGIN_PHONE: 'Logga in med telefonnummer',
    LOGIN_APPLE: 'Logga in med Apple',
    LOGIN_FACEBOOK: 'Logga in med Facebook',
    LOGIN_EMAIL: 'Logga in med e-post',
    LOGIN_TERMS: 'Våra villkor',
    LOGIN_NO_ACCOUNT_Q: 'Inget konto ännu?',
    LOGIN_CREATE: 'Skapa',
    LOGIN_RESET: 'Återställ',
    LOGIN_NAME_NEEDED: 'Ditt namn behövs för att para ihop dig med dina ordrar',
    LOGIN_HI: 'Hej',
    LOGGING_IN: 'Du loggas in',
    LOGIN_WAITING_CODE: 'Väntar på kod...',
    LOGIN_VERIFYI: 'Verifiera',
    SEND_SMS_CODE: 'Skicka smskod',
    LOGGING_IN_2: 'Loggar in',
    LOGIN_WRITE_EMAIL: 'Skriv in din mail',
    OFFER_TO_LOGIN_TITLE: 'Logga in!',
    OFFER_TO_LOGIN_SUBHEADER: 'Logga in för att se dina ordrar och ta del av erbjudanden.',
    COMPLETE_ORDER_TITLE: 'Din order är färdig!',
    COMPLETE_ORDER_SUBHEADER: 'Vill du öppna dina aktiva ordrar?',
    EMPTY_ACTIVE_ORDER_PAGE: 'Du har inga aktiva ordrar.',
    EMAIL: 'Email',
    EMAIL_SENT: 'Mail skickat',
    EMAIL_FOLLOW_INSTRUCTIONS: 'Öppna din inkorg och följ instruktionerna',
    HOUR: 'Timme',
    MINUTE: 'Minut',
    ORDER_CONFIRMED: 'Din beställning är genomförd',
    ADDITIONAL_ACCESSORIES: 'Extra tillbehör',
    // Reepay charge errors
    ACQUIRER_ERROR: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    INSUFFICIENT_FOUNDS: 'Misslyckades på grund av otillräckliga medel.',
    PAYMENT_NOT_AUTHORIZED: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    CREDIT_CARD_EXPIRED: 'Ditt kort har gått ut. Vänligen prova ett annat',
    DECLINED_BY_ACQUIRER: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    CREDIT_CARD_LOST_OR_STOLEN: 'Detta kort är markerat som stulet. Ditt ip har loggats',
    CREDIT_CARD_SUSPECTED_FRAUD: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    REFOUND_AMOUNT_TOO_HIGH: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    AUTHORIZATION_EXPIRED: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    AUTHORIZATION_AMOUNT_EXCEEDED: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    AUTHORIZATION_VOIDED: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    SCA_REQUIRED: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    RISK_FILTER_BLOCK: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen'
};
