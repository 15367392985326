import { Location } from './services/location';

export class Coupon {
  discountPercent: number;
  code: string;
}
export class AddCreditcardOutput {
  number: string;
  expMonth;
  cardHolderName:string;
  expYear;
  cvc;
  saveCardForFutureUse;
  nickname;
  type:"VISA" | "MASTERCARD" | "DEBIT MASTERCARD" | "VISA DEBIT" | "MAESTRO" | "VISA ELECTRON"
};

export class Option {
  enabled:boolean;
  cost: number;
  text: string;
  type: string;
  selected: any; // boolean or Choice
  choices: Choice[];
  step?;
}

export class Choice {
  cost: number;
  text: string;
}

export class MenuItem {
  key:string;
  ImageUrl: string;
  Name: string;
  Description: string;
  DescriptionLong: string;
  Cost: number;
  NrOfItems: number;
  options: Option[];
  enabled: boolean;
  isUsingBuildABurger?: boolean;
  type: { containsAlcohol: boolean, productTypeId: number, bongCategoryType: { id: string, label: string }, productCategoryType: { id: string, label: string } };
}
export class City {
  name: string;
  location: Location;
  imageUrl: string;
  distanceInKm: number;
  constructor(name: string, location: Location) {
    this.name = name;
    this.location = location;
  }

}