import { Injectable } from '@angular/core';
// import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
// import { AppLauncher } from '@ionic-native/app-launcher/ngx';

import { decode } from 'blurhash';
// import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { Location } from '../location';
import { ThemeProvider } from '../theme/theme';

@Injectable({
  providedIn: 'root'
})
export class ContextProvider {
  client: ClientInfo;
  clientContext = new ClientContext();
  menu;
  theme;
  constructor(
    private db: AngularFireDatabase,
    private themeProvider: ThemeProvider
    // private iab: InAppBrowser,
    // private platform: Platform,
    // private appLauncher: AppLauncher

  ) { }
  launchExternalApp(
    appUrl: string,
    data: string
  ) {
    // if (this.platform.is('android')) {
    //   this.appLauncher.launch({ uri: appUrl + data }).then(x => {
    //     console.log(x);
    //   });
    // } else {
    //   const browser = this.iab.create(appUrl + data, "_system", { location: "no", usewkwebview: "yes" });
    //   browser.show();
    // }
    window.open(appUrl + data, '_blank');

  }

  openSwish(data) {
    this.launchExternalApp(
      'swish://',
      data
    );
  }

  getClient() {
    return this.client;
  }

  init(client: ClientInfo) {
    this.client = client;
    const clientRef = 'Clients/' + this.client.routeName;

    this.themeProvider.init(this.client.routeName); 

    // Get styles
    this.db.database.ref(clientRef + '/theme/webOrder').once('value').then(snapshot => {
      try {
        const data = snapshot.val();
        if (!data) {
          this.clientContext.theme.next(null);
          return;
        } else {
          this.clientContext.theme.next(data);
        }
      } catch (e) {
        console.log(e);
      }
    }, err => {
      console.log(err);
    });

    this.db
      .object(clientRef + '/AppInfo')
      .valueChanges()
      .subscribe(async (x: AppInfo) => {
        const openingHours = (await this.db.database.ref(
          `adminApp/clients/${this.client.routeName}/settings/openingHours`).once('value')).val();
        x.Context.openingHours = openingHours;
        if (!client.location) {
          client.location = x.Context.location;
        }
        this.clientContext.appInfo.next(x);
      });

    this.db
      .list(clientRef + '/activeMenu/categories')
      .valueChanges()
      .subscribe((x: [{ items: any[] }]) => {
        x.forEach(category => {
          if (category.items) {
            category.items.forEach(item => {
              if (item.blurHash) {
                const height = 8;
                const width = 8;
                const pixels = decode(item.blurHash, width, height);
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                const imageData = ctx.createImageData(width, height);
                imageData.data.set(pixels);

                ctx.putImageData(imageData, 0, 0);
                item.sdStyling = {
                  'background-image': `url(${canvas.toDataURL()})`,
                  'background-size': 'cover',
                  'background-repeat': 'no-repeat'
                };

              } else {
                item.sdStyling = {
                  'background-image': 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAA7UlEQVQoUwXBvU6DUBiA4fcgVWNSGbwAJ+P1ODt7A96Co5txcWhi4sgVaGLUxdXBxZ/Y1gSxlMIBD3D47efzKHV4JLubA/s7NWPXMDQ5y1Qzm2mgQR2fnMrGumfUlIjVFOWKhUl405Y4EdTk5lqoG9ZJShWFxFlIYDULO1AXDur+6VakLGmDgHz+RZj+EEiHHlxU5aAeHu9kMDn19J3044XfvxXR9h7VaIzbCMr3fRlMhp2+kn0+E9c9qXdAu+Xhti3q4nIiYgu6aE4VfZN1a/TIw4hLV1aos/MrcfoOxxb01mBqy9I2BHlFGBv+AbCTmBgUfFQ3AAAAAElFTkSuQmCC)',
                  'background-size': 'cover',
                  'background-repeat': 'no-repeat'
                };
              }
              // item.sdStyling = {
              //   'background': 'black'
              // };
            });
          }
        });
        this.clientContext.menu.next(x);
      });

    this.db
      .list(`adminApp/clients/${this.client.routeName}/news`)
      .valueChanges()
      .subscribe(x => {
        this.clientContext.news.next(x);
      });
      
    const subGateway = this.db
      .object<IMerchantConfig>(`adminApp/clients/${this.client.routeName}/settings/paymentGateway`)
      .valueChanges()
      .subscribe((x) => {
        this.clientContext.paymentGateway.next(x);
        setTimeout(() => {
          subGateway.unsubscribe();
        }, 2000);
      });

    this.clientContext.currentClient.next(client);
  }
}

export interface IMerchantConfig {
  terminalId: string;
  secret: string;
  merchantRef: string;
  hostedPaymentPageUrl: string;
  hostedPreAuthPageUrl: string;
  acceptUrl: string;
  useXml: boolean;
  mpi?: boolean;
  mpiAmount?: number;
}
export class DeliveryOption {
  name = '';
  fee = 0;
  minValue = 0;
  maxValue = 0;
}
export class ClientContext {
  appInfo = new BehaviorSubject<AppInfo>(null);
  menu = new BehaviorSubject<[{}]>(null);
  theme =  new BehaviorSubject<[{}]>(null);
  news = new BehaviorSubject<any[]>([]);
  paymentGateway = new BehaviorSubject<IMerchantConfig>(null);
  currentClient = new BehaviorSubject<ClientInfo>(null);
}
export class AppInfo {
  Context: Context;
  MaxArticles: number;
  MomsRegNr: string;
  style: { menuCards: boolean, facebookLink: { url: string, shown: boolean }, instagramLink: { url: string, shown: boolean } };
  appName: string;
}
export class Context {
  aboutUsDescription?: string;
  showItemsComment: boolean;
  showCustomerComment: boolean;
  policyUrl: string;
  allergyInfo: { phone: string, open: string, close: string };
  drinksTab: { enabled: boolean, hidden: boolean };
  foodTab: { enabled: boolean, hidden: boolean };
  customTickets: boolean;
  weeklyMenu: { enabled: boolean, content: any[] };
  globalOffersEnabled: boolean;
  stockBalanceEnabled: boolean;
  languageHandlingEnabled: boolean;
  minimumBonusVoucherAmount: number;
  tabKeyAcceptsText: boolean;
  Tables: {
    LowerValue: number;
    UpperValue: number;
    fee: number;
    enabled: boolean;
    icon?: string;
    text?: string;
  };

  restaurantPhoneNumber: string;
  restaurantEmail: string;
  openingHours: { weekdays: any[], deviations: any[] };
  orderByCustomerTabKey: boolean;
  homeDelivery: { enabled: boolean, text?: string, radiuses: any[] };
  takeawayDelivery: { enabled: boolean, icon?: string, text?: string };
  sitAtRestaurantDelivery: { enabled: boolean, icon?: string; text?: string; };
  drinks: { isOpen: boolean; closedComment: string };
  food: { isOpen: boolean; closedComment: string };
  estimatedWaitingTimeText: string;
  showEstimatedWaitingTime: boolean;
  useDefaultCoupon: boolean;
  defaultCouponCode: string;
  paymentOptions: {
    card: PaymentOption;
    swish: PaymentOption;
    customerTab: PaymentOption;
  };
  tableBooking: { enabled: boolean };
  signInMethods: {
    ios: { apple: boolean, facebook: boolean, email: boolean, phone: boolean },
    android: { apple: boolean, facebook: boolean, email: boolean, phone: boolean }
  };
  deliveryDate: { enabled: boolean };
  couponCode: { enabled: boolean };
  location: any;
  receiptOnly: boolean;
}
export class PaymentOption {
  enabled: boolean;
  id: string;
}
export class ClientInfo {
  name: string;
  routeName: string;
  address: string;
  phoneNumber: string;
  location: Location;
  imageUrl?: string;
  distanceInKm?: number;
}
