import { Component, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastController, ModalController, ActionSheetController, NavController, NavParams, AlertController } from '@ionic/angular';
import { PaymentProvider } from '../services/payment/payment';
import { CartProvider } from '../services/cart/cart';
import { UserProvider, User } from '../services/user/user';
import { AngularFireDatabase } from '@angular/fire/database';
import { ContextProvider } from '../services/context/context';
import { ActivatedRoute } from '@angular/router';
import { OrderProvider } from '../services/order/order';
import { LanguagePipe } from '../pipes/language.pipe';
import { LanguageProvider } from '../services/language/language.provider';


@Component({
  selector: 'page-customer-tabs-list',
  templateUrl: 'customer-tabs-list.html',
  styleUrls: ["customer-tabs-list.scss"]
})
export class CustomerTabsListPage {
  user: User = null;
  cardBackgroundString = "";
  background = "center url('./img/cartBackground.jpg') no-repeat";
  tabs = {};
  tabKeys = [];
  userSubscription: Subscription;
  client?: string = null;
  city?: string = null;
  routeName?: string = null;
  constructor(private toastController: ToastController, 
    private alertController: AlertController,
    private orderProvider:OrderProvider, 
    private activatedRoute: ActivatedRoute, 
    private paymentProvider: PaymentProvider, 
    private cartProvider: CartProvider, 
    private modalCtrl: ModalController, 
    private actionSheetCtrl: ActionSheetController, 
    public navCtrl: NavController, 
    public userProvider: UserProvider, 
    private db: AngularFireDatabase, 
    private contextProvider: ContextProvider,
    private languagePipe:LanguagePipe,
    public languageProvider:LanguageProvider) 
  {
    this.client = this.activatedRoute.snapshot.params.client;
    this.city = this.activatedRoute.snapshot.params.city;
    this.routeName = this.activatedRoute.snapshot.params.routeName;
  }
  goBack() {
    this.navCtrl.navigateBack([`/main/${this.client}/${this.city}/${this.routeName}`]);
  }
  ngAfterViewInit() {
    this.userSubscription = this.userProvider.currentUser.subscribe(x => {
      this.user = x;
      if (this.user == null) return;
      if (this.user.activeTabs) {
        //this.routeName = this.contextProvider.client.routeName;
        Object.keys(this.user.activeTabs).forEach(async x => {

          this.db.object(`Clients/${this.routeName}/customerTabs/${x}`).valueChanges().subscribe((tab: any) => {
            if (!tab)
              return;
            if (this.tabKeys.filter(x => x === tab.key).length > 0) {
              this.tabs[x] = tab;
              return;
            }
            this.tabKeys.push(x);
            this.tabs[x] = tab;
          });
        });
      }
    });
  }
  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
  async demandAttention(tab) {
    await this.db.database.ref(`Clients/${this.routeName}/attentionQueue/${tab.label}/read`).set(false);
    let toast = await this.toastController.create({
      duration: 4000,
      position: "bottom",
      message: this.languagePipe.transform("Vi kommer strax och hjälper dig",'CUSTOMER_TABS_ATTENTION_MSG',this.languageProvider.currentLanguage),
      cssClass:"demand-attention-toast"
    })
    toast.present();
  }
  async payTab(tab) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: `Nota ${tab.label}`,
      buttons: [
        // {
        //   text: `${this.languagePipe.transform("Betala i appen",'CUSTOMER_TABS_PAY_IN_APP',this.languageProvider.currentLanguage)} ${String.fromCodePoint(0x1F4F2)}`,
        //   role: 'payinapp',
        //   handler: () => {

        //   }
        // }, 
        {
          text: `${this.languagePipe.transform("Tillkalla kypare",'CUSTOMER_TABS_CALL_WAITER',this.languageProvider.currentLanguage)} ${String.fromCodePoint(0x1F44B)}`,
          role: 'callwaiter',
          handler: () => {
            this.demandAttention(tab);
          }
        }, {
          text: this.languagePipe.transform('Avbryt','CANCEL',this.languageProvider.currentLanguage),
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    actionSheet.present();
    let result = await actionSheet.onDidDismiss();
    let role = result.role;
    if (role === "cancel")
      return;
    if (role === "payinapp") {
      let id = this.cartProvider.generateOrderId();
      let userItems = tab.contents.filter(x => x.userId === this.user.id);
      this.orderProvider.tabItemsWaitingForApproval[id] = userItems;
      let amountToCharge = userItems.filter(x => !x.paid).map(x => {
        let cost = x.Cost + this.cartProvider.costOfItemOptions(x) * x.quantity;
        if (x.coupon)
          return cost - (cost * parseFloat(x.coupon.amount) / 100);
        return cost;
      }).reduce((val, acc) => {
        return acc + val;
      });
      //Decide tip amount
      let tipAmount = 0;
      let twentyPercent = Math.round((amountToCharge * 0.20) / 100);
      let fifteenPercent = Math.round((amountToCharge * 0.15) / 100);
      let tenPercent = Math.round((amountToCharge * 0.10) / 100);
      let tipAction = await this.actionSheetCtrl.create({
        header: this.languagePipe.transform("Dricks",'TIPS',this.languageProvider.currentLanguage),
        subHeader: this.languagePipe.transform("Vill du ge något extra till vår personal?",'CUSTOMER_TABS_TIPS',this.languageProvider.currentLanguage),
        buttons: [
          {
            text: `20% = ${twentyPercent}kr ${String.fromCodePoint(0x1F60D)}`,
            role: 'twenty',
            handler: () => {
              tipAmount = twentyPercent * 100;
            }
          },
          {
            text: `15% = ${fifteenPercent}kr ${String.fromCodePoint(0x1F970)}`,
            role: 'fifteen',
            handler: () => {
              tipAmount = fifteenPercent * 100;
            }
          },
          {
            text: `10% = ${tenPercent}kr ${String.fromCodePoint(0x1F60A)}`,
            role: 'callwaiter',
            handler: () => {
              tipAmount = tenPercent * 100;
            }
          },
          {
            text: `${this.languagePipe.transform('Valfri summa...','CUSTOMER_TABS_CUSTOM_AM1',this.languageProvider.currentLanguage)} ${String.fromCodePoint(0x1F60F)}`,
            role: 'customamount',
            handler: async () => {
              let input = await this.alertController.create({
                header: this.languagePipe.transform("Valfri summa",'CUSTOMER_TABS_CUSTOM_AM2',this.languageProvider.currentLanguage),
                inputs: [
                  {
                    type: "tel",
                    label:this.languagePipe.transform("Summa",'SUM',this.languageProvider.currentLanguage),
                    name: "customamount"
                  }
                ],
                buttons: [{
                  text: this.languagePipe.transform("Avbryt",'CANCEL',this.languageProvider.currentLanguage),
                  role: "cancel"

                },
                {
                  text: this.languagePipe.transform("Ok",'CONFIRM',this.languageProvider.currentLanguage),
                  role: "confirm",
                  handler: (result) => {
                    return result.customamount;

                  }
                }]
              });
              input.present();
              let result = await input.onDidDismiss();
              if (result.role === "confirm") {
                let amount = parseInt(result.data.values.customamount);
                if (isNaN(amount))
                  return false;
                if(amount < 0)
                  return false;
                tipAmount = amount * 100;
                return tipAmount;
              }
              return false;
            }
          },
          {
            text: this.languagePipe.transform('Nej tack','OPT_OUT',this.languageProvider.currentLanguage),
            role: "notip"
          },
          {
            text: this.languagePipe.transform('Avbryt','CANCEL',this.languageProvider.currentLanguage),
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      });
      tipAction.present();
      let tipResult = await tipAction.onDidDismiss();
      console.log(tipResult);
      if(tipResult.role === "cancel")
        return;

      this.navCtrl.navigateForward(["payment"], { queryParams: { amountToCharge, action: "paytab", tabId: tab.key,tipAmount:tipAmount,orderId:id } });
    }
  }

}

@Pipe({
  name: 'userid',
  pure: false
})
export class UserIdFilterPipe implements PipeTransform {
  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter(item => item.userId === filter);
  }
}
