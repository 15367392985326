module.exports = {
    version: "19.2.48",
    authorEmail: "hw@pubq.se",
    authorUrl: "pubq.se",
    authorName: "PubQ AB",
    dbProd: {
        apiKey: "AIzaSyClzLTuG8q1z0UhIFBmD-1QVibHz4v5Dnk",
        authDomain: "pq-dev.firebaseapp.com",
        databaseURL: "https://pq-dev.firebaseio.com",
        projectId: "pq-dev",
        storageBucket: "pq-dev.appspot.com",
        messagingSenderId: "923325473711",
        appId: "pq-web"
    },
    dbStaging: {
        apiKey: "AIzaSyBmfb5lbJ29AdVQyp0Jk1Tug6pekx_fTwA",
        authDomain: "pq2-staging.firebaseapp.com",
        databaseURL: "https://pq2-staging.firebaseio.com",
        projectId: "pq2-staging",
        storageBucket: "pq2-staging.appspot.com",
        messagingSenderId: "438992683153",
        appId: "1:438992683153:web:2f37f322f2d9ee4f723f30"
    }
}