import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonRouterOutlet, NavController } from '@ionic/angular';
import { LanguageProvider } from 'src/app/services/language/language.provider';

@Component({
    selector: 'page-confirm-payment',
    templateUrl: 'paymentConfirmation.component.html',
    styleUrls: ['paymentConfirmation.component.scss']
})
export class PaymentConfirmationComponent {
    showCard = false;
    showIcon = false;
    client?: string = null;
    city?: string = null;
    routeName?: string = null;
    constructor(
        private routerOutlet: IonRouterOutlet,
        private navCtrl: NavController,
        private activatedRoute: ActivatedRoute,
        public languageProvider: LanguageProvider) 
    {
        this.client = this.activatedRoute.snapshot.params.client;
        this.city = this.activatedRoute.snapshot.params.city;
        this.routeName = this.activatedRoute.snapshot.params.routeName;
    }
    ngOnInit() {
        setTimeout(() => {
            this.showCard = true;
        }, 1000);
        setTimeout(() => {
            this.showIcon = true;
        }, 1500);
        setTimeout(() => {
            this.home();
        }, 4000);
    }
    home() {
        this.routerOutlet.swipeGesture = true;
        this.navCtrl.navigateForward([`/main/${this.client}/${this.city}/${this.routeName}`], {
            // relativeTo: this.activatedRoute,
            // queryParams: {
            //   showLoginAdvice: true
            // }
        });
    }
}
