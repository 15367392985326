import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-route',
  templateUrl: './no-route.page.html',
  styleUrls: ['./no-route.page.scss'],
})
export class NoRoutePage implements OnInit {

  constructor() { }

  ngOnInit() {
    setTimeout(()=>{
      document.location.href = 'https://www.pubq.se';
    },700);
  }

}
