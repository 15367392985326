import { Component, ViewChild, Input, HostListener } from '@angular/core';
import {
  IonContent,
  NavController,
  ModalController,
  AlertController,
  Platform,
  IonRouterOutlet,
  IonNav,
} from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AppInfo, ContextProvider } from '../services/context/context';
import { Subscription, Subject } from 'rxjs';
import { CartProvider } from '../services/cart/cart';
import { CartItem, CartItemsOutput } from '../cart-items/cart-items';
import {
  BuildStep,
  BurgerFlow,
  BurgerFunctions,
} from './build-a-burger/burgerOrder';
import { ActivatedRoute } from '@angular/router';
import { AppConfiguration } from '../app.configuration';
import { LanguageProvider } from '../services/language/language.provider';
import { LanguagePipe } from '../pipes/language.pipe';
import { LoginNavComponent } from '../account/login-nav.component';
import { User, UserPaymentSource, UserProvider } from '../services/user/user';
import * as firebase from 'firebase';
import { UnitProvider } from '../services/unitProvider';
import { MenuItemDescriptionComponent } from './itemDescription';
import { ThemeProvider } from '../services/theme/theme';
import { TableSettings } from '../cart/table-settings/table-settings';
import { Order, OrderProvider } from '../services/order/order';
import * as moment from 'moment';
import { LogMemoryService } from '../services/logMemory.provider';

@Component({
  selector: 'page-menu',
  templateUrl: 'menu.html',
  styleUrls: ['menu.scss'],
  providers: [IonRouterOutlet],
})

export class MenuPage {
  @ViewChild('myContent') content: IonContent;
  @ViewChild('cart') cartElement: any;
  @Input() nav: IonNav;
  @HostListener('window:scroll', ['$event'])
  subscriptions: Subscription = new Subscription();
  bottomPaddingHtmlElement: HTMLElement;
  menuSegmentType = AppConfiguration.menuSegmentType;
  itemElementsCache = {};
  toggleDrink = { item: -1 };
  toggleFood = { item: -1 };
  openHours = true;
  slideHeight = 0;
  isLoading = true;
  menu: any;
  shownMenuItems: any;
  categoryNames: string[];
  categoryHtmlElements: HTMLElement[];
  appInfo: AppInfo;
  menuType: 'drinks' | 'food' | 'takeaway' = 'food';
  menuTypeName: 'Mat' | 'Dryck' | 'Takeaway' = 'Mat';
  menuTypeTitle = 'Food';
  slideIndex = 0;
  background = '';
  toggleItemIndex = 999;
  shownCategoryName = '';
  contextSubscription: Subscription;
  menuSubscription: Subscription;
  themeSubscription: Subscription;
  loadedImages = {};
  noItemsToShow = false;
  scrollToProduct = { initiated: false, productId: '' };
  user: User;
  logInUser = false;
  userProfileName = '';
  userSubscription: Subscription;
  windowWidth: any;
  mainBackgroundColor = {};
  logo = {};
  mainRestaurantImage = {};
  mainButtonColor = {};
  activeSegmentColor = {};
  secondaryBackgroundColor = {};
  backButtonColor = {};
  hoverItem = null;
  itemsForDishInfo: any;
  updateCartPageData: boolean;
  showDishInfo = false;
  itemIsHover = false;
  iosPlatform = false;

  /******** Only for Melodyclub *********** */
  orderFromCart: any = null;
  appCode: string = 'N/A';
  orderCode: string = 'N/A';
  /**************************************** */

  activeTabsCount = 0;
  unit: any = null;
  cartMargins: any = {
    "margin-left": "30px",
    "margin-top": "65px"
  };

  client?: string = null;
  city?: string = null;
  routeName?: string = null;
  constructor(
    private contextProvider: ContextProvider,
    public navCtrl: NavController,
    private activatedRoute: ActivatedRoute,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public cartProvider: CartProvider,
    private platform: Platform,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    private userProvider: UserProvider,
    private unitProvider: UnitProvider,
    public themeProvider: ThemeProvider,
    private storage: Storage,
    private orderProvider: OrderProvider,
    private logMemoryService: LogMemoryService
  ) {

    this.isLoading = true;

    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0) {
      this.iosPlatform = true;
    }

    this.client = this.activatedRoute.snapshot.params.client;
    this.city = this.activatedRoute.snapshot.params.city;
    this.routeName = this.activatedRoute.snapshot.params.routeName;
    this.logMemoryService.routeName = this.routeName;

    /*********** ONLY FOR MELODYCLUB ************ */
    this.storage.get("PUBQ_TAB_SETTINGS").then((x) => {
      let data = JSON.parse(x);

      let body = {
        appCode: '3215',
        orderCode: 'RUM1'
      }

      if (data) {
        body = data;
      }

      this.appCode = body.appCode;
      this.orderCode = body.orderCode;
    });

    /******************************************** */

    // Hämta klient data
    this.subscriptions.add(this.unitProvider.getUnits(this.client, this.city).subscribe(async data => {
      if (!data) {
        return;
      }

      if (data) {
        let filterUnits = data.filter(x => x.routeName == this.routeName);
        if (filterUnits.length > 0) {
          console.log("Unit:");
          console.dir(filterUnits[0]);
          this.unit = filterUnits[0];
          this.contextProvider.init(this.unit);
        }
      } else if (this.client) {
        this.navCtrl.navigateBack(`unitselection/${this.client}/all`);
      }
      else {
        console.log("Could not any unit for routeName " + this.routeName)
        navCtrl.navigateBack('no-route');
      }
    })
    );


    this.subscriptions.add(
      this.userProvider.currentUser.subscribe((x) => {
        this.user = x;
        if (!this.user) return;
        if (this.user.activeTabs) {
          this.activeTabsCount = Object.keys(this.user.activeTabs).length;
        } else {
          this.activeTabsCount = 0;
        }
      })
    );


    this.userSubscription = this.userProvider.currentUser.subscribe(
      (userInfo: User) => {
        if (!userInfo) { return }
        console.log("Found user");
        console.dir(userInfo);
        this.user = userInfo;
        if (this.user && this.user.firstName) {
          this.userProfileName = this.user.firstName;
        }
        firebase.auth().onAuthStateChanged(user => {
          if (user) {
            this.logInUser = !user.isAnonymous;
          }
        });
      });
  }

  ngOnInit() {
    this.windowWidth = window.innerWidth;


    // this.isIphone = this.platform.is('iphone');
    this.isLoading = true;
    setTimeout(async () => {
      this.contextSubscription = this.contextProvider.clientContext.appInfo.subscribe(
        (data) => {
          console.log("AppInfo");
          console.dir(data);
          this.appInfo = data;
        }
      );
      this.menuSubscription = this.contextProvider.clientContext.menu.subscribe(
        (data) => {
          if (data) {
            this.shownMenuItems = null;
            this.itemElementsCache = {};
            this.menu = data;//this.filterCategories(data);
            this.categoryNames = this.getCategoryNames(this.menu);
            setTimeout(() => {
              this.categoryHtmlElements = [];
              this.categoryNames.forEach((x, i) => {
                this.categoryHtmlElements.push(
                  document.getElementById('' + x + i)
                );
              });
              if (this.scrollToProduct.initiated) {
                const element = document.getElementById(this.scrollToProduct.productId);
                if (!element) {
                  return;
                }
                const rect = element.getBoundingClientRect();
                this.content.scrollByPoint(rect.x, rect.y - 200, 0);
              }

            }, 500);
            this.displayMenu();
            this.isLoading = false;
          }
          // if(this.userProvider && !this.userProvider.currentUser){
          //   this.userProvider.createAnonymousUser();
          // }

        }
      );

      this.themeSubscription = this.contextProvider.clientContext.theme.subscribe((data: any) => {
        this.mainBackgroundColor = {
          'background-color': '#F5F5F5'
        };
        this.secondaryBackgroundColor = {
          'background-color': '#F5F5F5',
          color: '#000',
          'font-weight': 'bold'
        };
        this.backButtonColor = {
          color: '#000'
        };
        if (data) {
          this.mainButtonColor = {
            'background-color': data.mainButtonColor,
            color: '#FFFAFA'
          };
          this.logo = {
            'background-image': data.mainLogo,
          };
          this.mainRestaurantImage = {
            'background-image': data.mainImage
          };
          this.activeSegmentColor = {
            color: data.mainButtonColor,
            'border-bottom': `2px solid ${data.mainButtonColor}`,
            'font-weight': 'bold'
          };
        } else {
          this.logo = {
            'background-image': 'assets/img/logo_wide.png',
          };
          this.mainRestaurantImage = {
            // tslint:disable-next-line:max-line-length
            'background-image': 'https://www.gordonramsayrestaurants.com/assets/Uploads/_resampled/CroppedFocusedImage160055050-50-GRBG-Sim-Shoot-3-2000px.jpg'
          };
          this.mainButtonColor = {
            'background-color': '#bc9049',
            color: '#ffffff'
          };
          this.activeSegmentColor = {
            color: '#bc9049',
            'border-bottom': `2px solid #bc9049`,
            'font-weight': 'bold'
          };
        }
      });

      //Check if any user
      console.log("Check if any user")
      console.dir(this.userProvider.currentUser.value)
      if (!this.userProvider.currentUser.value) {
        console.log("Creates a new user")
        await this.userProvider.createAnonymousUser();
        this.user = this.userProvider.currentUser.value;
      }

    }, 300);
    this.bottomPaddingHtmlElement = document.getElementById('reached-end');
  }

  ngOnDestroy() {
    if (this.contextSubscription)
      this.contextSubscription.unsubscribe();
    if (this.menuSubscription)
      this.menuSubscription.unsubscribe();
    if (this.themeSubscription)
      this.themeSubscription.unsubscribe();
    if (this.subscriptions)
      this.subscriptions.unsubscribe();
  }

  ionViewDidEnter() {
    this.content.scrollToTop();
    this.updateCartPageData = !this.updateCartPageData;
  }


  filterCategories(categories: any) {
    const filtered = [];
    categories.forEach((item) => {
      if (this.menuType === 'takeaway') {
        const takeAwayItems = item.items.filter(
          (x) => x.tags && x.tags.find((p) => p === this.menuType)
        );
        if (takeAwayItems.length > 0) {
          item.items = takeAwayItems;
          filtered.push(item);
          return;
        }
      }
      filtered.push(item);
    });
    return filtered;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  // selectMenuItemsToShow(categories: any[]) {
  //   if (!this.appInfo.style.menuCards) { return categories; }
  //   const menuCategories = [];
  //   for (let i = 0; i < categories.length; i++) {
  //     const element = categories[i];
  //     menuCategories.push(element);
  //   }

  //   return menuCategories;
  // }

  onLanguageChanged(ev) {
    console.log("Language");
    console.dir(ev);
    this.languageProvider.setLanguage(ev.detail.value);
  }

  getOnlineInfo() {
    if (this.appInfo) {
      if (!this.appInfo.Context.food.isOpen) {
        return 'Går inte att beställa online - ' + this.appInfo.Context.food.closedComment;
      } else {
        return `Online ${this.orderCode} - Nota ${this.appCode}`;
      }
    }
  }

  getCategoryNames(categories: any[]): string[] {
    const categoryNames = [];
    categories.forEach((category) => {
      categoryNames.push(category.name);
    });
    return categoryNames;
  }

  isLoaded() {
    return this.appInfo != null;
  }

  isOpen() {
    if (!this.isLoaded()) { return false; }

    if (!this.appInfo.Context[this.menuType]) {
      console.log("Menu type " + this.menuType + " do not exist in the context for this merchant!!");
    }

    return (
      this.appInfo.Context[this.menuType] &&
      this.appInfo.Context[this.menuType].isOpen
    );
  }

  isEnabled(item: MenuItem) {
    if (item.enabled === undefined) { return true; } else { return item.enabled; }
  }

  getClosedComment() {
    if (!this.isLoaded()) { return ''; }
    return this.appInfo.Context[this.menuType]
      ? this.appInfo.Context[this.menuType].closedComment
      : 'OFFLINE';
  }

  displayMenu() {
    this.noItemsToShow = false;
    this.shownMenuItems = this.menu;
    if (this.shownMenuItems.length <= 0) {
      this.noItemsToShow = true;
      return;
    }
    this.shownMenuItems.forEach((element) => {
      element.items.forEach((x) => {
        this.loadAndApplyImage(x);
      });
    });
    // Load the first images from the start
    const nrOfImagesToPreLoad = 6;
    for (let index = 0; index < nrOfImagesToPreLoad; index++) {
      const category = this.shownMenuItems[0];
      const element = category.items[index];
      if (element) { element.event.next('load_image'); }
    }
    this.shownCategoryName = this.categoryNames[0];
  }

  loadAndApplyImage(product) {
    product.event = new Subject<string>();
    const subscription = product.event.subscribe((x) => {
      // Product is in view so load its image;
      const image = new Image();
      image.src = product.ImageUrl;
      image.onload = (x) => {
        product.hdStyling = {
          'background-image': `url('${product.ImageUrl}')`,
          animation: 'hdproduct 0.3s',
          'animation-delay': '0.5s',
          'animation-fill-mode': 'forwards'
        };
      };
      subscription.unsubscribe();
      product.event.complete();
    });
  }
  getImageUrl(product) {
    return `url('${product.ImageUrl}')`;
  }



  changeColor(index, state) {
    if (!state) {
      this.hoverItem = index;
      this.itemIsHover = true;
    } else {
      this.hoverItem = null;
      this.itemIsHover = false;
    }
  }

  isInView(element) {
    if (element) {
      const height = this.platform.height();
      const width = this.platform.width();
      const bounding = element.getBoundingClientRect();
      if (
        Math.floor(bounding.top) >= 0 &&
        Math.floor(bounding.left) >= 0 &&
        Math.floor(bounding.right) <= width &&
        Math.floor(bounding.bottom) <= height
      ) {
        return true;
      } else {
        return false;
      }
    } return false;
  }

  isKindOfInView(element) {
    if (!element)
      return false;
    const height = this.platform.height();
    const width = this.platform.width();
    const bounding = element.getBoundingClientRect();
    if (
      Math.floor(bounding.top) >= -400 &&
      Math.floor(bounding.left) >= 0 &&
      Math.floor(bounding.right) <= width &&
      Math.floor(bounding.bottom) - 400 <= height
    ) {
      return true;
    } else {
      return false;
    }
  }

  slideTo(index) {
    console.dir(index);
    console.dir(`category_${index}`)
    const element = document.getElementById(`category_${index}`)
    const y = element.offsetTop;
    console.log("distans from top" + y);
    this.content.scrollToPoint(0, index == 0 ? y : y, 300);
    this.itemIsHover = false;
  }

  scrollIsHappening(ev) {
    this.scrolling();
    if (this.categoryNames && this.categoryHtmlElements) {
      this.categoryNames.forEach((x, i) => {
        if (this.isInView(this.categoryHtmlElements[i])) {
          this.slideIndex = i;
          this.shownCategoryName = x;
        }
      });
    }
    // this.offsetCartFromTopIfVisable(ev);
  }

  scrolling() {
    this.shownMenuItems.forEach((x) => {
      x.items.forEach((item, i) => {
        let element = this.itemElementsCache[item.key];
        if (!element) {
          element = document.getElementById(item.key);
          this.itemElementsCache[item.key] = element;
        }
        if (this.isKindOfInView(element)) {
          if (!item.event.isStopped) { item.event.next('load_image'); }
        }
      });
    });
  }

  // offsetCartFromTopIfVisable(ev) {
  //   if (this.cartElement) {
  //     if (!this.cartElement.hidden) {
  //       let marginTop = '' + (ev.detail.scrollTop + 60) + 'px';
  //       this.cartMargins = {
  //         'margin-left': '30px',
  //         'margin-top': marginTop
  //       };
  //     } else {
  //       console.log("Cart is hidden");
  //     }
  //   }
  // }

  // toggleSelectedDrinkItem($index) {
  //   this.toggleDrink.item = $index;
  //   setTimeout(() => {
  //     this.toggleDrink.item = -1;
  //   }, 0);
  // }

  // toggleSelectedFoodItem($index) {
  //   this.toggleFood.item = $index;
  //   setTimeout(() => {
  //     this.toggleFood.item = -1;
  //   }, 0);
  // }

  async showItemPopup(item: CartItem) {
    if (item.isUsingBuildABurger) {
      let steps = [];
      if (item.steps) {
        item.steps.forEach((step) => {
          if (item.options.find((x) => x.step === step.value)) {
            steps.push(
              new BuildStep(
                step.imageUrl,
                step.header,
                step.subHeader,
                step.value
              )
            );
          }
        });
      } else { steps = undefined; }

      const burgerFlow = new BurgerFlow(this.modalCtrl, steps);
      burgerFlow.start(
        item,
        (item) => {
          item = BurgerFunctions.sortOptions(item, [
            'type',
            'sideorder',
            'sauce',
            'toppings',
            'extras',
          ]);

          this.addToOrder(item);
        },
        () => { }
      );
    }
    // else {
    //   const modal = await this.modalCtrl.create({
    //     component: MenuItemDescriptionComponent,
    //     cssClass: 'modal-drawer',
    //     swipeToClose: true,
    //     componentProps: { item, isReadonly: !this.isOpen() },
    //     backdropDismiss: true,
    //     showBackdrop: false,
    //   });
    //   modal.present();
    //   const result = await modal.onDidDismiss();
    //   if (!result.data) { return; }
    //   this.addToOrder(result.data);
    // }
  }

  ifUserClickOnCart(item, event, i) {
    if ((item.isCombinedProduct === undefined || item.isCombinedProduct === false) && item.options != null && item.options.length > 0) {
      this.showDishInfo = !this.showDishInfo;
      this.itemsForDishInfo = item;
    }
  }

  addOrShowPopup(item, event, i) {
    event && event.preventDefault();
    this.showDishInfo = false;
    if ((item.isCombinedProduct === undefined || item.isCombinedProduct === false) && item.options != null && item.options.length > 0) {
      this.showDishInfo = true;
      this.itemsForDishInfo = item;
      this.showDishInfoModal(item);
      //this.showItemPopup(this.itemsForDishInfo);
    } else if (this.isOpen()) {
      this.addToOrder(item);
      //this.startAnimation(i);
    }
  }

  hasOptions(item) {
    return item.options != null && item.options.length > 0;
  }

  isCartEmpty() {
    return this.cartProvider.cart.sum <= 0;
  }

  isCartNotEmpty() {
    return this.cartProvider.cart.sum > 0;
  }

  async showDishInfoModal(item) {
    const modal = await this.modalCtrl.create({
      component: MenuItemDescriptionComponent,
      cssClass: 'modal-drawer',
      swipeToClose: true,
      componentProps: { item, isReadonly: !this.isOpen() },
      backdropDismiss: true,
      showBackdrop: false,
    });

    modal.present();
    const result = await modal.onDidDismiss();

    if (!result.data) { return; }
    console.dir(result);
    this.addToOrder(result.data);
  }

  valueToCart(event) {
    if (!event) {
      this.showDishInfo = false;
    } else {
      this.addToOrder(event.data);
      this.showDishInfo = false;
    }
  }

  removeItem(item, event, index) {
    event && event.preventDefault();
    this.cartProvider.removeFirstItemOfName(item.Name);
    this.logMemoryService.logEvent("customer_tab", { text: `Tog bort 1 x ${item.Name} från beställningen` });
    // Re render the cart component
    this.updateCartPageData = !this.updateCartPageData;
  }

  async gotoCart() {
    this.logMemoryService.screenName = this.orderCode;
    this.logMemoryService.logEvent("customer_tab", { text: `Skickade iväg beställning på nota ${this.appCode} från ${this.orderCode}` });
    this.updateCartPageData = !this.updateCartPageData;

    await this.payWithCustomerTab();
  }


  //**************** Only for Melodyclub ************** */

  async payWithCustomerTab() {
    this.storage.get("PUBQ_TAB_SETTINGS").then((x) => {
      let data = JSON.parse(x);

      let body = {
        appCode: '3215',
        orderCode: 'RUM1'
      }

      if (data) {
        body = data;
      }

      var customerTab: UserPaymentSource = {
        nickname: "customerTab",
        recurringReference: body.appCode,
        type: "customerTabKey",
      };

      this.orderFromCart = this.setUserForOrder(this.orderFromCart, this.user);
      this.orderProvider.orderWaitingForApproval = this.orderFromCart;

      this.orderProvider.paymentOfChoice = customerTab;
      this.orderProvider.setLastUsedTabKey(body.appCode);
      this.orderProvider.orderWaitingForApproval.orderCode = body.orderCode;

      this.forward();
    });
  }

  createOrder(event: CartItemsOutput): Order {
    if (!this.contextProvider.clientContext.appInfo.value) return;
    moment.locale('sv');
    const date = moment().unix();
    const order = new Order();
    order.version = '2';
    order.client = this.contextProvider.getClient();
    order.date = date;
    order.deliveryDate = event.selectedDate;
    order.coupon = event.coupon;
    order.customerComment = event.customerComment;
    order.momsRegNr = this.contextProvider.clientContext.appInfo.value.MomsRegNr;
    order.totalCost = event.totalCost;
    order.totalFoodItems = event.totalFoodItems;
    order.totalFoodCost = event.totalFoodCost;
    order.totalDrinksCost = event.totalDrinksCost;
    order.totalDrinkItems = event.totalDrinkItems;
    order.totalItems = event.totalItems;
    order.order = {
      food: this.cartProvider.getFoodItems(event.items),
      drinks: this.cartProvider.getDrinkItems(event.items),
    };
    return order;
  }

  async onPaymentInitiation(event?: CartItemsOutput) {
    console.log("Cart event -> orderFromCart");

    this.orderFromCart = this.createOrder(event);
  }

  setUserForOrder(order: Order, user: User): Order {
    try {
      order.userId = user.id;

      return order;
    } catch (error) {
      console.log(error);
    }
  }

  async forward() {
    console.log("At forward")
    console.dir(this.orderProvider.orderWaitingForApproval);
    console.dir(this.orderProvider.paymentOfChoice);
    console.dir(this.orderProvider.lastUsedCustomerTabKey);

    this.navCtrl.navigateForward([`/chargeCustomer/${this.client}/${this.city}/${this.routeName}`], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'preserve',
    });
  }

  /******************************************************* */

  goToActiveOrders() {
    this.navCtrl.navigateForward(`active-orders/${this.client}/${this.city}/${this.routeName}`);
  }



  async viewActiveTabs() {
    if (this.activeTabsCount <= 0) {
      let alert = await this.alertCtrl.create({
        header: this.languagePipe.transform("Du har ingen aktiv bordsnota", 'CART_NO_ACTIVE_TAB', this.languageProvider.currentLanguage),
        subHeader: this.languagePipe.transform("Gå till menyn och gör din första beställning", 'CART_GO_TO_MENY', this.languageProvider.currentLanguage),
        buttons: [
          {
            text: this.languagePipe.transform("Ok", 'CONFIRM', this.languageProvider.currentLanguage),
          },
        ],
      });
      alert.present();
      return;
    }
    this.navCtrl.navigateForward([`customerTabsList/${this.client}/${this.city}/${this.routeName}`], {
    });
  }

  async showRoomSettings() {
    this.storage.get("PUBQ_TAB_SETTINGS").then(async (x) => {
      let data = JSON.parse(x);

      let body = {
        appCode: '',
        orderCode: ''
      }

      if (data) {
        body = data;
      }

      const modal = await this.modalCtrl.create({
        component: TableSettings,
        cssClass: 'modal-drawer',
        swipeToClose: true,
        componentProps: { appCodeInput: body.appCode, orderCodeInput: body.orderCode },
        backdropDismiss: true,
        showBackdrop: false,
      });

      modal.present();
      const result = await modal.onDidDismiss();

      if (!result.data) { return; }

      this.setTabelNumberAndAppCode(result.data);
    });

  }

  async setTabelNumberAndAppCode(data) {

    let body = {
      appCode: '',
      orderCode: ''
    }

    if (data) {
      body = data;
    }

    this.appCode = body.appCode;
    this.orderCode = body.orderCode;

    await this.storage.set("PUBQ_TAB_SETTINGS", JSON.stringify(body));
  }

  scrollUp() {
    this.content.scrollByPoint(0, -1000, 200);
  }

  scrollDown() {
    this.content.scrollByPoint(0, 1000, 200);
  }

  async loginOrRegistration() {
    const modal = await this.modalCtrl.create({
      component: LoginNavComponent,
    });
    modal.present();
    const result = await modal.onDidDismiss();
    if (!result.data) { return; }
    this.user = result.data;
    this.userProfileName = this.user.firstName;
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.logInUser = !user.isAnonymous;
      }
    });
  }

  async showProfileUserInfo() {
    this.navCtrl.navigateForward(`account/${this.client}/${this.city}/${this.routeName}`);
  }

  startAnimation(index) {
    this.toggleItemIndex = index;
    setTimeout(() => {
      this.toggleItemIndex = 999;
    }, 700);
  }

  hasActiveOrders(user) {
    return user && user.activeOrders && typeof user.activeOrders === 'object' && Object.keys(user.activeOrders).length > 0;
  }

  async addToOrder(menuItem) {
    // Re render the cart component
    this.updateCartPageData = !this.updateCartPageData;

    this.cartProvider.fromTakeawayMenu = this.menuType === 'takeaway';
    const result = this.cartProvider.addItem(menuItem);
    if (result === 'DRINKS_CLOSED') {
      const alert = await this.alertCtrl.create({
        header: this.languagePipe.transform('Fel', 'ERROR', this.languageProvider.currentLanguage),
        message: this.languagePipe.transform('Det tas inte emot några dryckesbeställningar för tillfället', 'MENU_DRINKS_CLOSED', this.languageProvider.currentLanguage),
        buttons: [{ text: this.languagePipe.transform('OK', 'OK', this.languageProvider.currentLanguage) }],
      });
      alert.present();
    }
    if (result === 'CART_FULL') {
      const alert = await this.alertCtrl.create({
        header: this.languagePipe.transform('Du kan inte beställa mer på samma order', 'MENU_ORDER_FULL', this.languageProvider.currentLanguage),
        buttons: [{ text: this.languagePipe.transform('OK', 'OK', this.languageProvider.currentLanguage) }],
      });
      alert.present();
    }
    this.logMemoryService.screenName = this.orderCode;
    this.logMemoryService.logEvent("customer_tab", { text: `Lade till ${result} x ${menuItem.Name} på beställningen` });
  }

  goBack() {
    this.navCtrl.navigateBack(`unitselection/${this.client}/${this.city}`);
  }

}

export class Option {
  enabled: boolean;
  cost: number;
  text: string;
  type: string;
  selected: any; // boolean or Choice
  choices: Choice[];
  linkedProduct?: MenuItem;
  step?;
}

export class Choice {
  cost: number;
  text: string;
  enabled: boolean;
}

export class MenuItem {
  ImageUrl: string;
  Name: string;
  Description: string;
  DescriptionLong: string;
  Cost: number;
  NrOfItems: number;
  options: Option[];
  enabled: boolean;
  isUsingBuildABurger?: boolean;
  isCombinedProduct: boolean;
}
