import { Component, Input, ViewChild } from "@angular/core";
import { IonNav, ModalController } from '@ionic/angular';
import { MenuPage } from 'src/app/menu/menu';
import { NumpadComponent } from "src/app/numpad/numpad.component";
@Component({
  selector: 'table-settings',
  templateUrl: 'table-settings.html',
  styleUrls: ['table-settings.scss']
})
export class TableSettings {
  @Input() appCodeInput: string = "";
  @Input() orderCodeInput: string = "";
  appCode = '';
  orderCode = '';
  labels = ["RUM 1", "RUM 2", "RUM 3", "RUM 4", "RUM 5", "RUM 6", "RUM 7", "RUM 8", "RUM 9"];
  constructor(private modalCtrl: ModalController) {
  }

  ngOnInit() {
    this.appCode = this.appCodeInput;
    this.orderCode = this.orderCodeInput;
  }

  ngAfterViewInit() {

  }
  async openNumpad() {
    let modal = await this.modalCtrl.create({
      component: NumpadComponent,
      componentProps: { asModal: true, showModifierButtons: false, title: "Ange kod" }
    });
    modal.present();

    let result = await modal.onDidDismiss();
    if (result.role !== "confirm")
      return;

    this.appCode = result.data.number;
  }

  onSave() {
    this.modalCtrl.dismiss({ appCode: this.appCode, orderCode: this.orderCode });
  }
}