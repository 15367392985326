let appSettings = require("../../cordova-scripts/appsettings");
let clientSettings = require("../../cordova-scripts/clientsettings");
module.exports = {
    name: clientSettings.name,
    userOriginName: clientSettings.userOriginName,
    version: appSettings.version,
    dbRoute: clientSettings.dbRoute,
    customUrlProtocol: clientSettings.customUrlProtocol,
    hasCitySelection: clientSettings.hasCitySelection,
    database: clientSettings.isProduction ? appSettings.dbProd : appSettings.dbStaging,
    paymentProvider: clientSettings.paymentProvider,
    statusBarColorHex: clientSettings.statusBarColorHex,
    tabHomeTitle: clientSettings.tabHomeTitle,
    tabFoodTitle: clientSettings.tabFoodTitle,
    tabDrinksTitle: clientSettings.tabDrinksTitle,
    tabAccountTitle: clientSettings.tabAccountTitle,
    menuSegmentType: clientSettings.menuSegmentType,
    firebaseProject: clientSettings.firebaseProject,
    defaultLanguage: clientSettings.defaultLanguage
}
