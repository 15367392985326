import { Component } from "@angular/core";
import { OrderProvider, Order } from "../services/order/order";
import { PaymentProvider, TransactionResult } from "../services/payment/payment";
import { ActivatedRoute } from "@angular/router";
import {
  NavController,
  IonRouterOutlet,
  ToastController,
  AlertController,
  ModalController
} from "@ionic/angular";
import { UserProvider, User, UserPaymentSource } from "../services/user/user";
import { CartProvider } from "../services/cart/cart";
import { ContextProvider } from "../services/context/context";
import { Storage } from '@ionic/storage';
import { BonusProvider } from '../services/bonus/bonus';
import { StockModalComponent } from '../stock-balance/stock.component';
import { LanguageProvider } from '../services/language/language.provider';
import { LanguagePipe } from '../pipes/language.pipe';


import { PubqConfigProvider } from "../services/pubq-config/pubq-config";
import { EventsProvider } from "../services/events/events.provider";


@Component({
  selector: "page-charge-customer",
  styleUrls: ["chargeCustomer.component.scss"],
  templateUrl: "chargeCustomer.component.html",
})
export class ChargeCustomerComponent {
  payingForOrder = false;
  hideSpinner = false;
  title = this.languagePipe.transform("Ett ögonblick...", 'CHARGE_WAIT', this.languageProvider.currentLanguage);
  client?: string = null;
  city?: string = null;
  routeName?: string = null;

  constructor(
    private orderProvider: OrderProvider,
    private toastController: ToastController,
    private contextProvider: ContextProvider,
    private routerOutlet: IonRouterOutlet,
    private cartProvider: CartProvider,
    private userProvider: UserProvider,
    private paymentProvider: PaymentProvider,
    private activatedRoute: ActivatedRoute,
    private navCtrl: NavController,
    private alertController: AlertController,
    private storage: Storage,
    private bonusProvider: BonusProvider,
    private modalController: ModalController,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    private eventsProvider: EventsProvider,
    private pubqConfigProvider: PubqConfigProvider,
  ) { }
  async ngOnInit() {

    this.client = this.activatedRoute.snapshot.params.client;
    this.city = this.activatedRoute.snapshot.params.city;
    this.routeName = this.activatedRoute.snapshot.params.routeName;

    this.routerOutlet.swipeGesture = false;
    this.payingForOrder = true;
    let user = this.userProvider.currentUser.value;

    let paymentSource = this.orderProvider.paymentOfChoice;
    let order = this.orderProvider.orderWaitingForApproval;
    try {
      let result = await this.paymentProvider.chargeCustomerTab(
        user,
        paymentSource,
        order
      );
      if (result.error) {
        let modal = await this.modalController.create({
          component: StockModalComponent,
          componentProps: { routeName: this.contextProvider.client.routeName, data: result.missing, updatedCart: result.updatedCart },
          cssClass: 'stock-modal'
        });
        modal.present();
        await modal.onDidDismiss();
        this.backToMain();
      } else {
        this.resetOrderFlow();
        this.home();
        this.payingForOrder = false;
      }
    } catch (error) {
      alert("Beställningen kunde inte genomföras, kontrollera att det finns en nota med rumskoden " + paymentSource.recurringReference + ".");
      this.payingForOrder = false;
      this.backToMain();
    }


  }

  async handleReepayChargeResponse(x: any, user: any) {
    try {
      console.dir(x);
      if (x.error) {
        if (x.missing) {
          let modal = await this.modalController.create({
            component: StockModalComponent,
            componentProps: { routeName: this.contextProvider.client.routeName, data: x.missing, updatedCart: x.updatedCart },
            cssClass: 'stock-modal'
          });
          modal.present();
          await modal.onDidDismiss();
          this.backToCart();
        } else {
          // Get error code from responseText
          let errorCode = 'CHARGE_ERROR'
          if (x.error.responseText) {
            let parts = x.error.responseText.split(':')
            if (parts.length > 1) {
              errorCode = parts[1].trim();
            }
          }

          // Log event for credit card error  
          this.eventsProvider.logEvent("CREDIT_CARD_ERROR_" + errorCode, { user: user });

          if (errorCode === "SCA_REQUIRED") {
            console.log("Card needs to run 3D Secure handling,");
            console.log("reverts to paymat and automatic init 3D Secure payment.");
            // Needs to go back to payment.ts page and run the payWithReepayCardOnFile funktion
            this.backForAutomaticCardOnFilePayment();
          } else {
            this.showErrorPrompt(this.languagePipe.transform("Något gick fel med ditt köp. Vänligen kontakta restaurangen", errorCode, this.languageProvider.currentLanguage));
            this.back();
            this.payingForOrder = false;
          }
        }
      } else {
        this.resetOrderFlow();
        this.home();
        this.payingForOrder = false;
      }
    } catch (error) {
      this.backToCart();
    }
  }

  async handleChargeResponse(x: any) {
    try {
      console.log(x);
      if (x.error) {
        if (x.missing) {
          let modal = await this.modalController.create({
            component: StockModalComponent,
            componentProps: { routeName: this.contextProvider.client.routeName, data: x.missing, updatedCart: x.updatedCart },
            cssClass: 'stock-modal'
          });
          modal.present();
          await modal.onDidDismiss();
          this.backToCart();
        } else {
          this.showErrorPrompt(x.error.responseText);
          this.back();
          this.payingForOrder = false;
        }

      } else {
        this.resetOrderFlow();
        this.home();
        this.payingForOrder = false;
      }
    } catch (error) {
      this.backToCart();
    }

  }
  async chargePossibleSwishCustomerCached() {
    try {
      let body = await this.storage.get("swish");
      body = JSON.parse(body);

      return await this.paymentProvider.chargeSwishCustomerCachedOrder(body);
    } catch (err) {
      // alert(JSON.stringify(err));
    }
  }

  async chargeCachedVippsOrder() {

    let body = await this.storage.get("PUBQ_VIPPS");
    body = JSON.parse(body);
    console.log("Fetching Vipps order data");
    if (this.paymentProvider.config) {
      console.log("API not ready!");
    }

    return await this.paymentProvider.chargeVippsCachedOrder(body);

  }

  async chargeSwishCustomer(
    user: User,
    recurringDetailReference: UserPaymentSource,
    order: Order
  ) {
    const amount = order.totalCost;
    return await this.paymentProvider.chargeSwishCustomer(
      user,
      recurringDetailReference,
      amount,
      order
    );
  }
  async showErrorPrompt(errorMsg) {
    let prompt = await this.alertController.create({
      header: errorMsg,
      buttons: [
        {
          text: this.languagePipe.transform("OK", 'OK', this.languageProvider.currentLanguage),
          role: "confirm",
        },
      ],
    });
    prompt.present();
  }
  resetOrderFlow() {
    this.orderProvider.orderWaitingForApproval = null;
    this.orderProvider.paymentOfChoice = null;
    this.orderProvider.activatedOfferIds = [];
    this.cartProvider.empty();
  }
  home() {
    this.routerOutlet.swipeGesture = true;
    this.navCtrl.navigateForward([`/paymentConfirmation/${this.client}/${this.city}/${this.routeName}`], {
      relativeTo: this.activatedRoute,
    });
  }
  back() {
    this.routerOutlet.swipeGesture = true;
    this.navCtrl.navigateBack([`/cart/${this.client}/${this.city}/${this.routeName}`], {
      relativeTo: this.activatedRoute,
    });
  }
  backForAutomaticCardOnFilePayment() {
    this.routerOutlet.swipeGesture = true;
    this.navCtrl.navigateBack([`/cart/${this.client}/${this.city}/${this.routeName}`], {
      queryParams: { automaticCardOnFilePayment: true },
      relativeTo: this.activatedRoute
    });
  }
  backToCart() {
    console.log("BACK TO CART!!!!!!!!!");
    // this.routerOutlet.swipeGesture = true;
    // this.navCtrl.navigateBack([`/cart/${this.client}/${this.city}/${this.routeName}`], {
    //   relativeTo: this.activatedRoute,
    // });
  }

  backToMain() {
    this.routerOutlet.swipeGesture = true;
    this.navCtrl.navigateBack([`/main/${this.client}/${this.city}/${this.routeName}`], {
      relativeTo: this.activatedRoute,
    });
  }
}
