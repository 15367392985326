import { Component, ViewChild } from "@angular/core";
import { ModalController, IonSlides } from "@ionic/angular";
import { ContextProvider } from "../services/context/context";
import * as moment from "moment";
import { LanguageProvider } from '../services/language/language.provider';
import { LanguagePipe } from '../pipes/language.pipe';
@Component({
  selector: "app-weekly-menu",
  templateUrl: "weeklyMenu.component.html",
  styleUrls: ["weeklyMenu.component.scss"],
})
export class WeeklyMenuComponent {
  menu = { content: [] };
  selectedWeek = { number: 0 };
  @ViewChild("weekSlider", { static: false }) slider: IonSlides;
  sliderOpts = { speed: 300 };
  constructor(
    private modalController: ModalController,
    private contextProvider: ContextProvider,
    public languageProvider: LanguageProvider
  ) { }
  ngOnInit() {
    let currentWeekNumber = moment().week();
    this.menu = this.contextProvider.clientContext.appInfo.value.Context.weeklyMenu;
    this.menu.content = this.menu.content.filter((week) => {
      if (week.number < currentWeekNumber) return false;
      let allDaysHasText = true;
      week.days.forEach((day) => {
        if (!day.description) allDaysHasText = false;
      });
      return allDaysHasText;
    });
    this.menu.content.forEach((x) => {
      x.days.forEach((day) => {
        if (day.description)
          day.description = day.description.replace(/(\r\n|\n|\r)/gm, "<br>");
      });
    });
    this.selectedWeek = this.menu.content[0] ? this.menu.content[0] : { number: 0 };
  }
  slideDidLoad(ev) {
    console.log("laddade");
    this.slider.update();
  }
  ionViewDidEnter() {
    this.slider.update();
  }
  ngAfterViewInit() {
    this.slider.update();
    this.slider.updateAutoHeight();
  }
  async ionSlideDidChange(ev) {
    let index = await this.slider.getActiveIndex();
    this.selectedWeek = this.menu.content[index];
  }
  select(week) {
    this.selectedWeek = week;
    let index = this.menu.content.findIndex((x) => x.number === week.number);
    this.slider.slideTo(index);
  }
  dismiss() {
    this.modalController.dismiss();
  }
}
