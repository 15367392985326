import { ChangeDetectionStrategy, Component, Input, SimpleChanges } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ContextProvider, AppInfo } from '../services/context/context';
import { PaymentProvider, CouponGoblin } from '../services/payment/payment';
import { CartProvider } from '../services/cart/cart';
import { MenuItem } from '../models';
import { OrderProvider } from '../services/order/order';
import { TimePickerComponent } from './time-picker/timePicker.component';
import { LanguageProvider } from '../services/language/language.provider';
import { LanguagePipe } from '../pipes/language.pipe';

@Component({
  selector: 'cart-items',
  templateUrl: 'cart-items.html',
  styleUrls: ['cart-items.scss'],
  providers: [CartItemsComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartItemsComponent {
  @Output() initiatePaymentProcess = new EventEmitter();
  @Input() updateCartPageStatus: boolean;
  items: CartItem[];
  totalCost;
  couponCode: string;
  closingTime: string;
  couponCodeIconStyle = {
    icon: 'barcode',
    color: 'grey'
  };
  couponCodeEnabled = true;
  customerCommentEnabled = true;
  selectedThroughTimePicker = false;
  validCoupon;
  customerComment;
  showAlcoholNotDiscountedNote = false;
  yearValues = [new Date().getFullYear(), new Date().getFullYear() + 1];
  monthShortNames = [];
  selectedDeliveryDate: { label: string, date: any } = { label: 'NONE', date: undefined };
  showDeliveryDate = true;
  showItemsComment = false;
  minimumBonusAmount = 0;
  constructor(public cartProvider: CartProvider,
    private modalController: ModalController,
    private orderProvider: OrderProvider,
    private contextProvider: ContextProvider,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private paymentProvider: PaymentProvider,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe
  ) {

    this.items = this.cartProvider.cart.items;
    moment.locale('sv-se');
    this.monthShortNames = moment.monthsShort();
    this.selectedDeliveryDate = this.setDeliveryDateNow();
    this.contextProvider.clientContext.appInfo.subscribe((data: AppInfo) => {
      if (!data) return;
      if (!this.couponCode && data.Context.useDefaultCoupon) {
        this.couponCode = data.Context.defaultCouponCode;
        this.validateCouponCode();
      }
      if (data.Context.deliveryDate) {
        this.showDeliveryDate = data.Context.deliveryDate.enabled;
      }
      if (data.Context.couponCode) {
        this.couponCodeEnabled = data.Context.couponCode.enabled;
      }
      if (typeof (data.Context.showItemsComment) !== 'undefined') {
        this.showItemsComment = data.Context.showItemsComment;
      }
      if (typeof (data.Context.showCustomerComment) !== 'undefined') {
        this.customerCommentEnabled = data.Context.showCustomerComment;
      }
    });
  }

  async selectDeliveryDate() {
    const modal = await this.modalController.create({
      component: TimePickerComponent,
      componentProps: { preSelectedMoment: this.selectedThroughTimePicker ? this.selectedDeliveryDate.date : null },
      backdropDismiss: false,
      cssClass: 'action-sheet-modal'
    });
    modal.present();
    const result = await modal.onDidDismiss();
    if (result.role === 'backdrop') {
      return;
    }
    if (result.data) {
      this.selectedDeliveryDate = { label: result.data.moment.format('D MMM kk:mm'), date: result.data.moment };
      this.selectedThroughTimePicker = true;
    } else if (!result.data) {
      this.selectedDeliveryDate = this.setDeliveryDateNow();
      this.selectedThroughTimePicker = false;
    }

  }

  setDeliveryDateNow() {
    return {
      label: this.languagePipe.transform(
        'Så hurtigt som muligt',
        'CART_SERVE_NOW',
        this.languageProvider.currentLanguage), date: moment()
    };
  }

  increaseItemCount(item: MenuItem, event) {
    event.stopPropagation();
    this.cartProvider.addItem(item);
  }

  commentColor(comment: string, itemSlide) {
    return comment ? 'primary' : 'dark';
  }

  getTotalItemCost(item: CartItem) {
    return this.orderProvider.getTotalCostOfItemExclDiscount(item);
  }

  getTotalCost() {
    let cost = 0;
    if (this.validCoupon) {
      let sum = this.cartProvider.cart.sum;
      const itemsWithAlcohol = this.cartProvider.cart.items.filter(x => x.type && x.type.containsAlcohol);

      if (itemsWithAlcohol.length > 0) {
        this.showAlcoholNotDiscountedNote = true;
        const itemsWithAlcoholSum = itemsWithAlcohol.map(x => x.Cost * x.NrOfItems).reduce((agg, x) => agg + x);
        sum = this.cartProvider.cart.sum - itemsWithAlcoholSum;
        cost = CouponGoblin.valueWithCouponDeducted(sum, this.validCoupon.discountPercent);
        cost += itemsWithAlcoholSum;

      } else {
        this.showAlcoholNotDiscountedNote = false;
        cost = CouponGoblin.valueWithCouponDeducted(sum, this.validCoupon.discountPercent);
      }
    } else {
      cost = this.cartProvider.cart.sum;
    }
    return parseFloat(cost.toFixed(2));
  }

  async validateCouponCode() {
    this.couponCode = this.couponCode.toUpperCase();
    const clientToValidateCouponAgainst = this.contextProvider.getClient().routeName;
    const loader = await this.loadingCtrl.create({
      message: this.languagePipe.transform(
        'Lägger på rabattkod',
        'CART_ITEMS_APPLYING_OFFER',
        this.languageProvider.currentLanguage), spinner: 'circular'
    });
    loader.present();

    this.paymentProvider.validateCouponCode(this.couponCode, clientToValidateCouponAgainst).subscribe(data => {
      this.validCoupon = data;
      loader.dismiss();
      this.couponCodeIconStyle.icon = 'checkbox';
      this.couponCodeIconStyle.color = 'green';
    }, error => {
      this.showCouponError();
      loader.dismiss();
      this.showOkAlert(this.languagePipe.transform(
        'Rabattkoden är inte giltig',
        'CART_ITEM_OFFER_NOT_VALID',
        this.languageProvider.currentLanguage));
    });
  }

  private showCouponError() {
    this.validCoupon = null;
    this.couponCodeIconStyle.icon = 'alert';
    this.couponCodeIconStyle.color = 'red';
  }

  resetCouponIconOnEmpty() {
    if (this.validCoupon == null && this.couponCode.length === 0) {
      this.couponCodeIconStyle.icon = 'barcode';
      this.couponCodeIconStyle.color = 'grey';
    }
  }

  private async showOkAlert(text: string) {
    const alert = await this.alertCtrl.create({
      header: text,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
          }
        }
      ]
    });
    alert.present();
  }

  async showItemCommentPopup(item: CartItem, event) {
    event.stopPropagation();
    const prompt = await this.alertCtrl.create({
      header: item.Name,
      subHeader: this.languagePipe.transform('Kommentar', 'COMMENT', this.languageProvider.currentLanguage),
      message: this.languagePipe.transform('T.ex \'ingen koriander\'', 'CART_ITEM_COMMENT_EXAMPLE', this.languageProvider.currentLanguage),
      inputs: [
        {
          name: 'comment',
          value: item.Comment,
          placeholder: this.languagePipe.transform('Skriv din kommentar här', 'CART_ITEM_COMMENT', this.languageProvider.currentLanguage),
        } as any,
      ],
      buttons: [
        {
          text: this.languagePipe.transform('Avbryt', 'CANCEL', this.languageProvider.currentLanguage),
          handler: data => {
          }
        },
        {
          text: this.languagePipe.transform('Ok', 'CONFIRM', this.languageProvider.currentLanguage),
          handler: data => {
            item.Comment = data.comment.substring(0, 100);
          },
          cssClass: 'alert-button-default'
        }
      ]
    });
    prompt.present();
  }

  getDiscountAmount() {
    return this.cartProvider.cart.sumDrinks + this.cartProvider.cart.sumFood - this.getTotalCost();
  }

  async removeCoupon() {
    const alert = await this.alertCtrl.create({
      message: this.languagePipe.transform('Ta bort koden?', 'CART_ITEM_REMOVE_CODE', this.languageProvider.currentLanguage),
      buttons: [{
        text: this.languagePipe.transform('Avbryt', 'CANCEL', this.languageProvider.currentLanguage),
        role: 'cancel'
      },
      {
        text: this.languagePipe.transform('Ok', 'CONFIRM', this.languageProvider.currentLanguage),
        role: 'confirm'
      }
      ]
    });
    alert.present();
    const result = await alert.onDidDismiss();
    if (result.role === 'confirm') {
      this.validCoupon = null;
      this.couponCode = '';
      this.couponCodeIconStyle.icon = 'barcode';
      this.couponCodeIconStyle.color = 'grey';
    }
  }


  // async editItem(item) {
  //   const modal = await this.modalController.create({
  //     component: EditItemComponent,
  //     componentProps: { item },
  //     cssClass: 'action-sheet-modal',
  //     swipeToClose: true
  //   });
  //   modal.present();
  //
  //   const result = await modal.onDidDismiss();
  //   const change = result.data.NrOfItems - item.NrOfItems;
  //   if (result.role === 'confirm') {
  //     if (change > 0) {
  //       for (let i = 0; i < change; i++) {
  //         this.cartProvider.addItem(item);
  //       }
  //     } else if (change < 0) {
  //       for (let i = change; i < 0; i++) {
  //         this.cartProvider.removeItem(item);
  //       }
  //     }
  //   }
  //   if (result.role === 'remove') {
  //     for (let i = change; i < 0; i++) {
  //       this.cartProvider.removeItem(item);
  //     }
  //   }
  // }
  pay() {
    let deliveryDateTimestamp = null;
    // Delivery date is in the future
    if (this.selectedDeliveryDate.date.diff(moment()) > 0) {
      deliveryDateTimestamp = this.selectedDeliveryDate.date.unix();
    }
    const output: CartItemsOutput = {
      coupon: this.validCoupon,
      customerComment: this.customerComment,
      selectedDate: deliveryDateTimestamp,
      items: this.items,
      totalCost: this.getTotalCost(),
      totalItems: this.cartProvider.cart.totalItems,
      totalDrinkItems: this.cartProvider.cart.totalDrinkItems,
      totalFoodItems: this.cartProvider.cart.totalFoodItems,
      totalDrinksCost: this.cartProvider.cart.sumDrinks,
      totalFoodCost: this.cartProvider.cart.sumFood
    };
    this.initiatePaymentProcess.emit(output);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.updateCartPageStatus) {
      this.pay();
    }
  }

  increase(item) {
    this.cartProvider.addItem(item);
    this.pay();
  }

  async decrease(item) {
    this.cartProvider.removeItem(item);
    this.pay();
  }

}
export class CartItemsOutput {
  items: CartItem[];
  customerComment: string;
  coupon: Coupon;
  totalItems: number;
  totalCost: number;
  totalFoodCost: number;
  totalDrinksCost: number;
  totalFoodItems: number;
  totalDrinkItems: number;
  selectedDate: number;
}

export class Coupon {
  code: string;
  discountPercent: number;
}
export class CartItem extends MenuItem {
  Comment: number;
  completed: string;
  cartIndex: number;
  NameInternal: any;
  steps?: any;
  discountPercent: number;
  discountValue: number;
  totalPrice: number;
  appliedOffer: any;
}

export class TabCartItem extends CartItem {
  paid: boolean;
}
