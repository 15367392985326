import {Component, SimpleChanges, ViewChild} from '@angular/core';
import {AlertController, IonContent, ModalController, NavController} from '@ionic/angular';

import { Subscription } from 'rxjs';
import { LanguagePipe } from '../pipes/language.pipe';
import { LanguageProvider } from '../services/language/language.provider';
import { ActiveOrder } from '../services/order/order';
import {User, UserProvider} from '../services/user/user';
import {LoginNavComponent} from '../account/login-nav.component';
import * as firebase from 'firebase';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'active-orders',
  templateUrl: 'active-orders.html',
  styleUrls: ['active-orders.scss']
})
export class ActiveOrdersComponent {
  @ViewChild('activeOrderContent') content: IonContent;
  orders: ActiveOrder[];
  expandOrders = false;
  ordersSub: Subscription;
  logInUser = false;
  user: User;
  fromUnitSelectionPage = false;
  client?: string = null;
  city?: string = null;
  routeName?: string = null;
  constructor(
    private userProvider: UserProvider,
    public languageProvider: LanguageProvider,
    public navCtrl: NavController,
    private alertCtrl: AlertController,
    private languagePipe: LanguagePipe,
    public modalCtrl: ModalController,
    public activatedRoute: ActivatedRoute,
  ) {
    this.client = this.activatedRoute.snapshot.params.client;
    this.city = this.activatedRoute.snapshot.params.city;
    this.routeName = this.activatedRoute.snapshot.params.routeName;
  }

  ngOnInit() {
    this.ordersSub = this.userProvider.activeOrders.subscribe((x: ActiveOrder[]) => {
      if (!x) {
        this.orders = [];
        return;
      }
      this.expandOrders = x.length < 3;
      this.orders = x;
      if(this.orders.length == 0)
        this.goBackNoActiveOrders();
    });
    const user = firebase.auth().currentUser;

    this.activatedRoute.queryParams.subscribe((res) => {
      if (res.fromUnitSelection) {
        this.fromUnitSelectionPage = res.fromUnitSelection;
      }

      if (user.isAnonymous && res.showLoginAdvice) {
        this.offerAnonUserToRegister();
      }
    });
  }
  ngOnDestroy() {
    this.ordersSub.unsubscribe();
  }

  goBackNoActiveOrders(){
    setTimeout(()=>{
      this.goBack();
    }, 1200);
  }

  goBack() {
    // if (this.fromUnitSelectionPage) {
    //   this.navCtrl.navigateBack('unitselection/all');
    // } else {
      this.navCtrl.navigateBack(`main/${this.client}/${this.city}/${this.routeName}`);
    // }
  }

  async offerAnonUserToRegister() {
    const confirm = await this.alertCtrl.create({
      header: this.languagePipe.transform('Logga in!', 'OFFER_TO_LOGIN_TITLE', this.languageProvider.currentLanguage),
      subHeader: this.languagePipe.transform('Logga in för att se dina ordrar och ta del av erbjudanden.', 'OFFER_TO_LOGIN_SUBHEADER', this.languageProvider.currentLanguage),
      buttons: [
        {
          text: this.languagePipe.transform('Nej', 'NO', this.languageProvider.currentLanguage),
          handler: () => {
            return null;
          }
        },
        {
          text: this.languagePipe.transform('Ja', 'YES', this.languageProvider.currentLanguage),
          handler: async () => {
            const modal = await this.modalCtrl.create({
              component: LoginNavComponent,
            });
            modal.present();
            const result = await modal.onDidDismiss();
            if (!result.data) {
              return;
            }
            this.user = result.data;
            firebase.auth().onAuthStateChanged(user => {
              if (user) {
                this.logInUser = !user.isAnonymous;
              }
            });
          }
        }
      ],
      backdropDismiss: false
    });
    await confirm.present();
  }

}
