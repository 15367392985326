import { Component, Input } from '@angular/core';
import { UserCreate, UserProvider, User } from 'src/app/services/user/user';
import { NavController, ModalController, LoadingController, AlertController, IonNav } from '@ionic/angular';
import { LoginCompleteComponent } from './login-complete.component';
import { LanguageProvider } from 'src/app/services/language/language.provider';
import { LanguagePipe } from 'src/app/pipes/language.pipe';
import * as firebase from 'firebase';
@Component({
    templateUrl: 'registeremail.html',
    styleUrls: ['login.scss'],
    selector: 'page-register-email'
})
export class RegisterEmail {
    user: User;
    userToCreate = new UserCreate();
    @Input() nav: IonNav;
    constructor(
        public modalCtrl: ModalController,
        public loadingCtrl: LoadingController,
        public userProvider: UserProvider,
        public alertCtrl: AlertController,
        public languageProvider: LanguageProvider,
        private languagePipe: LanguagePipe
    ) {
        this.user = this.userProvider.currentUser.value;
    }
    async registerEmail() {
        const loader = await this.loadingCtrl.create({ duration: 8000, spinner: 'circular' });
        loader.present();
        this.userProvider
            .updateAnonymousAccount(this.userToCreate)
            .then(data => {
                loader.dismiss();
                const userUid = firebase.auth().currentUser.uid;
                this.nav.push(LoginCompleteComponent, { nav: this.nav, userId: userUid, email: data.user.email });
            })
            .catch(async error => {
                let message = '';
                if (error.code === 'auth/weak-password') {
                    message = this.languagePipe.transform('Ditt lösenord måste innehålla minst 6 tecken',
                      'LOGIN_PW_6', this.languageProvider.currentLanguage);
                }
                if (error.code === 'auth/invalid-email') {
                    message = this.languagePipe.transform('Din email verkar vara felformaterad. Dubbekolla och försök igen',
                      'LOGIN_EMAIL_FORMAT', this.languageProvider.currentLanguage);
                }
                if (error.code === 'auth/email-already-in-use') {
                    message = this.languagePipe.transform('Denna email används redan',
                      'LOGIN_EMAIL_IN_USE', this.languageProvider.currentLanguage);
                }

                const alert = await this.alertCtrl.create({
                    header: '',
                    subHeader: message,
                    buttons: [
                        {
                            text: this.languagePipe.transform('OK', 'OK', this.languageProvider.currentLanguage),
                            role: 'cancel'

                        }
                    ]
                });
                alert.present();
                loader.dismiss();
            });
    }
    dismiss() {
        this.nav.pop();
    }
}
