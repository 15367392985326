import { HttpClient } from "@angular/common/http";
import { 
    LoadingController,
    ModalController,
    Platform
  } from "@ionic/angular";
import { PubqConfig } from "../../pubq-config/pubq-config";
import { AddCardComponent } from "../../../payment/add-card/addCard.component"
export class ReepayGateway {

    constructor(
        private http: HttpClient, 
        private config: PubqConfig, 
        private merchantConfig: any, 
        private loadingController: LoadingController,
        private modalCtrl: ModalController,
        ) {

    }

    ///
    /// Uses Reepay Javascript SDK for embedded checkout
    ///
    pay_embedded(options: ReepayPaymentOptions): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let loading = await this.loadingController.create({
                spinner: "bubbles"
            });
            loading.present();
            
            try {
                let response = await this.createChargeSession(options);
                loading.dismiss();

                if (options.order.totalCost === 0) {
                    resolve(response);
                    return;
                }

                let id = response.id;
                
                // Show modal
                let modal = await this.modalCtrl.create({
                    component: AddCardComponent,
                    componentProps: {sessionId: id},
                    cssClass: "modal-drawer-small",
                    swipeToClose: true,
                    backdropDismiss: true,
                });
                modal.present();

                let result = await modal.onDidDismiss();

                if (result.role === "confirm") {
                    resolve(result.data);
                }

                reject(null);
            } catch (error) {
                loading.dismiss();
                console.log("Error: " + JSON.stringify(error))
                alert(JSON.stringify(error));
            }
        });
    }

    ///
    /// Create session id in backend to be used with checkout flows
    ///
    async createChargeSession(options: any){
    
        let url = `${this.config.ApiUrl}/payments/v1/createCheckoutSession`;
        let body = {
            routeName: options.routeName,
            user: {
                id: options.userId,
                firstName: options.firstName,
                lastName: options.lastName,
            },
            order: options.order,
            orderText: options.orderText,
            cardOnFile: options.cardOnFile,
            locale: options.locale
        };
        
        return this.http.post<any>(url, body).toPromise();
    }
}

export interface ReepayEventData{
    id: string;           // The current session id
    invoice: string;      // Invoice/charge handle
    customer: string;     // Customer handle
    subscription: string;     // Subscription handle
    payment_method: string;   // Payment method if a new one is created
    error: string;        // The error code
  }

export interface ReepayPaymentOptions {
    userId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    order: any;
    tipAmount?: number;
    routeName: string;
    cardOnFile: string;
    orderText: string;
    locale?: string;
}