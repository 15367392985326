
import { Component, ViewChild, Input } from '@angular/core';
// import { FirebaseX } from '@ionic-native/firebase-x/ngx';

import { VerifyPhonePage } from './verifyPhone';

import {
  NavController,
  NavParams,
  Platform,
  PopoverController,
  LoadingController,
  AlertController,
  ModalController,
  IonNav } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { LanguageProvider } from 'src/app/services/language/language.provider';
import { LanguagePipe } from 'src/app/pipes/language.pipe';

@Component({
  selector: 'page-phone-form',
  templateUrl: 'phoneForm.html',
  styleUrls: ['phoneForm.scss']
})
export class PhoneFormPage {
  @ViewChild('phonePrefixField') phonePrefixField;
  @ViewChild('phoneField') phoneField;
  phonePrefix = '+46';
  flag = `assets/icons/se.svg`;
  phoneNumber = '';
  @Input() callback;
  @Input() nav: IonNav;
  constructor(
    private navCtrl: NavController,
    // private firebaseX: FirebaseX,
    private platform: Platform,
    private popoverCtrl: PopoverController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private storage: Storage,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe

  ) {

  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.selectPhoneInput();
    }, 300);
  }
  back() {
    this.nav.pop();
  }
  async verifyPhoneNumber() {
    let phone = '';
    if (this.phoneNumber.startsWith('0')) {
      phone = this.phoneNumber.replace(/^0+/, '');
    } else {
      phone = this.phoneNumber;
    }
    const fullPhone = this.phonePrefix + phone;
    const alertPopup = await this.alertCtrl.create({
      message: this.languagePipe.transform(`Skicka sms till ${fullPhone}?`, 'LOGIN_SEND_SMS', this.languageProvider.currentLanguage, [fullPhone]),
      backdropDismiss: false,
      buttons: [{ text: this.languagePipe.transform('Nej', 'NO', this.languageProvider.currentLanguage), role: 'cancel' }, { text: this.languagePipe.transform('Ja tack!', 'LOGIN_SEND_SMS', this.languageProvider.currentLanguage), role: 'confirm' }]
    });
    alertPopup.present();
    const result = await alertPopup.onDidDismiss();
    if (result.role !== 'confirm') {
      return;
    }

    const loading = await this.loadingCtrl.create({
      spinner: 'circular',
      duration: 20000
    });
    loading.present();
    const isAndroid = this.platform.is('android');
    // let credential;
    try {
      // credential = await this.firebaseX.verifyPhoneNumber(fullPhone, 60);
      // await this.storage.set('phone_auth', { isAndroid, credential });
      loading.dismiss();
    } catch (error) {
      alert(this.languagePipe.transform(`Oj något gick fel. Vänligen testa ett annat sätt att logga in.\n ${JSON.stringify(error)}`, 'LOGIN_ERROR', this.languageProvider.currentLanguage, [JSON.stringify(error)]));
      return;
    }

    // this.nav.push(
    //   VerifyPhonePage, { isAndroid, credential, callback: this.callback, nav: this.nav });

  }

  selectPhoneInput() {
    this.phoneField.setFocus();
  }
  async presentPopover(myEvent) {
    const popover = await this.popoverCtrl.create({ component: PopoverPage, event: myEvent, translucent: true });
    popover.present();
    const result = await popover.onDidDismiss();
    const data = result.data;
    this.phonePrefix = data ? data.prefix : this.phonePrefix;
    this.flag = data ? `assets/icons/${data.flag}.svg` : this.flag;
    setTimeout(() => {
      this.selectPhoneInput();
    }, 500);

  }
  select(event) {
    event.target.children[0].select();
  }
}
@Component({
  template: `
    <ion-list lines="none">
    <ion-item (click)="close('se')" detail="false"><ion-avatar slot="start"><img src="assets/icons/se.svg"> </ion-avatar><ion-label>SE ({{se.prefix}})</ion-label>  </ion-item>
    <ion-item (click)="close('dk')" detail="false"><ion-avatar slot="start"><img src="assets/icons/dk.svg"> </ion-avatar><ion-label>DK ({{dk.prefix}})</ion-label>  </ion-item>
    <ion-item (click)="close('no')" detail="false"><ion-avatar slot="start"><img src="assets/icons/no.svg"> </ion-avatar><ion-label>NO ({{no.prefix}})</ion-label>  </ion-item>
    <ion-item (click)="close('fi')" detail="false"><ion-avatar slot="start"><img src="assets/icons/fi.svg"> </ion-avatar><ion-label>FI ({{fi.prefix}})</ion-label>  </ion-item>
    <ion-item (click)="close('de')" detail="false"><ion-avatar slot="start"><img src="assets/icons/de.svg"> </ion-avatar><ion-label>DE ({{de.prefix}})</ion-label>  </ion-item>
    </ion-list>
  `
})
export class PopoverPage {
  se = { prefix: '+46', flag: 'se' };
  dk = { prefix: '+45', flag: 'dk' };
  no = { prefix: '+47', flag: 'no' };
  fi = { prefix: '+358', flag: 'fi' };
  de = { prefix: '+49', flag: 'de' };
  constructor(private popoverCtrl: PopoverController) {

  }

  close(countryCode) {
    this.popoverCtrl.dismiss(this[countryCode]);
  }
}
