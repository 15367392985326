import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { BonusProvider } from '../services/bonus/bonus';
import { ModalController, ToastController, IonSlides, IonRouterOutlet } from '@ionic/angular';
import { OrderProvider } from '../services/order/order';
import { Subscription } from 'rxjs';

import { OfferDetailsComponent } from '../cart/offer-details-component/offerDetails.component';
import { LanguageProvider } from '../services/language/language.provider';
import { LanguagePipe } from '../pipes/language.pipe';

@Component({
  selector: 'app-offers',
  templateUrl: 'offers.component.html',
  styleUrls: ['offers.component.scss'],
})
export class OffersComponent {
  globalOffers: any[];
  subscriptions = new Subscription();
  @Input() asModal = false;
  @Input() asHtml = false;
  @Input() tableNumber: number = null;
  @Output() onOfferActivated = new EventEmitter<any>();
  @Output() onOfferDeactivated = new EventEmitter<any>();
  @ViewChild('offersSlider', { static: false }) slider: IonSlides;
  sliderOpts = { slidesPerView: 2, spaceBetween: 0 };
  constructor(
    private bonusProvider: BonusProvider,
    private modalController: ModalController,
    private orderProvider: OrderProvider,
    private toastController: ToastController,
    private routerOutlet: IonRouterOutlet,
    public languageProvider: LanguageProvider,
  ) { }
  async ngOnInit() {
    const sub = this.bonusProvider.globalOffers.subscribe(x => {
      this.globalOffers = [];
      if (this.tableNumber != null) {
        if (this.tableNumber < 1) {
          x.forEach((offer) => {
            if (offer.deliveryTypes.filter(d => d.id === this.tableNumber).length > 0) { this.globalOffers.push(offer); }
          });
        } else {
          x.forEach((offer) => {
            if (offer.deliveryTypes.filter(d => d.id === 1).length > 0) { this.globalOffers.push(offer); }
          });
        }
      } else {
        this.globalOffers = x.sort((x, y) => {
          return ((this.isActivated(x) ? 0 : 1) - (this.isActivated(y) ? 0 : 1));
        });
      }

    });
    this.subscriptions.add(sub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loaded(aa) {
    aa.loaded = true;
  }

  async activateOffer(offer) {
    // this.taptic.impact({ style: 'heavy' });
    // if (!this.isActivated(offer)) {
    //   this.orderProvider.activateOffer(offer);
    //   const toast = await this.toastController.create({
    //     duration: 2000,
    //     header: this.languagePipe.transform('Aktiverade', 'OFFERS_ACTIVATED', this.languageProvider.currentLanguage , [offer.title]),
    //     position: 'top',
    //     cssClass: 'offer-toast'
    //   });
    //   toast.present();
    //   this.onOfferActivated.next(offer);
    // } else {
    //   this.orderProvider.deActivateOffer(offer);
    //   this.onOfferDeactivated.next(offer);
    // }
  }

  async deactivateAllOffers() {
    this.globalOffers.forEach(offer => {
      if (this.isActivated(offer)) {
        this.orderProvider.deActivateOffer(offer);
        this.onOfferDeactivated.next(offer);
      }
    });
  }

  async showOfferInfo(offer) {
    const modal = await this.modalController.create({
      component: OfferDetailsComponent,
      componentProps: { offer },
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl
    });
    modal.present();
  }


  isActivated(offer) {
    return this.orderProvider.isOfferActivated(offer.id);
  }

  dismiss() {
    this.modalController.dismiss();
  }

  slideDidLoad(ev) {
    this.slider.update();
  }

  ionViewDidEnter() {
    if (this.asHtml) {
      this.slider.update();
    }
  }

  ngAfterViewInit() {
    if (this.asHtml) {
      this.slider.update();
      this.slider.updateAutoHeight();
    }
  }

  async ionSlideDidChange(ev) {
  }
}
