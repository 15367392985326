import { Component, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PubqConfigProvider } from 'src/app/services/pubq-config/pubq-config';
import { NavController, NavParams } from '@ionic/angular';
import { UserProvider, User } from 'src/app/services/user/user';
import { ContextProvider } from 'src/app/services/context/context';
import * as moment from "moment";
import { ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import { LanguageProvider } from 'src/app/services/language/language.provider';
import { LanguagePipe } from 'src/app/pipes/language.pipe';


@Component({
  selector: 'page-book-slot',
  templateUrl: 'book-slot.html',
  styleUrls: ["book-slot.scss"]
})
export class BookSlotPage {
  user: User = null;
  userSubscription: Subscription;
  routeName: string;
  loading = false;
  timeSlot: moment.Moment;
  seats = 0;
  title = "";
  subTitle = "";
  name = "";
  phoneNumber = "";
  phoneNumberPrefix = "+46";
  comment = "";

  @ViewChild('nameField') nameField;
  @ViewChild('phonePrefixField') phonePrefixField;
  @ViewChild('phoneField') phoneField;
  @ViewChild('commentField') commentField;
  @ViewChild('submitBtn') submitBtn;
  constructor(
    private http: HttpClient,
    private pubqConfig: PubqConfigProvider,
    public navCtrl: NavController,
    private activatedRoute: ActivatedRoute,
    public userProvider: UserProvider,
    public contextProvider: ContextProvider,
    public languageProvider:LanguageProvider
  ) {
    moment.locale("sv-se");
    this.activatedRoute.queryParams.subscribe(x => {
      this.routeName = this.contextProvider.client.routeName;
      this.timeSlot = moment.unix(parseInt(x.timeSlot));
      this.seats = x.seats;
      this.title = this.timeSlot.local().format('dddd, D MMMM YYYY').toUpperCase();
      this.subTitle = this.timeSlot.local().format("kk:mm");
    })

  }
  goBack() {
    this.navCtrl.pop();
  }
  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
  ngAfterViewInit() {
    this.userSubscription = this.userProvider.currentUser.subscribe(x => {
      if (!x)
        return;
      this.name = `${x.firstName} ${x.lastName}`;
      this.user = x;
    });
    //something steals focus after viewinit so timeout here
    setTimeout(() => {
      if (this.name)
        this.phoneField.setFocus();
      else
        this.nameField.setFocus();
    }, 700);
  }
  formValid() {
    return this.phoneNumber && this.phoneNumberPrefix && this.name;
  }
  select(event) {
    event.target.children[0].select();
  }
  selectNextField($event: KeyboardEvent, fieldName) {
    if ($event.keyCode !== 13 || $event.key !== "Enter")
      return;
    if (this[fieldName].setFocus) {
      this[fieldName].setFocus();
      return;
    }
    // this[fieldName]._elementRef.nativeElement.select();
    document.getElementById("submitBtn").focus();
  }


  async bookTimeSlot() {
    if (!this.formValid())
      return;
    let url = `${this.pubqConfig.config.value.primeApiUrl}/booktimeslot`;
    let firstName = this.name.split(" ")[0];
    let lastName = this.name.split(" ")[1] ? this.name.split(" ")[1] : " ";
    let data = {
      unitRouteName: this.routeName,
      seats: this.seats,
      firstName,
      lastName,
      phonePrefix: this.phoneNumberPrefix,
      phoneNumber: this.phoneNumber,
      comment: this.comment,
      timeslotUTC: this.timeSlot.utc().unix()
    };
    this.loading = true;
    this.http.post<any>(url, data).subscribe(async (x) => {
      this.bookSlotCallback(x);
    }, (error) => {
      alert(JSON.stringify(error));
    });
  }
  private async bookSlotCallback(params) {
    let bookingId = params.data.bookingId;
    if (this.user)
      await this.userProvider.setUserTableBooking(this.user, this.routeName, bookingId);
    else {
      await this.userProvider.setLocalBooking(bookingId, this.routeName);
      await this.userProvider.setupTableBooking();
    }
    this.navCtrl.pop();

  }

}