import { Component, Input } from '@angular/core';
import { ActiveOrder, CountdownTimer, Order } from 'src/app/services/order/order';
import { UserProvider } from 'src/app/services/user/user';
import { LoadingController, ModalController } from '@ionic/angular';
import { CartItem } from 'src/app/cart-items/cart-items';
import { ItemFunctions } from 'src/app/services/itemFunctions';
import { ModalOverseerProvider } from 'src/app/services/modaloverseer/modaloverseer';
import { OrderTicketComponent } from '../order-ticket/orderTicket.component';
import { LanguagePipe } from 'src/app/pipes/language.pipe';
import { LanguageProvider } from 'src/app/services/language/language.provider';
@Component({
  selector: 'active-order',
  templateUrl: 'active-order.html',
  styleUrls: ['active-order.scss']
})
export class ActiveOrderComponent {
  @Input('order') activeOrder: ActiveOrder;
  @Input() expanded: boolean;
  timer = new CountdownTimer(null);
  modalIsCurrentlyOpenForThisOrder = false;
  constructor(private modaloverseer: ModalOverseerProvider,
              private userProvider: UserProvider,
              private loaderCtrl: LoadingController,
              private modalCtrl: ModalController,
              public languageProvider: LanguageProvider,
              private languagePipe: LanguagePipe
    ) {

  }
  ngOnInit() {
    const possibleOpenModal = this.modaloverseer.currentlyOpenModals.find(x => x === this.activeOrder.key);
    this.modalIsCurrentlyOpenForThisOrder = possibleOpenModal != null;
    this.startTimer(this.activeOrder);

    if (this.modalIsCurrentlyOpenForThisOrder) {
      return;
    }
    this.checkForCompletion();
  }
  checkForCompletion() {
    const orderContents = this.activeOrder.order.order;
    const completedFood = orderContents.food !== undefined ? orderContents.food.filter(x => x.completed === 'completed') : [];
    const completedDrinks = orderContents.drinks !== undefined ? orderContents.drinks.filter(x => x.completed === 'completed') : [];
    if ((completedFood.length + completedDrinks.length) > 0) {
      this.modaloverseer.currentlyOpenModals.push(this.activeOrder.key);
      this.showPartiallyCompletedModal(completedFood, completedDrinks);
    }
    let contents = [];
    if (orderContents.food) {
      contents = contents.concat(orderContents.food);
    }
    if (orderContents.drinks) {
      contents = contents.concat(orderContents.drinks);
    }
    if (contents.every(x => x.completed === 'completedAndAccepted')) {
      this.showPartiallyCompletedModal(orderContents.food, orderContents.drinks);
    }

  }
  setExpanded(expanded: boolean) {
    this.expanded = !expanded;
    this.checkForCompletion();
  }
  getOrderText(activeOrder: ActiveOrder) {
    switch (activeOrder.order.tableNumber) {
      case -2: // Deliver to customer
        return this.languagePipe.transform('Din order är placerad i köket och du får en notifikation när ordern körs ut till dig',
        'ACTIVE_ORDER_DEL_CUSTOMER', this.languageProvider.currentLanguage);
      case -1: // Customer eats in but picks up
        return this.languagePipe.transform('Tack! Väntar på godkännande',
        'ACTIVE_ORDER_WAITING', this.languageProvider.currentLanguage);
      case 0: // Customer Take away
        return this.languagePipe.transform('Din order är placerad i köket och du får strax en uppskattad tid när du kan hämta upp den',
        'ACTIVE_ORDER_TAKEAWAY', this.languageProvider.currentLanguage);
      default: // Table order
        return this.languagePipe.transform('Tack! Vi är på gång med beställningen och kommer ut med den till dig så snart vi kan!',
        'ACTIVE_ORDER_TABLE', this.languageProvider.currentLanguage);
    }
  }
  async showPartiallyCompletedModal(foodItems: CartItem[], drinkItems: CartItem[]) {
    const modal = await this.modalCtrl.create({
      component: OrderTicketComponent,
      backdropDismiss: false,
      componentProps:
      {
        order: this.activeOrder,
        completedFood: foodItems,
        completedDrinks: drinkItems
      }
    });
    modal.present();
    const result = await modal.onDidDismiss();
    if (!result.data) { return; } else if (result.data === 'ok') {
      const loader = await this.loaderCtrl.create({ spinner: 'circular', duration: 5000 });
      loader.present();
      foodItems.forEach(x => x.completed = 'completedAndAccepted');
      drinkItems.forEach(x => x.completed = 'completedAndAccepted');

      this.userProvider
        .updateAcceptedOrderItems(this.userProvider.currentUser.getValue(), this.activeOrder, foodItems, drinkItems)
        .then(async x => {
          const orderContents = this.activeOrder.order.order;
          const food = orderContents.food != null ? orderContents.food : [];
          const drinks = orderContents.drinks != null ? orderContents.drinks : [];
          const allItemsAccepted = food.concat(drinks).every(x => x.completed === 'completedAndAccepted');
          const index = this.modaloverseer.currentlyOpenModals.findIndex(x => x === this.activeOrder.key);
          if (index !== -1) {
            this.modaloverseer.currentlyOpenModals.splice(index, 1);
          }
          if (allItemsAccepted) {
            try {
              await this.userProvider.removeActiveOrder(this.userProvider.currentUser.getValue(), this.activeOrder);
              loader.dismiss();
            } catch (error) {
              alert(JSON.stringify(error));
              loader.dismiss();
            }
          } else {
            loader.dismiss();
          }
        });
    }
  }
  async showCompletedModal() {
    const modal = await this.modalCtrl.create({ componentProps: { order: this.activeOrder }, component: OrderTicketComponent });
    modal.present();
    const result = await modal.onDidDismiss();
    const reason = result.data;

    if (!reason) { return; } else if (reason === 'ok') {
      const loader = await this.loaderCtrl.create();
      loader.present();
      this.userProvider.removeActiveOrder(this.userProvider.currentUser.getValue(), this.activeOrder).then(x => {
        loader.dismiss();
      });
    }
  }
  getOrderPlacedTime() {
    return new Date(this.activeOrder.order.date).toLocaleTimeString();
  }
  startTimer(activeOrder: ActiveOrder) {
    this.timer = new CountdownTimer(new Date(activeOrder.order.estimatedFinishTime));
    this.timer.start();
  }
  getTotalItemCost(item: CartItem) {
    return (item.Cost + ItemFunctions.costOfItemOptions(item)) * item.NrOfItems;
  }
}
