import {Component, EventEmitter, Inject, Input, ViewChild} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {BehaviorSubject, combineLatest, ReplaySubject, Subscription} from 'rxjs';
import {AppConfiguration} from '../app.configuration';
import {
  AlertController,
  IonContent, IonNav,
  IonRouterOutlet,
  IonSlides,
  LoadingController,
  MenuController,
  ModalController,
  NavController, ToastController,
} from '@ionic/angular';
import {CartProvider} from '../services/cart/cart';
import {AppInfo, ClientInfo, ContextProvider, DeliveryOption} from '../services/context/context';
import {User, UserPaymentSource, UserProvider} from '../services/user/user';
import {PaymentProvider, TransactionResult, } from '../services/payment/payment';
import {Order, OrderProvider} from '../services/order/order';
import {CartItem, CartItemsComponent, CartItemsOutput} from '../cart-items/cart-items';
import {DeliveryPlaceProvider} from '../services/delivery-place/delivery-place';
// import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import {DOCUMENT} from '@angular/common';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginNavComponent} from '../account/login-nav.component';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import {TakeawayNavComponent} from './takeaway-nav/takeawayNav.component';
import {ClientInfoComponent} from '../client-info/clientInfo.component';
import {NewsItemDetailsComponent} from './news-item-details/newsItemDetails.component';
import {Storage} from '@ionic/storage';
import {PushComponent} from '../push-notifications/push.component';
import {BonusProvider} from '../services/bonus/bonus';
import {OffersComponent} from '../offers/offers.component';
import {CustomTicketsComponent} from '../custom-tickets/customTickets.component';
import {LanguagePipe} from '../pipes/language.pipe';
import {LanguageProvider} from '../services/language/language.provider';
import _ from 'lodash';
import {LoginCompleteComponent} from '../account/login/login-complete.component';
import * as firebase from 'firebase';
import { EventsProvider } from '../services/events/events.provider';
import { ReepayGateway } from '../services/payment/gateways/reepay.gateway';
import { PubqConfigProvider } from '../services/pubq-config/pubq-config';
import { UnitProvider } from '../services/unitProvider';
import { Console } from 'console';

@Component({
  selector: 'page-cart',
  templateUrl: 'cart.html',
  styleUrls: ['cart.scss']
})
export class CartPage 
{
  @ViewChild('cartContent') content: IonContent;
  @Input() nav: IonNav;
  background = 'center url(\'./img/cartBackground.jpg\') no-repeat';
  showEstimatedWaitingTime: boolean;
  estimatedWaitingTimeText: string;
  estimatedWaitingTimeInMinutes: number;
  selectedTableNumber: number = null;
  tableNumberObservable: ReplaySubject<number>;
  tableBookingEnabled = false;
  globalOffersEnabled = false;
  customerTabEnabled = false;
  weeklyMenu = { enabled: false };
  user: User;
  activeTabsCount = 0;
  activeTableBooking;
  subscriptions = new Subscription();
  activatedRouteSubscription:Subscription;
  lastUsedCustomerTabKey: string;
  currentUnitName: string;
  allergyInfo;
  takeawayAndOrderByTabKeyEnabled = false;
  hasPushPermission = false;
  unitHasCustomTicketEnabled = false;
  globalOffers = [];
  allergyString = '';
  myBookingString = '';
  bookTableString = '';
  sitAtRestaurantClicked = false;
  stockBalanceEnabled = false;
  takeawayEnabled = false;
  deliveryEnabled = true;
  tablesEnabled = false;
  order: Order;
  modalCloseDelayTime = 500;
  sitAtRestaurantEnabled = false;
  sitAtRestaurantIcon = 'walk';
  takeawayText = 'Ta med';
  sitAtRestaurantText = 'Hämta i baren';
  tablesText = 'Servera till mitt bord';
  deliveryText = 'Utkörning';
  tablesIcon = 'golf';
  takeawayIcon = 'basket';
  showMap = true;
  choseVariant = '';
  showAllTypes = false;
  takeAwayImage = 'assets/icons/mmm/take_away.svg';
  eatInImage = 'assets/icons/mmm/eatin.svg';
  tableServeImage = 'assets/icons/mmm/tableserve.svg';
  deliveryImage = 'assets/icons/delivery.svg';
  showTakeAway = true;
  showEat = true;
  showTableServe = true;
  showDelivery = false;
  image: string;
  items: CartItem[];
  userStoredPaymentOptions: any[];
  selectedPaymentMethod = {};
  paymentOptions: any = "customerTab";
  ifCartNotSelected = true;
  updateCartPageData: boolean;
  userName: string;
  userPhone: string;
  tableNumber = -2;
  tableOption: DeliveryOption;
  showTableNumber = false;
  incorrectTableNumber = false;
  showNameBlock = false;
  //thisUserIsAnon = firebase.auth().currentUser.isAnonymous;
  name = '';
  anonUserFirstSecondName: any;
  split: any;
  orderFromCart: any;
  tableNumberValue = 1;
  provider = AppConfiguration.paymentProvider;
  contextIsLoaded:boolean = false;
  userIsLoaded:boolean = false;

  waitingToGoToCharge: BehaviorSubject<melodyclubLoading> = new BehaviorSubject<melodyclubLoading>(new melodyclubLoading());
  waitingToGoToChargeData: melodyclubLoading = new melodyclubLoading();

  client?: string = null;
  city?: string = null;
  routeName?: string = null;
  appInfo: AppInfo;
  contextSubscription: Subscription;
  userSubscription: Subscription;
  constructor(
    public navCtrl: NavController,
    private alertCtrl: AlertController,
    private loaderCtrl: LoadingController,
    public cartProvider: CartProvider,
    public contextProvider: ContextProvider,
    private modalCtrl: ModalController,
    private userProvider: UserProvider,
    private paymentProvider: PaymentProvider,
    private storage: Storage,
    private activatedRoute: ActivatedRoute,
    private orderProvider: OrderProvider,
    private sideMenuCtrl: MenuController,
    //private router: Router,
    private routerOutlet: IonRouterOutlet,
    private bonusProvider: BonusProvider,
    //private modalController: ModalController,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    private eventsProvider: EventsProvider,
    private unitProvider: UnitProvider,
    private http: HttpClient,
    private pubqConfig: PubqConfigProvider,
    public deliveryPlaceProvider: DeliveryPlaceProvider,
  ) {
    console.log("ARE IN CONSTRUKTOR");
    this.client = this.activatedRoute.snapshot.params.client;
    this.city = this.activatedRoute.snapshot.params.city;
    this.routeName = this.activatedRoute.snapshot.params.routeName;

    // Check if we are ready to move to payment
    this.subscriptions.add(
      this.waitingToGoToCharge.subscribe((data)=>{
        if(!data){return};
        //**************************************************** */
          // Only for Melodyclub
          console.log("Loaded Melody");
          if(data.cartLoaded && data.userLoaded && !data.movedToPay){

            this.waitingToGoToChargeData.movedToPay = true;
            this.payForOrder();
          }
        /***************************************************** */
      })
    );

    // Check if clientContext is init
    if(!this.contextProvider.client){
      console.log("No unit was init");
      this.subscriptions.add(
        this.unitProvider.getUnits(this.client, this.city).subscribe(async data => {
          if (!data) {
              return;
          }
          if(data)
          {
            let filterUnits = data.filter(x=>x.routeName == this.routeName);
            if(filterUnits.length > 0){
              console.log("Unit:");
              console.dir(filterUnits[0]);
              const unit = filterUnits[0];
              this.contextProvider.init(unit);
            }
          }
          else{
            console.log("Could not any unit for routeName " + this.routeName)
            navCtrl.navigateBack('no-route');
          }
        }) 
      );
    }

    this.takeawayText = this.languagePipe.transform('Ta med', 'DELIVERY_TO_GO', this.languageProvider.currentLanguage);
    this.sitAtRestaurantText = this.languagePipe.transform('Hämta i baren', 'DELIVERY_FETCH_BAR', this.languageProvider.currentLanguage);
    this.tablesText = this.languagePipe.transform('Servera till mitt bord', 'DELIVERY_MY_TABLE', this.languageProvider.currentLanguage);
    this.deliveryText = this.languagePipe.transform('Utkörning', 'DELIVERY_HOME_DELIVERY', this.languageProvider.currentLanguage);

    this.subscriptions.add(
      this.userProvider.currentUser.subscribe((x) => {
        if(!x){return}
          console.log("Found user->")
          console.dir(x);
          this.user = x;
          if (this.user.firstName && this.user.lastName || this.user.firstName && this.user.lastName === '') {
            this.name = `${this.user.firstName} ${this.user.lastName}`;
            this.userName = `${this.user.firstName} ${this.user.lastName}`;
          }
          if (this.user.activeTabs) {
            this.activeTabsCount = Object.keys(this.user.activeTabs).length;
          } else {
            this.activeTabsCount = 0;
          }

          this.handelQueryParam();
          //**************************************************** */
          // Only for Melodyclub

          this.waitingToGoToChargeData.userLoaded = true;
          this.waitingToGoToCharge.next(this.waitingToGoToChargeData);

          /****************************************************** */
          this.userIsLoaded=true;
      })
    );

    // this.contextSubscription = this.contextProvider.clientContext.appInfo.subscribe(
    //   (data) => {
    //     if(data){
    //       this.appInfo = data;
    //       const appInfo = this.appInfo;
    //       this.items = this.cartProvider.cart.items;
    //       this.tablesEnabled = appInfo.Context.Tables.enabled;
    //       this.tablesIcon = appInfo.Context.Tables.icon
    //         ? appInfo.Context.Tables.icon
    //         : this.tablesIcon;
    //       this.tablesText = appInfo.Context.Tables.text
    //         ? appInfo.Context.Tables.text
    //         : this.tablesText;
    //       this.takeawayEnabled = appInfo.Context.takeawayDelivery.enabled;
    //       this.takeawayIcon = appInfo.Context.takeawayDelivery.icon
    //         ? appInfo.Context.takeawayDelivery.icon
    //         : this.takeawayIcon;
    //       this.takeawayText = appInfo.Context.takeawayDelivery.text
    //         ? appInfo.Context.takeawayDelivery.text
    //         : this.takeawayText;
    //       this.sitAtRestaurantEnabled =
    //         appInfo.Context.sitAtRestaurantDelivery.enabled;
    //       this.sitAtRestaurantIcon = appInfo.Context.sitAtRestaurantDelivery.icon
    //         ? appInfo.Context.sitAtRestaurantDelivery.icon
    //         : this.sitAtRestaurantIcon;
    //       this.sitAtRestaurantText = appInfo.Context.sitAtRestaurantDelivery.text
    //         ? appInfo.Context.sitAtRestaurantDelivery.text
    //         : this.sitAtRestaurantText;
    //       this.deliveryEnabled =
    //         appInfo.Context.homeDelivery && appInfo.Context.homeDelivery.enabled;
    //       this.deliveryText = appInfo.Context.homeDelivery && appInfo.Context.homeDelivery.text
    //         ? appInfo.Context.homeDelivery.text
    //         : this.deliveryText;
    //       this.order = this.orderProvider.orderWaitingForApproval;
    //       this.stockBalanceEnabled = appInfo.Context.stockBalanceEnabled;
    //       this.deliveryPlaceProvider.tableOption = {
    //         fee: 0,
    //         minValue: appInfo.Context.Tables.LowerValue,
    //         maxValue: appInfo.Context.Tables.UpperValue,
    //         name: 'Tables',
    //       };
    //     }
    //   }
    // );

    // NOT IMPLEMENTED YET!!!
    // const tableNumber = this.cartProvider.defaultDeliveryType;
    // if (tableNumber) {
    //   this.tableNumber = Number(tableNumber);
    //   this.showMap = false;
    //   this.showDelivery = true;
    //   if (!this.user.firstName) {
    //     this.showNameBlock = true;
    //   }
    //   if (Number(this.tableNumber) === -2) {
    //     this.showMap = true;
    //     this.showDelivery = false;
    //     this.showNameBlock = false;
    //     this.choseVariant = this.deliveryText;
    //     this.image = this.deliveryImage;
    //   } else if (Number(this.tableNumber) === -1) {
    //     this.showEat = false;
    //     this.choseVariant = this.sitAtRestaurantText;
    //     this.image = this.eatInImage;
    //   } else if (Number(this.tableNumber) === 0) {
    //     this.showTakeAway = false;
    //     this.choseVariant = this.takeawayText;
    //     this.image = this.takeAwayImage;
    //   } else if (Number(this.tableNumber) >= 1) {
    //     if (!this.user.firstName) {
    //       this.showNameBlock = true;
    //     }
    //     this.showTableNumber = true;
    //     this.showTableServe = true;
    //     this.choseVariant = this.tablesText;
    //     this.image = this.tableServeImage;
    //     this.tableNumberValue = Number(this.tableNumber);
    //   }
    // }

    this.subscriptions.add(
      this.contextProvider.clientContext.appInfo.subscribe((x) => {
        console.log("Context loaded");
        console.dir(x);
        if (!x) { return; }
        // this.splashScreen.hide();
        this.estimatedWaitingTimeText = x.Context.estimatedWaitingTimeText;
        this.showEstimatedWaitingTime = x.Context.showEstimatedWaitingTime;
        this.weeklyMenu =
          x.Context.weeklyMenu && x.Context.weeklyMenu.enabled
            ? { enabled: true }
            : { enabled: false };
        this.tableBookingEnabled =
          x.Context.tableBooking && x.Context.tableBooking.enabled;
        this.customerTabEnabled =
          x.Context.paymentOptions &&
          x.Context.paymentOptions.customerTab &&
          x.Context.paymentOptions.customerTab.enabled;
        this.allergyInfo = x.Context.allergyInfo;
        this.takeawayAndOrderByTabKeyEnabled =
          x.Context.orderByCustomerTabKey && x.Context.takeawayDelivery.enabled;
        this.unitHasCustomTicketEnabled = x.Context.customTickets;
        this.globalOffersEnabled = x.Context.globalOffersEnabled;
        if (this.globalOffersEnabled) {
          this.checkGlobalOffers();
        }

        this.appInfo = x;
        const appInfo = this.appInfo;
        this.items = this.cartProvider.cart.items;
        this.tablesEnabled = appInfo.Context.Tables.enabled;
        this.tablesIcon = appInfo.Context.Tables.icon
          ? appInfo.Context.Tables.icon
          : this.tablesIcon;
        this.tablesText = appInfo.Context.Tables.text
          ? appInfo.Context.Tables.text
          : this.tablesText;
        this.takeawayEnabled = appInfo.Context.takeawayDelivery.enabled;
        this.takeawayIcon = appInfo.Context.takeawayDelivery.icon
          ? appInfo.Context.takeawayDelivery.icon
          : this.takeawayIcon;
        this.takeawayText = appInfo.Context.takeawayDelivery.text
          ? appInfo.Context.takeawayDelivery.text
          : this.takeawayText;
        this.sitAtRestaurantEnabled =
          appInfo.Context.sitAtRestaurantDelivery.enabled;
        this.sitAtRestaurantIcon = appInfo.Context.sitAtRestaurantDelivery.icon
          ? appInfo.Context.sitAtRestaurantDelivery.icon
          : this.sitAtRestaurantIcon;
        this.sitAtRestaurantText = appInfo.Context.sitAtRestaurantDelivery.text
          ? appInfo.Context.sitAtRestaurantDelivery.text
          : this.sitAtRestaurantText;
        this.deliveryEnabled =
          appInfo.Context.homeDelivery && appInfo.Context.homeDelivery.enabled;
        this.deliveryText = appInfo.Context.homeDelivery && appInfo.Context.homeDelivery.text
          ? appInfo.Context.homeDelivery.text
          : this.deliveryText;
        this.order = this.orderProvider.orderWaitingForApproval;
        this.stockBalanceEnabled = appInfo.Context.stockBalanceEnabled;
        this.deliveryPlaceProvider.tableOption = {
          fee: 0,
          minValue: appInfo.Context.Tables.LowerValue,
          maxValue: appInfo.Context.Tables.UpperValue,
          name: 'Tables',
        };
        this.contextIsLoaded = true;
      })
    );

    this.subscriptions.add(
      this.userProvider.activeTableBooking.subscribe((x) => {
        this.activeTableBooking = x;
      })
    );

    // NOT IMPLEMENTED YET!!!
    //this.chargePossibleSwishCustomerCached();
    //this.checkTopicSubscription(this.contextProvider.client.routeName);

    // NOT IMPLEMENTED YET!!!
    // if (this.allergyInfo) {
    //   this.allergyString = this.languagePipe.transform(
    //     `, mellan kl ${this.allergyInfo.open}-${this.allergyInfo.close}, så svarar vi gärna på dina frågor om innehåll`,
    //     'CART_ALLERGY_CONTACT', this.languageProvider.currentLanguage,
    //     [this.allergyInfo.open, this.allergyInfo.close]);
    // }

    this.myBookingString = this.languagePipe.transform('Min bokning', 'CART_MY_BOOKING', this.languageProvider.currentLanguage);
    this.bookTableString = this.languagePipe.transform('Boka bord', 'CART_BOOK_TABLE', this.languageProvider.currentLanguage);
  }

  async handelQueryParam(){
    this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe(async params => {
      if(!params) {return }

      let automaticCardOnFilePayment = params['automaticCardOnFilePayment'];
      let payment = params['payment'];
      if(automaticCardOnFilePayment){
        if(automaticCardOnFilePayment === "true"){
          this.loaderCtrl.create();
          setTimeout((context)=>{
            console.log("Starting automatic 3D Secure card on file payment.");
            this.useStoredPaymentOption(this.paymentOptions);
            this.orderFromCart = this.setUserForOrder(this.orderFromCart, this.user);
            this.orderProvider.orderWaitingForApproval = this.orderFromCart;
            this.payWithReepayCardOnFile();
          },1000,this);
        }else{
          console.log("No automatic payment");
        }
      }

      // Handling a started vipps payment
      // This url should only be called from Vipps 
      if(payment && payment === 'vipps'){

        let payment = await this.storage.get("PUBQ_VIPPS");
        payment = JSON.parse(payment);
        if(payment && payment.paymentType && payment.paymentType === 'vipps'){

          if(payment.cart){
            this.cartProvider.cart = payment.cart;
            this.orderProvider.orderWaitingForApproval = <Order>payment.order;
          }
          
          await this.verifyPaymentWithVipps();
        }
      }
    });
  }

//   /****************** ViewModel Events ******************/
  async ngOnInit() {

  }

  ngAfterViewInit() {
    this.tableNumberObservable = new ReplaySubject<number>(1);
    this.choseVariant = this.sitAtRestaurantText;
    this.takeawaySelected();
  }

  ngOnDestroy() {
    if(this.contextSubscription)
      this.contextSubscription.unsubscribe();
    if(this.activatedRouteSubscription)
      this.activatedRouteSubscription.unsubscribe();
    if(this.userSubscription)
      this.userSubscription.unsubscribe();
    if(this.subscriptions)
      this.subscriptions.unsubscribe();
  }

  ionViewDidEnter() {
    this.content.scrollToTop();
    this.updateCartPageData = !this.updateCartPageData;
  }

  /**************** End ViewModel Events **************************/

  isAllLoaded(){
    return this.userIsLoaded && this.contextIsLoaded;
  }

  getPaymentMethod(event) {
    this.paymentOptions = "customerTab";
    // this.paymentOptions = event;
    // this.ifCartNotSelected = !_.isEmpty(this.paymentOptions);
  }

  async userAddressInfo(event) {
    this.userName = event.userName;
    this.userPhone = event.userPhone;
  }

  

  formValid() {
    return this.userName && this.userPhone;
  }

  async showCustomTicket() {
    const modal = await this.modalCtrl.create({
      component: CustomTicketsComponent,
      cssClass: 'modal-drawer-small',
      animated: true,
      backdropDismiss: true,
      componentProps: { user: this.user }
    });
    modal.present();
  }

  async showOffers() {
    const modal = await this.modalCtrl.create({
      component: OffersComponent,
      cssClass: 'modal-drawer-small',
      componentProps: { asModal: true, asHtml: false },
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl,
    });
    modal.present();
  }

  async checkGlobalOffers() {
    this.subscriptions.add(this.bonusProvider.globalOffers.subscribe(x => {
      this.globalOffers = x;
    }));


  }

  async checkTopicSubscription(routeName) {
    const hasSeen = await this.userProvider.hasSeenPushPermissionRequest();
    const hasPermission = await this.userProvider.hasPushPermission();
    
  }

  async startTakeaway() {
    const modal = await this.modalCtrl.create({
      component: TakeawayNavComponent,
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl,
    });
    modal.present();
  }

  openMenu() {
    this.sideMenuCtrl.enable(true, 'first');
    this.sideMenuCtrl.open('first');
  }

  showTypes() {
    this.showAllTypes = !this.showAllTypes;
  }

  checkWhatSelect(text: string, image?: string) {
    this.choseVariant = text;
    this.image = image;
    this.showAllTypes = !this.showAllTypes;
    this.showTakeAway = true;
    this.showEat = true;
    this.showTableServe = true;
    this.showDelivery = true;
    this.showMap = false;
    this.showTableNumber = false;
    this.userPhone = '';
    if (this.choseVariant === this.takeawayText) {
      this.showTakeAway = !this.showTakeAway;
      this.showNameBlock = true;
      this.takeawaySelected();
    } else if (this.choseVariant === this.sitAtRestaurantText) {
      this.showEat = !this.showEat;
      this.showNameBlock = true;
      this.sitAtRestaurant();
    } else if (this.choseVariant === this.tablesText) {
      this.showTableServe = !this.showTableServe;
      this.showNameBlock = true;
      //this.promptForTableNumber();
    } else if (this.choseVariant === this.deliveryText) {
      this.showNameBlock = false;
      this.showDelivery = !this.showDelivery;
      this.homeDelivery();
    }
  }
  
  selectedClientName() {
    return this.contextProvider.getClient().name;
  }

  resetOrderCoupon(order): Order {
    order.coupon = undefined;
    order.totalCost = this.cartProvider.cart.sum;
    return order;
  }

  async onPaymentInitiation(event?: CartItemsOutput) {
    console.log("Cart event -> orderFromCart");
    
    this.orderFromCart = this.createOrder(event);
    this.orderProvider.orderWaitingForApproval = this.orderFromCart;
    
    /*********** Only for Melodyclub **************** */

    this.waitingToGoToChargeData.cartLoaded = true;
    this.waitingToGoToCharge.next(this.waitingToGoToChargeData);

    /************************************************ */

    if (this.orderFromCart.totalCost === 0) {
      await this.navCtrl.navigateBack(`main/${this.client}/${this.city}/${this.routeName}`);
    }
    await this.setDeviceTokenForOrder(this.orderFromCart);
  }

  setUserForOrder(order: Order, user: User): Order {
    try{
      console.log("Set user for order");
      console.dir(user);
      order.userId = user.id;
      if (!user.firstName && !user.lastName) {
        order.orderCode = user.id.substring(0, 4);
      } else if (user.firstName && !user.lastName) {
        order.orderCode = user.firstName.substring(0, 2) + user.id.substring(0, 2);
      } else {
        order.orderCode = user.firstName.substring(0, 2) + user.lastName.substring(0, 2);
      }
      if (order.orderCode.toLowerCase() === 'anal') { order.orderCode = 'lana'; }
      order.orderCode = order.orderCode.toUpperCase();
      console.log("Setting user to order")
      console.dir(order);
      console.dir(user);
      return order;
    }catch(error){
      console.log(error);
    }
  }

  async setDeviceTokenForOrder(order: Order): Promise<Order> {
    order.deviceToken = await this.userProvider.getDeviceToken();
    order.deviceToken_v2 = {
      token: order.deviceToken,
      projectId: AppConfiguration.firebaseProject
        ? AppConfiguration.firebaseProject.projectId
        : 'default',
    };
    return order;
  }

  createOrder(event: CartItemsOutput): Order {
    moment.locale('sv');
    const date = moment().unix();
    const order = new Order();
    order.version = '2';
    order.client = this.contextProvider.getClient();
    order.date = date;
    order.deliveryDate = event.selectedDate;
    order.coupon = event.coupon;
    order.customerComment = event.customerComment;
    order.momsRegNr = this.contextProvider.clientContext.appInfo.getValue().MomsRegNr;
    order.totalCost = event.totalCost;
    order.totalFoodItems = event.totalFoodItems;
    order.totalFoodCost = event.totalFoodCost;
    order.totalDrinksCost = event.totalDrinksCost;
    order.totalDrinkItems = event.totalDrinkItems;
    order.totalItems = event.totalItems;
    order.order = {
      food: this.cartProvider.getFoodItems(event.items),
      drinks: this.cartProvider.getDrinkItems(event.items),
    };
    return order;
  }

  async showRefusedAlert(error, msg) {
    if (error === 'cordova_not_available') { return; }
    let text;
    if (error.error.code && error.error.code === 'custom') {
      text = error.error.message;
    } else if (error.error.pubqmaintenance) {
      text = this.languagePipe.transform('Just nu pågår en uppdatering och ditt köp kan inte genomföras',
        'PAYMENT_ERROR_MAINTENANCE', this.languageProvider.currentLanguage);
 } else { text = msg; }
    const alert = await this.alertCtrl.create({
      header: 'Oops!',
      subHeader: text,
      message: error.error.additionalInfo,
      buttons: [this.languagePipe.transform('OK', 'OK', this.languageProvider.currentLanguage)],
    });
    alert.present();
  }

  async chargePossibleSwishCustomerCached() {
    const loader = await this.loaderCtrl.create({
      message: this.languagePipe.transform('Förbereder Swish-betalning', 'CART_PREPARNG_SWISH', this.languageProvider.currentLanguage),
    });
    try {
      let body = await this.storage.get('swish');
      if (!body) {
        return;
      }
      loader.present();
      body = JSON.parse(body);

      await this.paymentProvider.chargeSwishCustomerCachedOrder(body);
      loader.dismiss();
      this.clearCart();
      this.resetOrderFlow();
    } catch (err) {
      loader.dismiss();
      this.showRefusedAlert(err, this.languagePipe.transform('Betalningen avbröts',
        'CART_ITEM_REMOVE_CODE', this.languageProvider.currentLanguage));
    }
  }

  async addToCustomerTab(user, customerTab: UserPaymentSource, order: Order) {

    const loader = await this.loaderCtrl.create({
      message: this.languagePipe.transform('Skapar order och lägger till på notan',
        'CART_CREATING_ORDER', this.languageProvider.currentLanguage),
    });
    loader.present();
    try {
      await this.paymentProvider.chargeCustomerTab(user, customerTab, order);
      loader.dismiss();
      this.clearCart();
      this.resetOrderFlow();
    } catch (err) {
      loader.dismiss();
      this.showRefusedAlert(err, this.languagePipe.transform('Något gick fel', 'CART_SOME_ERROR', this.languageProvider.currentLanguage));
    }
  }

  goBack() {
    this.navCtrl.navigateBack(`main/${this.client}/${this.city}/${this.routeName}`);
  }

  async chargeRecurringCustomer(
    user: User,
    recurringDetailReference: string,
    order: Order
  ) {
    const amount = order.totalCost;
    const loader = await this.loaderCtrl.create();
    await loader.present();

    // Need to be fixed
    // this.paymentProvider
    //   .chargeRecurringCustomer(user, recurringDetailReference, amount, order)
    //   .subscribe(
    //     (transactionResult: TransactionResult) => {
    //       loader.dismiss();
    //       if (transactionResult.error) {
    //         this.showRefusedAlert(
    //           transactionResult.error,
    //           this.languagePipe.transform(
    //             'Se så ditt kort är öppet för internetköp och att tillräcklig summa finns tillgänglig',
    //             'CART_CHECK_CARD', this.languageProvider.currentLanguage)
    //         );
    //       } else {
    //         this.clearCart();
    //         this.resetOrderFlow();
    //       }
    //     },
    //     (error) => {
    //       loader.dismiss();
    //       this.showRefusedAlert(
    //         error,
    //         this.languagePipe.transform(
    //           'Se så ditt kort är öppet för internetköp och att tillräcklig summa finns tillgänglig',
    //           'CART_CHECK_CARD', this.languageProvider.currentLanguage)
    //       );
    //     }
    //   );
  }

  takeawaySelected() {
    this.tableNumber = 0;
    setTimeout(() => {
      if (this.cartProvider.cart.hasAlcohol) {
        alert(this.languagePipe.transform(
          'Du kan inte beställa alkohol som take away',
          'DELIVERY_ALCOHOL_TAKE_AWAY',
          this.languageProvider.currentLanguage)
        );
        return;
      }
    }, this.modalCloseDelayTime);

  }

  sitAtRestaurant() {
    setTimeout(() => {
      this.tableNumber = -1;
      this.sitAtRestaurantClicked = false;
    }, this.modalCloseDelayTime);
  }

  inputTableNumberValue(event) {
    console.log("NOT IMPLEMENTED!!!");
    // this.promptForTableNumber(event.target.value);
  }

  // promptForTableNumber(tableNumber?: number) {
  //   this.showTableNumber = true;
  //   this.incorrectTableNumber = true;
  //   if (tableNumber === undefined) {
  //     tableNumber = 1;
  //     this.tableNumber = tableNumber;
  //   }
  //   if (Number(tableNumber) >= Number(this.deliveryPlaceProvider.tableOption.minValue) &&
  //       Number(tableNumber) <= Number(this.deliveryPlaceProvider.tableOption.maxValue)) {
  //     this.tableNumber = parseInt(String(tableNumber), 10);
  //     this.incorrectTableNumber = false;
  //   }
  // }

  async showError(error) {
    const alert = await this.alertCtrl.create({
      header: this.languagePipe.transform('Fel', 'ERROR', this.languageProvider.currentLanguage),
      subHeader: error,
      buttons: [this.languagePipe.transform('OK', 'OK', this.languageProvider.currentLanguage)]
    });
    alert.present();
  }

  validateCouponAndDeliveryPlace(coupon, tableNumber) {
    let numberToVerify = parseInt(tableNumber);
    if (tableNumber > 0) { numberToVerify = 1; }
    const promise = new Promise((resolve, reject) => {
      if (!coupon || !coupon.validFor) {
        resolve({ coupon, tableNumber });
        return;
      }
      if (coupon.validFor[numberToVerify]) {
        resolve({ coupon, tableNumber });
        return;
      } else { reject({ coupon, tableNumber }); }
      return;
    });
    return promise;
  }

  async homeDelivery() {
    this.showMap = !this.showMap;
    this.tableNumber = -2;
    if (this.order.coupon === null) {
      this.order.coupon = {
        code: '',
        discountPercent: 0
      };
    }
    try {
      const couponValid = await this.validateCouponAndDeliveryPlace(
        this.order.coupon,
        this.tableNumber
      );
    } catch (error) {
      alert(this.languagePipe.transform(
        'Rabattkoden är inte giltig för detta serveringssätt',
        'DELIVERY_OFFER_ERROR',
        this.languageProvider.currentLanguage));
      return;
    }
  }

  clearCart() {
    this.cartProvider.empty();
    this.orderProvider.activatedOfferIds = [];
    this.orderProvider.orderWaitingForApproval = undefined;
  }

  resetOrderFlow() {
    this.tableNumberObservable.next(null);
    this.selectedTableNumber = null;
  }

  canPayForOrder(){
    return !this.ifCartNotSelected || this.cartProvider.cart.hasAlcohol && this.choseVariant === this.takeawayText ||
    this.userName === ''  ||
    this.name === '' ||
    this.tableNumber === 0 && this.choseVariant === this.tablesText ||
    this.choseVariant === this.tablesText && this.incorrectTableNumber;
  }
/*
>======>         >>       >=>      >=>       >=======>     >===>      >======>           
>=>    >=>      >>=>       >=>    >=>        >=>         >=>    >=>   >=>    >=>         
>=>    >=>     >> >=>       >=> >=>          >=>       >=>        >=> >=>    >=>         
>======>      >=>  >=>        >=>            >=====>   >=>        >=> >> >==>            
>=>          >=====>>=>       >=>            >=>       >=>        >=> >=>  >=>           
>=>         >=>      >=>      >=>            >=>         >=>     >=>  >=>    >=>         
>=>        >=>        >=>     >=>            >=>           >===>      >=>      >=>       
                                                                                         
    >===>      >======>     >====>     >=======> >======>                                
  >=>    >=>   >=>    >=>   >=>   >=>  >=>       >=>    >=>                              
>=>        >=> >=>    >=>   >=>    >=> >=>       >=>    >=>                              
>=>        >=> >> >==>      >=>    >=> >=====>   >> >==>                                 
>=>        >=> >=>  >=>     >=>    >=> >=>       >=>  >=>                                
  >=>     >=>  >=>    >=>   >=>   >=>  >=>       >=>    >=>                              
    >===>      >=>      >=> >====>     >=======> >=>      >=>                     
*/
  async payForOrder() {
    try{
      console.log("Pay for order");
      console.dir(this.paymentOptions);
      console.dir(this.orderFromCart)
      console.dir(this.user);

      this.useStoredPaymentOption(this.paymentOptions);
      // PABI-2021-03-17 :  Removed unit we need it

      this.orderProvider.orderWaitingForApproval = this.setUserForOrder(this.orderFromCart, this.user);
      const order = this.orderProvider.orderWaitingForApproval;

      //order.tableNumber = this.tableNumber;

      // Removed for melodyclub
      // if (this.choseVariant !== this.deliveryText) {
      //   delete order.deliveryComment;
      //   // delete order.userName;
      //   delete order.phoneNumber;
      //   delete order.deliveryRadius;
      //   delete order.deliveryLat;
      //   delete order.deliveryLong;
      //   delete order.deliveryFee;
      //   delete order.deliveryAddress;
      // }

      // const user = this.userProvider.currentUser.value;
      // const paymentSource = this.orderProvider.paymentOfChoice;
      // const amount = order.totalCost;
      // await this.paymentProvider.chargeRecurringCustomer(
      //   user,
      //   paymentSource,
      //   amount,
      //   order
      // );
     
      console.log("this.paymentOptions: " + this.paymentOptions);
      console.dir(this.paymentOptions);

      if(this.paymentOptions === "customerTab"){
        console.log("Starting to pay with customer tab");
        await this.payWithCustomerTab();
      }
      else if(this.paymentOptions === "vipps"){
        await this.payWithVipps();
      } // Handle diffrent payment options 
      else if (this.provider === "reepay" && this.paymentOptions === "newcard") {
        await this.payWithReepayNewCard();
      }
      else if (this.provider === "reepay")
      {
        //await this.payWithReepayCardOnFile();
        await this.payWithReepayCharge();
      }
    }catch(error){
      console.error(error);
    }
  }

  useStoredPaymentOption(option: UserPaymentSource) {
    this.orderProvider.paymentOfChoice = option;
  }

  async addUserToOrder(){
    let order = this.orderProvider.orderWaitingForApproval;
    
    let firstName = '';
    let userLastName = '';
    if (this.userName) {
      this.split = this.userName.split(' ');
      order.userName = this.userName;
    } else if (this.name) {
      this.split = this.name.split(' ');
      order.userName = this.name;
    }

    if(this.split && this.split.length >= 1)
      firstName = this.split[0];
    else 
      firstName = this.name;

    if(this.split && this.split.length >= 2){
      userLastName = typeof this.split[1] === 'undefined' ? '' : this.split[1];
    }

    if(this.user){
      if (this.user.firstName && this.user.lastName || this.user.firstName && this.user.lastName === '') {
        this.name = `${this.user.firstName} ${this.user.lastName}`;
      }
    }

    this.anonUserFirstSecondName = await this.userProvider.updateUser({
      firstName: firstName,
      lastName: userLastName,
      id: this.user.id
    });
  }

  async forward() {

    await this.addUserToOrder()
    console.log("At forward")
    console.dir(this.orderProvider.orderWaitingForApproval);

    // this.navCtrl.navigateForward([`/chargeCustomer/${this.client}/${this.city}/${this.routeName}`], {
    //   relativeTo: this.activatedRoute,
    //   queryParamsHandling: 'preserve',
    // });
  }

  /***********************************************************/
  /*
  >======>         >>       >=>      >=>       >=>        >=> >=> >===>>=====> >=>    >=> 
  >=>    >=>      >>=>       >=>    >=>        >=>        >=> >=>      >=>     >=>    >=> 
  >=>    >=>     >> >=>       >=> >=>          >=>   >>   >=> >=>      >=>     >=>    >=> 
  >======>      >=>  >=>        >=>            >=>  >=>   >=> >=>      >=>     >=====>>=> 
  >=>          >=====>>=>       >=>            >=> >> >=> >=> >=>      >=>     >=>    >=> 
  >=>         >=>      >=>      >=>            >> >>    >===> >=>      >=>     >=>    >=> 
  >=>        >=>        >=>     >=>            >=>        >=> >=>      >=>     >=>    >=> 
                                                                                          
  >=>         >=> >=> >======>   >======>     >=>>=>                                      
   >=>       >=>  >=> >=>    >=> >=>    >=> >=>    >=>                                    
    >=>     >=>   >=> >=>    >=> >=>    >=>  >=>                                          
     >=>   >=>    >=> >======>   >======>      >=>                                        
      >=> >=>     >=> >=>        >=>              >=>                                     
       >===>      >=> >=>        >=>        >=>    >=>                                    
        >=>       >=> >=>        >=>          >=>>=>    
  */      
  async payWithVipps() {
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_VIPPS", { user: this.user});
    var vipps: UserPaymentSource = {
      nickname: "vipps",
      recurringReference: null,
      type: "vipps",
    };
    this.orderProvider.paymentOfChoice = vipps;
    //this.eventsProvider.logEvent("USER_PAYED_WITH_VIPPS", { user: this.user });
    console.log("Order");
    console.dir(this.orderProvider.orderWaitingForApproval);
    console.log("To charge from payWithVipps");
    this.forward();
  }

  async verifyPaymentWithVipps() {
    this.eventsProvider.logEvent("USER_STARTING_TO_VERIFY_PAYMENT_WITH_VIPPS", { user: this.user});
    var vipps: UserPaymentSource = {
      nickname: "vipps_verify",
      recurringReference: null,
      type: "vipps_verify",
    };
    this.orderProvider.paymentOfChoice = vipps;
    this.eventsProvider.logEvent("USER_PAYED_WITH_VIPPS", { user: this.user });
    console.log("To charge from verifyPaymentWithVipps");
    this.forward();
  }

  /*
  >======>     >=======> >=======> >======>         >>       >=>      >=> 
  >=>    >=>   >=>       >=>       >=>    >=>      >>=>       >=>    >=>  
  >=>    >=>   >=>       >=>       >=>    >=>     >> >=>       >=> >=>    
  >> >==>      >=====>   >=====>   >======>      >=>  >=>        >=>      
  >=>  >=>     >=>       >=>       >=>          >=====>>=>       >=>      
  >=>    >=>   >=>       >=>       >=>         >=>      >=>      >=>      
  >=>      >=> >=======> >=======> >=>        >=>        >=>     >=>      
  */
  async payWithReepayNewCard(){
    
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_ADD_CARD", { user: this.user});

    let merchantConfig = this.contextProvider.clientContext.paymentGateway.value;
    let pubqConfig = this.pubqConfig.config.value;

    let gateway = new ReepayGateway(this.http, pubqConfig, merchantConfig, this.loaderCtrl, this.modalCtrl);
    
    // Will show a modal with Reepay component
    await gateway.pay_embedded({
      cardOnFile: "",
      order: this.orderProvider.orderWaitingForApproval,
      routeName: this.contextProvider.client.routeName,
      userId: this.user.id,
      orderText: "",
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email ? this.user.email : null,
      locale: this.languageProvider.currentLanguage,
    })
    .then(async (payResult) => {
      let orderId = payResult.invoice;
      let recurringReference = payResult.payment_method;
      let originalMerchant = this.contextProvider.client.routeName;
      let alert = await this.alertCtrl.create({
        header: this.languagePipe.transform("Spara ditt kort?", 'SAVE_CARD_QUESTION_HEADER', this.languageProvider.currentLanguage),
        subHeader: '',
        message: this.languagePipe.transform("Lägg till kort för framtida köp.", 'SAVE_CARD_QUESTION', this.languageProvider.currentLanguage),
        buttons: [{ role: "cancel", text: this.languagePipe.transform("Cancel", 'CANCEL', this.languageProvider.currentLanguage) }, { role: "confirm", text: this.languagePipe.transform("OK", 'OK', this.languageProvider.currentLanguage) }]
      });
      alert.present();
      let result = await alert.onDidDismiss();
      let saveCard = false;

      if (result.role === "confirm")
      {
        this.eventsProvider.logEvent("USER_SAVED_CARD",{user: this.user, orderId: orderId});
        saveCard = true;
      }
      this.orderProvider.orderWaitingForApproval.id = orderId;
      this.orderProvider.reepayData = { orderId, recurringReference, originalMerchant, saveCard };
      this.orderProvider.paymentOfChoice = <UserPaymentSource>{ recurringReference, nickname: "TBD", type: "reepay" };
      this.eventsProvider.logEvent("USER_PAYED_WITH_ADD_CARD", { user: this.user, orderId: orderId, savedCard: saveCard });
      console.log("To charge from payWithReepayNewCard");
      this.forward();
    })
    // If user cancelled payment
    .catch(() => {
      this.eventsProvider.logEvent("USER_CANCEL_TO_PAY_WITH_ADD_CARD",{user: this.user});
    })
    .finally(()=> {
      return;
    });

  }
  
  // Obsolete do not use -> use payWithReepayCharge
  // IS USE IF 3D SECURE CONTROL IS NEEDED!
  async payWithReepayCardOnFile(){
    console.log("payWithReepayCardOnFile");
    console.dir(this.user)
    console.log("Order in payWithReepay:")
    console.dir(this.orderProvider.orderWaitingForApproval)
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_SAVED_CARD", { user: this.user});

    let merchantConfig = this.contextProvider.clientContext.paymentGateway.value;
    let pubqConfig = this.pubqConfig.config.value;

    let gateway = new ReepayGateway(this.http, pubqConfig, merchantConfig, this.loaderCtrl, this.modalCtrl);
    const paymentSource = this.orderProvider.paymentOfChoice;
    
    await gateway.pay_embedded({
      cardOnFile: paymentSource.recurringReference,
      order: this.orderProvider.orderWaitingForApproval,
      routeName: this.contextProvider.client.routeName,
      userId: this.user.id,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      orderText: this.contextProvider.client.name,
      email: this.user.email ? this.user.email : null,
      locale: this.languageProvider.currentLanguage,
    })
    .then(async (payResult) => {

      console.log()
      console.dir(payResult);

      let orderId = payResult.invoice;
      let recurringReference = paymentSource.recurringReference;
      let originalMerchant = this.contextProvider.client.routeName;
      this.orderProvider.orderWaitingForApproval.id = orderId;
      this.orderProvider.reepayData = { orderId, recurringReference, originalMerchant, saveCard: false };
      this.eventsProvider.logEvent("USER_PAYED_WITH_SAVED_CARD", { user: this.user, orderId: orderId });

      console.dir(this.orderProvider.orderWaitingForApproval);
      console.log("To charge from payWithReepayCardOnFile");
      this.forward();
    })
    // If user cancelled payment
    .catch(() => {
      this.eventsProvider.logEvent("USER_CANCEL_TO_PAY_WITH_SAVED_CARD",{user: this.user});
    })
    .finally(()=> {
      return;
    });
  }

  async payWithReepayCharge(){
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_SAVED_CARD", { user: this.user});
    
    let orderId = 'N/A';
    const paymentSource = this.orderProvider.paymentOfChoice;
    let recurringReference = paymentSource.recurringReference;
    let originalMerchant = this.contextProvider.client.routeName;

    this.orderProvider.reepayData = { orderId, recurringReference, originalMerchant, saveCard: false };
    this.eventsProvider.logEvent("USER_PAYED_WITH_SAVED_CARD", { user: this.user });
    console.log("To charge from payWithReepayCharge");
    this.forward();
  }


  async payWithCustomerTab(){
    this.storage.get("PUBQ_TAB_SETTINGS").then((x)=>{
      let data = JSON.parse(x);

      let body = {
        appCode: '1701',
        orderCode: 'RUM1'
      }
      
      if(data) {
        body = data;
      }

      var customerTab: UserPaymentSource = {
        nickname: "customerTab",
        recurringReference: null,
        type: "customerTabKey",
      };

      this.orderProvider.paymentOfChoice = customerTab;
      this.orderProvider.setLastUsedTabKey(body.appCode);
      this.orderProvider.orderWaitingForApproval.orderCode = body.orderCode;
      this.forward();
    });
  }
}

@Component({
  selector: 'cart-welcome',
  templateUrl: 'cart-welcome.html',
  styleUrls: ['cart.scss'],
})
export class CartWelcome {
  @Input() clientName;
  newsItems = [];
  loading = true;
  subscriptions: Subscription = new Subscription();
  toggleItemIndex = 999;
  appInfo;
  sliderOpts = { spaceBetween: -40 };
  clientInfo: ClientInfo;
  aboutUs = null;
  menu;
  @ViewChild('newsSlider') slides: IonSlides;
  constructor(
    private navCtrl: NavController,
    private contextProvider: ContextProvider,
    // private inAppBrowser: InAppBrowser,
    private modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    @Inject(DOCUMENT) private ngDocument
  ) { }
  async showOfferInfo(newsItem) {
    const modal = await this.modalController.create({
      component: NewsItemDetailsComponent,
      componentProps: { newsItem },
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl
    });
    modal.present();
    const result = await modal.onDidDismiss();
    if (result.role === 'confirm') {
      const tab = newsItem.displayProduct.tab === 'food' ? 'tab2' : 'tab3';
      this.navCtrl.navigateForward(
        [`main/tabs/${tab}/${newsItem.displayProduct.tab}`],
        { queryParams: { product: newsItem.displayProduct.productId } }
      );
    }
  }
  ngOnInit() {
    this.clientInfo = this.contextProvider.client;
    this.subscriptions.add(
      this.contextProvider.clientContext.appInfo.subscribe((appInfo) => {
        if (!appInfo || !appInfo.Context.aboutUsDescription) {
          this.aboutUs = null;
          return;
        }
        this.aboutUs = appInfo.Context.aboutUsDescription;
      })
    );
    this.subscriptions.add(
      combineLatest([
        this.contextProvider.clientContext.news,
        this.contextProvider.clientContext.menu
      ]
      ).subscribe(async ([news, menu]) => {
        this.menu = menu;
        this.newsItems = [...news];
        this.newsItems.sort((a, b) => {
          if (typeof (a.index) === 'undefined' || typeof (b.index) === 'undefined') {
            return -1;
          }
          return a.index - b.index;
        });
        this.newsItems = this.newsItems.splice(0, 8);
        this.newsItems.forEach((x, i) => {
          this.loadAndApplyImage(x);
        });
      })
    );
    this.subscriptions.add(
      this.contextProvider.clientContext.appInfo.subscribe((x) => {
        if (x && x.style && (x.style.facebookLink || x.style.instagramLink)) {
          this.appInfo = x;
        }
      })
    );
  }
  showSocialMediaHeadline() {
    return (
      this.appInfo &&
      this.appInfo.style &&
      (this.appInfo.style.facebookLink.shown ||
        this.appInfo.style.instagramLink.shown)
    );
  }
  openSocialMediaLink(url) {
    this.contextProvider.launchExternalApp(url, '');
  }
  loadAndApplyImage(news) {
    const image = new Image();
    image.src = news.imageUrl;
    image.onload = (x) => {
      news.styling = {
        'background-image': `url('${news.imageUrl}')`,
        'background-color': 'transparent',
        'background-size': 'cover',
        'background-position': 'center',
        'background-repeat': 'no-repeat',
        animation: 'hd-quality 0.3s',
        'animation-delay': '0.5s',
        'animation-fill-mode': 'forwards',
      };
      this.loading = false;
    };
  }
  update() {
    this.slides.update();
  }

  openNews(newsItem) {
    switch (newsItem.type) {
      case 'url': {
        const url = newsItem.url;
        if (!url) { return; }
        // const browser = this.inAppBrowser.create(url, '_blank', {
        //   usewkwebview: 'yes',
        //   location: 'no',
        //   fullscreen: 'yes',
        //   enableViewportScale: 'yes',
        //   toolbar: 'yes',
        //   hidenavigationbuttons: 'yes',
        //   closebuttoncolor: '#8EA9F6',
        //   closebuttoncaption: 'Stäng',
        //   presentationstyle: 'fullscreen',
        //   transitionstyle: 'crossdissolve',
        //   toolbartranslucent: 'no',
        //   toolbarposition: 'top',
        // });
        // browser.show();
        window.open(url, '_blank');
        break;
      }
      case 'product': {
        const tab = newsItem.displayProduct.tab === 'food' ? 'tab2' : 'tab3';
        this.navCtrl.navigateForward(
          [`main/tabs/${tab}/${newsItem.displayProduct.tab}`],
          { queryParams: { product: newsItem.displayProduct.productId } }
        );
        break;
      }
      case 'offer': {
        this.showOfferInfo(newsItem);
        break;
      }
    }
  }
  newsItemLoaded(id) {
    const s = this.ngDocument.getElementsByClassName(id);
    for (let index = 0; index < s.length; index++) {
      const element = s[index];
      element.style.visibility = 'visible';
      element.classList.add('fadeIn');
    }
  }
  async showRestaurantInfo() {
    const modal = await this.modalController.create({
      component: ClientInfoComponent,
      componentProps: {
        appInfo: this.contextProvider.clientContext.appInfo.value,
        clientInfo: this.contextProvider.clientContext.currentClient.value,
      },
      cssClass: 'modal-drawer',
      swipeToClose: true,
    });
    modal.present();
  }
}

class melodyclubLoading{userLoaded: boolean; cartLoaded: boolean; movedToPay: boolean;}
