import { Component, Input } from "@angular/core";
import { ModalController } from '@ionic/angular';
import { url } from 'inspector';
import { LanguageProvider } from '../services/language/language.provider';

@Component({
    selector: "page-custom-tickets",
    templateUrl: "customTickets.component.html",
    styleUrls: ["customTickets.component.scss"]
})
export class CustomTicketsComponent {
    @Input() user: any;
    ticket
    style: {};
    constructor(private modalController: ModalController,
        public languageProvider: LanguageProvider) {

    }
    ngOnInit() {
        this.ticket = this.user.customTicket;
        this.style = {
            'background': `url(${this.ticket.bgUrl}) 0 0/100% 100% no-repeat`
        }
    }
    dismiss() {
        this.modalController.dismiss();
    }
}