import { Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { LanguagePipe } from 'src/app/pipes/language.pipe';
import { LanguageProvider } from 'src/app/services/language/language.provider';



@Component({
    selector: 'edit-item',
    templateUrl: 'editItem.component.html'
})
export class EditItemComponent {
    @Input() item;
    editedItem;
    constructor(private modalController: ModalController, public languageProvider: LanguageProvider, private alertCtrl: AlertController, private languagePipe: LanguagePipe) {

    }
    ngOnInit() {
        this.editedItem = JSON.parse(JSON.stringify(this.item));
    }
    dismiss() {
        this.modalController.dismiss(null, 'dismiss');
    }
    ok() {
        this.modalController.dismiss(this.editedItem, 'confirm');
    }
    increase() {
        this.editedItem.NrOfItems += 1;
    }
    async decrease() {
        if (this.editedItem.NrOfItems === 1) {
            const confirm = await this.alertCtrl.create({
                header: this.languagePipe.transform('Vill du ta bort ' + this.editedItem.Name + '?', 'CART_ITEMS_REMOVE', this.languageProvider.currentLanguage, [this.editedItem.Name]),
                buttons: [
                    {
                        text: this.languagePipe.transform('Nej', 'NO', this.languageProvider.currentLanguage),
                        role: 'cancel'
                    },
                    {
                        text: this.languagePipe.transform('Ja', 'YES', this.languageProvider.currentLanguage),
                        role: 'confirm'
                    }
                ]
            });
            confirm.present();
            const result = await confirm.onDidDismiss();
            if (result.role === 'confirm') {
                this.editedItem.NrOfItems -= 1;
                this.modalController.dismiss(this.editedItem, 'remove');
            } else {
                this.editedItem.NrOfItems = 1;
            }
        } else {
            this.editedItem.NrOfItems -= 1;
        }
    }
}
