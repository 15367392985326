module.exports = {
    name: "PubQ Demo",
    id: "com.emberlink.pubqdemo",
    defaultLanguage:"se",
    description: "Ordering made easy",
    userOriginName: "pubqdemo",
    dbRoute: "demorino",
    customUrlProtocol: "https://tab.pubq.se", //"https://tab-staging.pubq.se"
    statusBarColorHex: "",
    isProduction: true,
    hasCitySelection: false,
    paymentProvider: "reepay",
    statusBarStyle: "lightcontent",
    tabHomeTitle: "Hem",
    tabFoodTitle: "Mat",
    tabDrinksTitle: "Dryck",
    tabAccountTitle: "Konto",
    menuSegmentType: "md",
    firebaseProject: null
}
