import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { LanguageProvider } from 'src/app/services/language/language.provider';

@Component({
  selector: "news-item-details",
  styleUrls: ["newsItemDetails.component.scss"],
  templateUrl: "newsItemDetails.component.html",
})
export class NewsItemDetailsComponent {
  style;
  @Input() newsItem;

  constructor(private modalCtrl: ModalController,
    public languageProvider:LanguageProvider) {

  }
  ngOnInit(){
    this.style = {
        "min-height": "220px",
        background: `url(${this.newsItem.imageUrl})`,
        "background-size": "cover",
        "background-position": "center",
      };
      this.newsItem.displayProduct.additionalDescription = this.newsItem.displayProduct.additionalDescription.replace(/(\r\n|\n|\r)/gm,"<br>");
  }
  dismiss() {
    this.modalCtrl.dismiss(null, "cancel");
  }
  go() {
    this.modalCtrl.dismiss(this.newsItem, "confirm");
  }
}
