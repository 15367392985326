import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AccountPage } from './account';
import { PhoneFormPage, PopoverPage } from './login/phone/phoneForm';
import { VerifyPhonePage } from './login/phone/verifyPhone';

import { LoginNavComponent } from './login-nav.component';
import { LoginCompleteComponent } from './login/login-complete.component';
import { LoginPage } from './login/login';
import { AdditionalAccountInfoForm } from './login/additionalAccountInfoForm.component';
import { EmailForm } from './login/emailForm.component';
import { RegisterEmail } from './login/registerEmail.component';
import { SharedComponentsModule } from '../sharedComponents.module';
import { PurchaseHistoryListViewPage } from './purchaseHistory/purchaseHistoryListView';
import { PurchaseHistoryDetailsViewComponent } from './purchaseHistory/purchaseHistoryListDetails';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    RouterModule.forChild(
      [
        {
          path: 'account',
          component: AccountPage
        },
        {
          path: 'login',
          component: LoginPage
        },
        {
          path: 'email',
          component: EmailForm
        },
        {
          path: 'phone',
          component: PhoneFormPage
        },
        {
          path: 'verifyPhone',
          component: VerifyPhonePage
        },
        {
          path: '',
          redirectTo: 'account',
          pathMatch: 'full'
        }
      ]
    ),
    SharedComponentsModule,
  ],
    declarations: [
        AccountPage,
        LoginPage,
        PhoneFormPage,
        VerifyPhonePage,
        RegisterEmail,
        AdditionalAccountInfoForm,
        EmailForm,
        LoginNavComponent,
        LoginCompleteComponent,
        PopoverPage,
        PurchaseHistoryListViewPage,
      PurchaseHistoryDetailsViewComponent
    ],
    entryComponents: [
        LoginNavComponent,
        LoginCompleteComponent,
        AdditionalAccountInfoForm,
        PopoverPage,
        RegisterEmail,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AuthModule { }
