import { NgModule } from '@angular/core';
import { PushComponent } from './push-notifications/push.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { OffersComponent } from './offers/offers.component';
import { StockModalComponent } from './stock-balance/stock.component';
import { TimePickerComponent } from './cart-items/time-picker/timePicker.component';
import { LanguagePipe } from './pipes/language.pipe';
import { EditItemComponent } from './cart-items/edit-items/editItem.component';


@NgModule({
  declarations: [
    PushComponent,
    StockModalComponent,
    OffersComponent,
    TimePickerComponent,
    LanguagePipe,
    EditItemComponent
  ],
  entryComponents: [PushComponent, OffersComponent, TimePickerComponent, EditItemComponent],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule
  ],
  exports: [
    StockModalComponent, PushComponent, OffersComponent,
    TimePickerComponent, LanguagePipe,
    EditItemComponent
  ],
  providers: [
    LanguagePipe
  ]
})
export class SharedComponentsModule { }
