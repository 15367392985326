
export const lang = {
    get: (string, parameters?) => {
        switch (string) {
            case 'BONUS_USE': return `Use ${parameters[0]}​​​ bonus points`;
            case 'BONUS_USE': `Brug ${parameters[0]} bonuspoint'`;
            case 'STOCK_ALERT': `Desværre manglede ${parameters[0]}​​​ på lager. Din kurv er blevet justeret til det, der var tilgængeligt, så du kan fortsætte med at shoppe.`;
            case 'BOOKING_NO_AVAILABLE_TIMES_1': `Der er desværre ingen ledige tidspunkter for ${parameters[0]} personer.`;
            case 'BOOKING_SMS_TEX': `Hej, dette handler om reservationen [booking_name] \n kl. ${parameters[0]}\n ${parameters[1]} personer.`;
            case 'OFFERS_ACTIVATED': `Aktiverede ${parameters[0]}`;
            case 'CART_ITEMS_REMOVE': `Vil du fjerne ${parameters[0]}?`;
            case 'CART_ALLERGY_CONTACT': `Mellem kl. ${parameters[0]}-${parameters[1]} svarer vi gerne på dine spørgsmål om indhold`;
            case 'EMAIL_CORRECT': `Er ${parameters[0]} korrekt? `;
            case 'LOGIN_SEND_SMS': `Send sms til ${parameters[0]}?`;
            case ' LOGIN_ERROR': `Ups! Noget gik galt. Prøv en anden måde at logge ind. \n ${parameters[0]}`;
        }
    },

    PUSH_TITLE: 'Notifikationer',
    PUSH_ALERT_TXT: 'For at se status på din bestilling skal du tillade push-beskeder',
    PUSH_ALERT_BTN: 'Klik her',
    PUSH_INFO: 'Vælg de restauranter, du ønsker at modtage tilbud fra. Vi lover at håndtere din tillid forsvarligt.',
    PUSH_PERMISSION: 'Du bedes gå til telefonens indstillinger og tillade push-beskeder for appen, før du kan foretage denne ændring.',
    PUSH_CHANGE_SELECTION: 'Du kan altid ændre dit valg under Konto => Push-beskeder',
    CURRENT: 'Aktuelt',
    NEW_ORDER: 'Ny bestilling',
    WELCOME: 'Velkommen!',
    DONE: 'Færdig',
    BACK: 'Tilbage',
    OPT_OUT: 'Nej tak',
    CONFIRM: 'Bekræft',
    OK: 'OK',
    CANCEL: 'Afbryd',
    DATE_TIME: 'Dato og tidspunkt',
    SENT: 'Sendt',
    BOOK: 'Bestil',
    SEARCH: 'Søg…',
    ERROR: 'Fejl',
    YES: 'Ja',
    NO: 'Nej',
    CARD_NO: 'Kortnummer',
    ADD: 'Tilføj',
    NAME: 'Navn',
    PHONE: 'Telefon',
    WRITE_TABLENUMBER: 'Skriv dit bordnummer',
    TABLENUMBER: 'Bordnummer',
    TIPS: 'Drikkepenge',
    PAY: 'Betal',
    PAYED: 'Betalt',
    SUM: 'Sum',
    CLOSED: 'Lukket',
    ACTIVATE: 'Aktivér',
    DISCOUNT: 'Rabat',
    ORDER: 'Bestil',
    FORWARD: 'Videre',
    /* 3 letter short*/
    SUN: 'Søn',
    MON: 'Man',
    TUE: 'Tir',
    WED: 'Ons',
    THU: 'Tor',
    FRI: 'Fre',
    SAT: 'Lør',
    TODAY: 'I dag',
    BOOKING_THANKS_1: 'Tak for besøget!',
    BOOKING_THANKS_2: 'Vi ses snart igen',
    MY_BOOKING: 'Min bestilling',
    BOOKING_QUESTIONS: 'Har du spørgsmål til din bestilling?',
    BOOKING_CONTACT_US_1: 'Kontakt os på',
    BOOKING_CONTACT_US_2: 'eller klik her for at sende en sms',
    BOOKING_CONFIRM: 'Ordrebekræftelse',
    BOOKING_APPCODE: 'Din kode er',
    BOOKING_ICONS: 'Bestil ved hjælp af ikonerne nedenfor',
    BOOKING_TITE: 'Bestil bord',
    BOOKING_NUMBER_GUESTS: 'Antal gæster',
    BOOKING_LARGER_GROUPS: 'Betil bord til større grupper',
    BOOKING_LARGER_GROUPS_CONTACT: 'Hvis du vil reservere et bord til flere mennesker bedes du kontakte',
    BOOKING_NO_AVAILABLE_TIMES_1: 'Ingen ledige tider',
    BOOKING_ALLOW_SMS: 'Tilladelsen giver appen mulighed for at åbne en forudfyldt SMS til restauranten for dig',
    BOOKING_CANCEL: 'Er du sikker på, du vil annullere bestillingen?',
    BOOKING_CONFIRM_CANCEL: 'Bekræft afbestilling',
    BOOKING_CANCELED: 'Din reservation er nu annulleret',
    STOCK_TITLE: 'Udsolgt',
    BONUS_POINTS: 'Bonuspoint',
    PAYMENT_TITLE: 'Betale for',
    PAYMENT_DELIVERY: 'Leveringsomkostninger',
    PAYMENT_DISCOUNT: 'Rabat',
    PAYMENT_BONUS: 'Brug bonuspoint',
    PAYMENT_FIRST_DISCOUNT: 'på vej til din første rabat!',
    PAYMENT_EXTRA: 'Ekstra',
    PAYMENT_TOTAL: 'Total',
    PAYMENT_PAYMENTS: 'Betalingsmetode',
    PAYMENT_TABLETAB: 'Tilføj til bordregning',
    PAYMENT_SWISH: 'Betal med Swish',
    PAYMENT_VIPPS: 'Betal med Vipps',
    PAYMENT_APPLEPAY: 'Betal med Apple Pay',
    PAYMENT_SAVED_CARDS: 'Gemte betalingskort',
    PAYMENT_ADD_CARD: 'Tilføj betalingskort',
    PAYMENT_SCROLL: 'Scroll ned for at betale',
    PAYMENT_PAY: 'Betal',
    PAYMENT_OFFER_ERROR_HEADER: 'Disse tilbud kunne ikke aktiveres',
    PAYMENT_OFFER_ERROR_TEXT: 'De gælder ikke for den serveringstype, du har valgt',
    PAYMENT_ERROR_MAINTENANCE: 'En opdatering er i øjeblikket i gang, og dit køb kan ikke gennemføres',
    PAYMENT_ERROR: 'Sørg for at dit kort kan benyttes til onlinekøb, og at der er et tilstrækkeligt beløb tilgængeligt',
    CHARGE_WAIT: 'Vent venligst ... ',
    CHARGE_TAB_TOAST: 'Din regning er blevet betalt og en kvittering er oprettet på din konto',
    CHARGE_SWISH_INIT: 'Anmod Swish om betaling',
    CHARGE_ERROR: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    CHARGE_VERIFY: 'Verificerer og afstemmer',
    ADD_CARD_ADDING: 'Tilføjer betalingskort',
    ADD_CARD_NEW_CARD: 'Tilføj nyt betalingskort',
    ADD_CARD_ERROR_E1: 'Noget gik galt. Prøv venligst igen.',
    ADD_CARD_ERROR_E3: 'Restauranten kan ikke tilføje kort. Kontakt venligst personalet.',
    ADD_CARD_ERROR_E4: 'Ugyldige oplysninger',
    ADD_CARD_ERROR_E5: 'Forkert korttype',
    ADD_CARD_ERROR_E6: 'Forkert terminal-ID! Kontakt venligst personalet.',
    ADD_CARD_ERROR_E7: 'Ikke tilladt! Kontakt venligst personalet.',
    ADD_CARD_ERROR_E8: 'Forkert forhandler-ID! Kontakt venligst personalet.',
    ADD_CARD_ERROR_E9: 'Forkert dato! Kontakt venligst personalet.',
    ADD_CARD_ERROR_E10: 'Forkert kortnummer. Tjek venligst at du har indtastet korrekt. ',
    ADD_CARD_ERROR_E11: 'Forkert måned/år. Tjek venligst at du har indtastet korrekt. ',
    ADD_CARD_ERROR_E12: 'Forkert navn på betalingskortet. Kontakt venligst personalet. ',
    ADD_CARD_ERROR_E13: 'Restauranten har de forkerte indstillinger. Kontakt venligst personalet.',
    ADD_CARD_ERROR_E14: 'Forkert CVV-kode. Tjek venligst at du har indtastet korrekt. ',
    ADD_CARD_NICK_TITLE: 'Hvad vil du navngive dit kort?',
    ADD_CARD_NICK_MSG: 'Kortet krypteres på en sikker måde og gemmes til fremtidig brug',
    ADD_CARD_NICK_PLACEHOLDER: 'Kortets navn',
    ADD_CARD_SECURITY_INFO: 'Vi behandler dine kortoplysninger på sikker vis. Alle følsomme oplysninger gemmes ved en certificeret betalingsudbyder.',
    OFFERS: 'Tilbud',
    OFFERS_NONE: 'Der er ingen tilbud at vise på nuværende tidspunkt',
    MENU_DRINKS_CLOSED: 'Der kan i øjeblikket ikke bestilles drikkevarer',
    MENU_ORDER_FULL: 'Du kan ikke tilføje yderligere til din ordre',
    MENU_NOT_AVAILABLE: 'i øjeblikket ikke tilgængelig',
    MENU_NO_PRODUCTS: 'Der er ingen produkter at vise',
    ITEM_DESCRIPTION_OUT_OF_STOCK: 'Udsolgt',
    ITEM_DESCRIPTION_SCROLL: 'Scroll ned',
    COMMENT: 'Kommentar',
    BAB_NAME: 'Hvad skal din ret hedde?',
    BAB_ADD: 'Tilføj',
    BAB_TYPE_SIZE: 'Vælg type og størrelse',
    BAB_DRESSING: 'Dressing',
    BAB_TOPPINGS: 'Toppings',
    BAB_SIDEORDER: 'Vælg tilbehør',
    BAB_EXTRAS: 'Vælg ekstra',
    BAB_BURGER_NAME: 'Hvad skal din burger hedde?',
    BAB_MANY: 'Vælg det antal du ønsker',
    CHOOSE_RESTAURANT: 'Vælg restaurant',
    DELIVERY_MY_TABLE: 'Server ved mit bord',
    DELIVERY_TO_GO: 'To go',
    DELIVERY_FETCH_BAR: 'Hent i baren',
    DELIVERY_HOME_DELIVERY: 'Udbringning',
    DELIVERY_OFFER_ERROR: 'Rabatkoden er ikke gyldig for denne serveringstype',
    DELIVERY_ALCOHOL_TAKE_AWAY: 'Du kan ikke bestille alkohol som take away',
    DELIVERY_ALCOHOL_DELIVERY: 'Du kan ikke bestille alkohol til udbringning',
    DELIVERY_SERVING: 'Servering',
    DELIVERY_QUESTION: 'Hvordan vil du modtage din bestilling?',
    LOCATION_CHOSE: 'Vælg plads',
    LOCATION_ADRESS: 'Din adresse',
    LOCATION_QUESTION: 'Levering hertil?',
    LOCATION_FEE: 'Leveringsomkostninger',
    LOCATION_COMMENT: 'Kommentar (etage, postnummer etc.)',
    LOCATION_UNFORTUNATELY_OTHER_ADRESS: 'Desværre leverer vi ikke hertil endnu. Prøv en anden adresse.',
    LOCATION_OTHER_ADRESS: 'Prøv en anden adresse',
    CUSTOMER_TABS_ATTENTION_MSG: 'Vi kommer straks og hjælper dig',
    CUSTOMER_TABS_PAY_IN_APP: 'Betal i appen',
    CUSTOMER_TABS_CALL_WAITER: 'Tilkald tjener',
    CUSTOMER_TABS_TIPS: 'Vil du give noget ekstra til vores personale?',
    CUSTOMER_TABS_CUSTOM_AM1: 'Valgfrit beløb...',
    CUSTOMER_TABS_CUSTOM_AM2: 'Valgfrit beløb...',
    CUSTOMER_TAB: 'Regning',
    CUSTOM_TICKET_VALID: 'Gyldigt indtil',
    CLIENT_OPEN_HOURS: 'Åbningstider',
    CART_ITEMS_APPLYING_OFFER: 'Tilføjer rabatkode',
    CART_ITEM_OFFER_NOT_VALID: 'Rabatkoden er ikke tilgængelig',
    CART_ITEM_COMMENT_EXAMPLE: '"F.eks. ingen koriander"',
    CART_ITEM_COMMENT: 'Skriv din kommentar her',
    CART_ITEM_REMOVE_CODE: 'Fjern koden?',
    CART_ITEM_18Y: '18+ år og gyldig ID er påkrævet ved køb af alkohol',
    CART_ITEM_ALCOHOL: 'Ingen rabat på alkohol',
    CART_NO_ACTIVE_TAB: 'Du har ingen åben regning',
    CART_GO_TO_MENY: 'Gå til menuen og placer din første ordre',
    CART_PREPARNG_SWISH: 'Forbereder Swish-betaling',
    CART_PAYMENT_ABORTED: 'Betalingen blev afbrudt',
    CART_CREATING_ORDER: 'Opretter ordrer og tilføjer til regningen',
    CART_SOME_ERROR: 'Noget gik galt. Prøv venligst igen.',
    CART_CHECK_CARD: 'Sørg venligst for at dit kort kan benyttes til onlinekøb, og at der er et tilstrækkeligt beløb tilgængeligt',
    CART_WEEK_MENU: 'Ugens menu',
    CART_ORDER_TAKEWAY: 'Bestil takeaway',
    CART_MY_BOOKING: 'Mine bestillinger',
    CART_BOOK_TABLE: 'Reserver bord',
    CART_ALLERGIC_CONTACT: 'Allergier? Kontakt restauranten på',
    CART_PERSONNEL_CARD: 'Personalekort',
    CART_WELCOME_READ: 'Læs mere',
    CART_WELCOME_OPENING_HOURS: 'Åbningstider og kontaktinformation',
    CUSTOMER_TAB_KEY_KOD: 'Bordkode',
    CUSTOMER_TAB_KEY_WRONG_CODE: 'Forkert bordkode',
    CUSTOMER_TAB_KEY_SAME_CODE: 'Samme kode igen? Klik her',
    CUSTOMER_CODE_TYPE_4_DIGITS: 'Skriv 4 cifre',
    ACTIVE_ORDER_ONGOING: 'Igangværende ordrer',
    ACTIVE_ORDER_DEL_CUSTOMER: 'Køkkenet har modtaget din ordre, og du får besked, når ordren leveres til dig',
    ACTIVE_ORDER_WAITING: 'Tak! Afventer godkendelse',
    ACTIVE_ORDER_TAKEAWAY: 'Køkkenet har modtaget din ordre, og du får snarest besked med afhentningstidspunkt',
    ACTIVE_ORDER_TABLE: 'Tak! Vi er i gang med din ordre og leverer den til dig, så snart vi kan!',
    ACTIVE_ORDER_READY: 'Din ordre er klar',
    ACTIVE_ORDER_FOOD_READY: 'Din mad er klar',
    ACTIVE_ORDER_DRINK_READY: 'Din drikkevare er klar',
    ACTIVE_ORDER_TICKET: 'Her er din billet',
    ACTIVE_ORDER_SHOW_SCREEN: 'Vis denne skærm ved skranken',
    ACTIVE_ORDER_HOME_DELIVERY_OTW: 'Din ordre forlader restauranten nu og er på vej til dig!',
    ACTIVE_ORDER_TABLE_DELIVERY_OTW: 'Din ordre leveres straks til dit bord',
    ACTIVE_ORDER_RECIEVED_Q: 'Har du modtaget din ordre?',
    ACTIVE_ORDER_CLOSE_WINDOW: 'Dette vindue forsvinder, når du vælger Ja',
    ACCOUNT_REMOVE_CARD: 'Fjern betalingskort',
    ACCOUNT_REMOVE_CARD_2: 'Vil du slette betalingskort?',
    ACCOUNT: 'Konto',
    LOGOUT: 'Log ud',
    CREATE_LOGIN: 'Opret konto / Log ind',
    ME: 'Min konto',
    BONUSPOINTS: 'Bonuspoint',
    HANDLE_NOTIFICATIONS: 'Administrer notifikationer',
    SAVED_CARDS: 'Gemte betalingskort',
    MY_RECIEPTS: 'Mine kvitteringer',
    RECIEPTS: 'Kvittering',
    PURCHASE_HISTORY_FILL_MAIL: 'Skriv din mailadresse',
    PURCHASE_HISTORY_SENDING_RECIEPT: 'Sender kvittering til',
    PURCHASE_HISTORY_SENDING_SENT: 'Kvitteringen blev sendt til',
    PURCHASE_HISTORY_MAIL_RECIEPT: 'Send kvitteringen ',
    PURCHASE_HISTORY_BUY_AT: 'Køb hos',
    PURCHASE_HISTORY_PHONE: 'Telefon',
    PURCHASE_HISTORY_VATID: 'Moms ID',
    DATE: 'Dato',
    PURCHASE_HISTORY_ORDER: 'Ordre',
    PURCHASE_HISTORY_DISCOUNT: 'Rabat',
    PURCHASE_HISTORY_TOTAL: 'I alt',
    PURCHASE_HISTORY_SUM: 'Sum',
    PURCHASE_HISTORY_COLLECTED_DISCOUNT: 'Samlet rabat',
    PURCHASE_HISTORY_ROUNDING: 'Afrundning',
    PURCHASE_HISTORY_CONTROL_UNIT: 'K.enhed',
    PURCHASE_HISTORY_RECIEPT_NO: 'Kvitteringsnummer',
    PURCHASE_HISTORY_VAT: 'Moms',
    PURCHASE_HISTORY_NETTO: 'Netto',
    PURCHASE_HISTORY_Brutto: 'Brutto',
    MENU_NOT_UPDATED: 'Det ser ud til, at restauranten endnu ikke har opdateret sin menu',
    WEEK: 'Uge',
    LOGIN: 'Log ind',
    LOGIN_PW_6: 'Din adgangskode skal minimum indeholde 6 tegn',
    LOGIN_EMAIL_FORMAT: 'Din e-mail ser ud til at være forkert. Kontrollér venligst og prøv igen',
    LOGIN_EMAIL_IN_USE: 'Denne e-mail er allerede benyttet',
    LOGIN_NEW_USER: 'Ny bruger',
    LOGIN_FINALIZING: 'Afslutter',
    LOGIN_CONTACTING_FACEBOOK: 'Kontakter Facebook',
    LOGIN_PHONE: 'Log ind med telefonnummer',
    LOGIN_APPLE: 'Log ind med Apple',
    LOGIN_FACEBOOK: 'Log ind med Facebook',
    LOGIN_EMAIL: 'Log ind med e-mail',
    LOGIN_TERMS: 'Betingelser og vilkår',
    LOGIN_NO_ACCOUNT_Q: 'Ingen konto?',
    LOGIN_CREATE: 'Opret',
    LOGIN_RESET: 'Nulstil',
    LOGIN_NAME_NEEDED: 'Dit navn er nødvendigt for at matche dig med dine ordrer',
    LOGIN_HI: 'Hej',
    LOGGING_IN: 'Du bliver nu logget ind',
    LOGIN_WAITING_CODE: 'Afventer kode...',
    LOGIN_VERIFYI: 'Verificerer',
    SEND_SMS_CODE: 'Send SMS-kode',
    LOGGING_IN_2: 'Logger ind',
    LOGIN_WRITE_EMAIL: 'Skriv din e-mail',
    EMAIL: 'E-mail',
    EMAIL_SENT: 'E-mail sendt',
    EMAIL_FOLLOW_INSTRUCTIONS: 'Åbn din indbakke og følg instruktionerne',
    HOUR: 'Time',
    MINUTE: 'Minut',
    CART_SERVE_NOW: 'Server straks',
    // Reepay charge errors
    ACQUIRER_ERROR: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    INSUFFICIENT_FOUNDS: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    PAYMENT_NOT_AUTHORIZED: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    CREDIT_CARD_EXPIRED: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    DECLINED_BY_ACQUIRER: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    CREDIT_CARD_LOST_OR_STOLEN: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    CREDIT_CARD_SUSPECTED_FRAUD: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    REFOUND_AMOUNT_TOO_HIGH: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    AUTHORIZATION_EXPIRED: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    AUTHORIZATION_AMOUNT_EXCEEDED: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    AUTHORIZATION_VOIDED: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    SCA_REQUIRED: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    RISK_FILTER_BLOCK: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten'
}
